function Letters2Meaning_Finale(testAreaDiv, itemDef) {
	// Save incoming parameters
	this._testAreaDiv = testAreaDiv;
	this._itemDef = itemDef;

	// Construct the item in the DOM
	this._build();
}

Letters2Meaning_Finale.prototype._build = function() {
	if (this._testAreaDiv) {
		// Construct the answer area
		var congratsText = document.createElement('div');
		congratsText.id = "finaleCongratsText";
		congratsText.className = "ProximaNovaSoft-Semibold";
		congratsText.appendChild(document.createTextNode("Good job!"));

		// Attach the congrats text to the document
		this._testAreaDiv.append(congratsText);

		// Hide the "Next" button
		app.hideNextButton();

		// Show the "Continue" frame (button & book icon)
		app.hideContinueFrame(false);
	}
};
