var gBtn_LogIn = undefined;
var gBtn_Start = undefined;
var gBtn_Restart = undefined;
var gBtn_Replay = undefined;
var gBtn_Prev = undefined;
var gBtn_Next = undefined;

function initializeNav() {
	gBtn_LogIn = document.getElementById("Btn_LogIn");
	gBtn_Start = document.getElementById("Btn_Start");
	gBtn_Restart = document.getElementById("Btn_Restart");
	gBtn_Replay = document.getElementById("Btn_Replay");
	gBtn_Prev = document.getElementById("Btn_Prev");
	gBtn_Next = document.getElementById("Btn_Next");

	// hide dotted :focus outlines when mouse is used
	// but NOT when tab key is used
	if (document.getElementsByTagName) {
		var elements = document.getElementsByTagName('A');
		for (var i in elements) {
			elements[i].onmousedown = function() {
				this.blur(); // most browsers
				this.hideFocus = true; // ie
				this.style.outline = 'none'; // mozilla
			};
			elements[i].onmouseout = elements[i].onmouseup = function() {
				this.blur(); // most browsers
				this.hideFocus = false; // ie
				this.style.outline = null; // mozilla
			};
		}
	}
}

function showLogInButton() {
	if (gBtn_LogIn == undefined) {
		gBtn_LogIn = document.getElementById("Btn_LogIn");
	}
	if (typeof gBtn_LogIn !== 'undefined') {
		gBtn_LogIn.className = "shown";
	}
}
function hideLogInButton() {
	if (gBtn_LogIn == undefined) {
		gBtn_LogIn = document.getElementById("Btn_LogIn");
	}
	if (typeof gBtn_LogIn !== 'undefined') {
		gBtn_LogIn.className = "hidden";
	}
}
function showHideLogInButton(showLogIn) {
	if (showLogIn) {
		showLogInButton();
	} else {
		hideLogInButton();
	}
}
function enableLogInButton() {
	if (gBtn_LogIn == undefined) {
		gBtn_LogIn = document.getElementById("Btn_LogIn");
	}
	if (typeof gBtn_LogIn !== 'undefined') {
		gBtn_LogIn.className = "enabled";
	}
}
function disableLogInButton() {
	if (typeof gBtn_LogIn === 'undefined') {
		gBtn_LogIn = document.getElementById("Btn_LogIn");
	}
	if (typeof gBtn_LogIn !== 'undefined') {
		gBtn_LogIn.className = "disabled";
	}
}
function isLogInButtonEnabled() {
	if (typeof gBtn_LogIn === 'undefined') {
		gBtn_LogIn = document.getElementById("Btn_LogIn");
	}
	return (gBtn_LogIn.className == "enabled");
}

function showStartButton() {
	if (typeof gBtn_Start === 'undefined') {
		gBtn_Start = document.getElementById("Btn_Start");
	}
	if (typeof gBtn_Start !== 'undefined') {
		gBtn_Start.className = "shown";
	}
}
function hideStartButton() {
	if (typeof gBtn_Start === 'undefined') {
		gBtn_Start = document.getElementById("Btn_v");
	}
	if (typeof gBtn_Start !== 'undefined') {
		gBtn_Start.className = "hidden";
	}
}
function showHideStartButton(showStart) {
	if (showStart) {
		showStartButton();
	} else {
		hideStartButton();
	}
}
function enableStartButton() {
	if (typeof gBtn_Start === 'undefined') {
		gBtn_Start = document.getElementById("Btn_Start");
	}
	if (typeof gBtn_Start !== 'undefined') {
		gBtn_Start.className = "enabled";
	}
}
function disableStartButton() {
	if (typeof gBtn_Start === 'undefined') {
		gBtn_Start = document.getElementById("Btn_Start");
	}
	if (typeof gBtn_Start !== 'undefined') {
		gBtn_Start.className = "disabled";
	}
}
function isStartButtonEnabled() {
	if (typeof gBtn_Start === 'undefined') {
		gBtn_Start = document.getElementById("Btn_Start");
	}
	return (gBtn_Start.className == "enabled");
}

function showRestartButton() {
	if (typeof gBtn_Restart === 'undefined') {
		gBtn_Restart = document.getElementById("Btn_Restart");
	}
	if (typeof gBtn_Restart !== 'undefined') {
		gBtn_Restart.className = "shown";
	}
}
function hideRestartButton() {
	if (typeof gBtn_Restart === 'undefined') {
		gBtn_Restart = document.getElementById("Btn_Restart");
	}
	if (typeof gBtn_Restart !== 'undefined') {
		gBtn_Restart.className = "hidden";
	}
}
function showHideRestartButton(showRestart) {
	if (showRestart) {
		showRestartButton();
	} else {
		hideRestartButton();
	}
}
function enableRestartButton() {
	if (typeof gBtn_Restart === 'undefined') {
		gBtn_Restart = document.getElementById("Btn_Restart");
	}
	if (typeof gBtn_Restart !== 'undefined') {
		gBtn_Restart.className = "enabled";
	}
}
function disableRestartButton() {
	if (typeof gBtn_Restart === 'undefined') {
		gBtn_Restart = document.getElementById("Btn_Restart");
	}
	if (typeof gBtn_Restart !== 'undefined') {
		gBtn_Restart.className = "disabled";
	}
}
function isRestartButtonEnabled() {
	if (typeof gBtn_Restart === 'undefined') {
		gBtn_Restart = document.getElementById("Btn_Restart");
	}
	return (gBtn_Restart.className == "enabled");
}

function showReplayButton() {
	if (typeof gBtn_Replay === 'undefined') {
		gBtn_Replay = document.getElementById("Btn_Replay");
	}
	if (typeof gBtn_Replay !== 'undefined') {
		gBtn_Replay.className = "shown";
	}
}
function hideReplayButton() {
	if (typeof gBtn_Replay === 'undefined') {
		gBtn_Replay = document.getElementById("Btn_Replay");
	}
	if (typeof gBtn_Replay !== 'undefined') {
		gBtn_Replay.className = "hidden";
	}
}
function showHideReplayButton(showReplay) {
	if (showReplay) {
		showReplayButton();
	} else {
		hideReplayButton();
	}
}
function enableReplayButton() {
	if (typeof gBtn_Replay === 'undefined') {
		gBtn_Replay = document.getElementById("Btn_Replay");
	}
	if (typeof gBtn_Replay !== 'undefined') {
		gBtn_Replay.className = "enabled";
	}
}
function disableReplayButton() {
	if (typeof gBtn_Replay === 'undefined') {
		gBtn_Replay = document.getElementById("Btn_Replay");
	}
	if (typeof gBtn_Replay !== 'undefined') {
		gBtn_Replay.className = "disabled";
	}
}
function isReplayButtonEnabled() {
	if (typeof gBtn_Replay === 'undefined') {
		gBtn_Replay = document.getElementById("Btn_Replay");
	}
	return (gBtn_Replay.className == "enabled");
}

function showPrevButton() {
	if (typeof gBtn_Prev === 'undefined') {
		gBtn_Prev = document.getElementById("Btn_Prev");
	}
	if (typeof gBtn_Prev !== 'undefined') {
		gBtn_Prev.className = "shown";
	}
}
function hidePrevButton() {
	if (typeof gBtn_Prev === 'undefined') {
		gBtn_Prev = document.getElementById("Btn_Prev");
	}
	if (typeof gBtn_Prev !== 'undefined') {
		gBtn_Prev.className = "hidden";
	}
}
function showHidePrevButton(showPrev) {
	if (showPrev) {
		showPrevButton();
	} else {
		hidePrevButton();
	}
}
function enablePrevButton() {
	if (typeof gBtn_Prev === 'undefined') {
		gBtn_Prev = document.getElementById("Btn_Prev");
	}
	if (typeof gBtn_Prev !== 'undefined') {
		gBtn_Prev.className = "enabled";
	}
}
function disablePrevButton() {
	if (typeof gBtn_Prev === 'undefined') {
		gBtn_Prev = document.getElementById("Btn_Prev");
	}
	if (typeof gBtn_Prev !== 'undefined') {
		gBtn_Prev.className = "disabled";
	}
}
function isPrevButtonEnabled() {
	if (typeof gBtn_Prev === 'undefined') {
		gBtn_Prev = document.getElementById("Btn_Prev");
	}
	return (gBtn_Prev.className == "enabled");
}

function showNextButton() {
	if (typeof gBtn_Next === 'undefined') {
		gBtn_Next = document.getElementById("Btn_Next");
	}
	if (typeof gBtn_Next !== 'undefined') {
		gBtn_Next.className = "shown";
	}
}
function hideNextButton() {
	if (typeof gBtn_Next === 'undefined') {
		gBtn_Next = document.getElementById("Btn_Next");
	}
	if (typeof gBtn_Next !== 'undefined') {
		gBtn_Next.className = "hidden";
	}
}
function showHideNextButton(showNext) {
	if (showNext) {
		showNextButton();
	} else {
		hideNextButton();
	}
}
function enableNextButton() {
	if (typeof gBtn_Next === 'undefined') {
		gBtn_Next = document.getElementById("Btn_Next");
	}
	if (typeof gBtn_Next !== 'undefined') {
		gBtn_Next.className = "enabled";
	}
}
function disableNextButton() {
	if (typeof gBtn_Next === 'undefined') {
		gBtn_Next = document.getElementById("Btn_Next");
	}
	if (typeof gBtn_Next !== 'undefined') {
		gBtn_Next.className = "disabled";
	}
}
function isNextButtonEnabled() {
	if (typeof gBtn_Next === 'undefined') {
		gBtn_Next = document.getElementById("Btn_Next");
	}
	return (gBtn_Next.className == "enabled");
}
