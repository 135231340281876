function l2m_itemDefsFactory() {
	var itemDefs = {
		"letterIdentification": {
			"trainingItemIds": [1008, 1012],
			"numTrainingItemRetries": 1,
			"items": [{
				"itemId": 1000,
				"itemLabel": "LID_1",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.93388685646155,
				"choices": ["O", "S", "A", "M", "R"],
				"correctAnswer": "O",
				"audioFileNames": {
					"prompts": ["LID_O"]
				},
				"discrimination": 3.14684374621056
			}, {
				"itemId": 1001,
				"itemLabel": "LID_2",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.92278412289783,
				"choices": ["o", "g", "v", "u", "w"],
				"correctAnswer": "o",
				"audioFileNames": {
					"prompts": ["LID_O"]
				},
				"discrimination": 3.10645051063469
			}, {
				"itemId": 1002,
				"itemLabel": "LID_3",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.78420374583229,
				"choices": ["B", "O", "G", "Z", "M"],
				"correctAnswer": "B",
				"audioFileNames": {
					"prompts": ["LID_B"]
				},
				"discrimination": 2.36700046962684
			}, {
				"itemId": 1003,
				"itemLabel": "LID_4",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -2.07094506677537,
				"choices": ["X", "T", "Q", "F", "Z"],
				"correctAnswer": "X",
				"audioFileNames": {
					"prompts": ["LID_X"]
				},
				"discrimination": 2.71960669627745
			}, {
				"itemId": 1004,
				"itemLabel": "LID_5",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.98213282473934,
				"choices": ["A", "J", "I", "Z", "C"],
				"correctAnswer": "A",
				"audioFileNames": {
					"prompts": ["LID_A"]
				},
				"discrimination": 2.89119571296735
			}, {
				"itemId": 1006,
				"itemLabel": "LID_7",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.73599567426618,
				"choices": ["S", "Q", "T", "W", "L"],
				"correctAnswer": "S",
				"audioFileNames": {
					"prompts": ["LID_S"]
				},
				"discrimination": 3.16483523630408
			}, {
				"itemId": 1007,
				"itemLabel": "LID_8",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.65167262341155,
				"choices": ["R", "U", "Q", "S", "L"],
				"correctAnswer": "R",
				"audioFileNames": {
					"prompts": ["LID_R"]
				},
				"discrimination": 3.50486195317701
			}, {
				"itemId": 1008,
				"itemLabel": "LID_9",
				"itemType": "letterIdentification",
				"itemClass": "training",
				"difficulty": -1.412,
				"choices": ["C", "T", "A", "E", "P"],
				"correctAnswer": "C",
				"audioFileNames": {
					"instructions": ["LID_Instructions"],
					"prompts": ["LID_T1_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["LID_T1_Feedback_correct"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["LID_T1_Feedback_incorrect", "Lets_try_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["LID_T1_Feedback_correct"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["Lets_try_some_more"]
					}]
				}
			}, {
				"itemId": 1009,
				"itemLabel": "LID_10",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.55514755757633,
				"choices": ["c", "j", "s", "z", "t"],
				"correctAnswer": "c",
				"audioFileNames": {
					"prompts": ["LID_C"]
				},
				"discrimination": 2.04357190546141
			}, {
				"itemId": 1010,
				"itemLabel": "LID_11",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.54303129677724,
				"choices": ["M", "I", "O", "N", "R"],
				"correctAnswer": "M",
				"audioFileNames": {
					"prompts": ["LID_M"]
				},
				"discrimination": 2.3472494902431
			}, {
				"itemId": 1011,
				"itemLabel": "LID_12",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.59324777478262,
				"choices": ["K", "Q", "G", "S", "M"],
				"correctAnswer": "K",
				"audioFileNames": {
					"prompts": ["LID_K"]
				},
				"discrimination": 3.24683372860292
			}, {
				"itemId": 1012,
				"itemLabel": "LID_13",
				"itemType": "letterIdentification",
				"itemClass": "training",
				"difficulty": -1.504,
				"choices": ["s", "x", "f", "g", "b"],
				"correctAnswer": "s",
				"audioFileNames": {
					"instructions": [],
					"prompts": ["LID_T2_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["LID_T2_Feedback_correct", "LID_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["LID_T2_Feedback_incorrect", "Lets_try_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["LID_T2_Feedback_correct", "LID_Training_FinalPrompt"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["LID_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["LID_T2_Feedback_correct", "LID_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["LID_T2_Feedback_incorrect", "LID_Training_FinalPrompt"]
					}]
				}
			}, {
				"itemId": 1013,
				"itemLabel": "LID_14",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.55181383623494,
				"choices": ["E", "R", "I", "F", "N"],
				"correctAnswer": "E",
				"audioFileNames": {
					"prompts": ["LID_E"]
				},
				"discrimination": 3.07169290511719
			}, {
				"itemId": 1014,
				"itemLabel": "LID_15",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.6586887222158,
				"choices": ["k", "i", "j", "n", "o"],
				"correctAnswer": "k",
				"audioFileNames": {
					"prompts": ["LID_K"]
				},
				"discrimination": 2.98881359847991
			}, {
				"itemId": 1016,
				"itemLabel": "LID_17",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.57622392965276,
				"choices": ["D", "Q", "O", "F", "L"],
				"correctAnswer": "D",
				"audioFileNames": {
					"prompts": ["LID_D"]
				},
				"discrimination": 3.57254021756716
			}, {
				"itemId": 1017,
				"itemLabel": "LID_18",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.62656791529062,
				"choices": ["H", "Z", "T", "N", "D"],
				"correctAnswer": "H",
				"audioFileNames": {
					"prompts": ["LID_H"]
				},
				"discrimination": 3.14604759272805
			}, {
				"itemId": 1018,
				"itemLabel": "LID_19",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.53120024555458,
				"choices": ["T", "X", "Y", "P", "C"],
				"correctAnswer": "T",
				"audioFileNames": {
					"prompts": ["LID_T"]
				},
				"discrimination": 3.52350130108288
			}, {
				"itemId": 1019,
				"itemLabel": "LID_20",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.75956804010354,
				"choices": ["W", "J", "E", "K", "C"],
				"correctAnswer": "W",
				"audioFileNames": {
					"prompts": ["LID_W"]
				},
				"discrimination": 3.49169096690292
			}, {
				"itemId": 1020,
				"itemLabel": "LID_21",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.67580390656596,
				"choices": ["L", "Q", "C", "Y", "W"],
				"correctAnswer": "L",
				"audioFileNames": {
					"prompts": ["LID_L"]
				},
				"discrimination": 3.56363632666245
			}, {
				"itemId": 1021,
				"itemLabel": "LID_22",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.78778982601824,
				"choices": ["w", "a", "k", "f", "z"],
				"correctAnswer": "w",
				"audioFileNames": {
					"prompts": ["LID_W"]
				},
				"discrimination": 3.19436610912019
			}, {
				"itemId": 1022,
				"itemLabel": "LID_23",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.82416955599043,
				"choices": ["Z", "G", "K", "C", "M"],
				"correctAnswer": "Z",
				"audioFileNames": {
					"prompts": ["LID_Z"]
				},
				"discrimination": 2.70425513170011
			}, {
				"itemId": 1024,
				"itemLabel": "LID_25",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.63474672749142,
				"choices": ["Y", "Z", "G", "U", "B"],
				"correctAnswer": "Y",
				"audioFileNames": {
					"prompts": ["LID_Y"]
				},
				"discrimination": 3.06313309133898
			}, {
				"itemId": 1025,
				"itemLabel": "LID_26",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.47090619381575,
				"choices": ["J", "L", "U", "N", "G"],
				"correctAnswer": "J",
				"audioFileNames": {
					"prompts": ["LID_J"]
				},
				"discrimination": 2.21983594709047
			}, {
				"itemId": 1026,
				"itemLabel": "LID_27",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.61382511506788,
				"choices": ["G", "F", "B", "P", "O"],
				"correctAnswer": "G",
				"audioFileNames": {
					"prompts": ["LID_G"]
				},
				"discrimination": 3.68243510448084
			}, {
				"itemId": 1027,
				"itemLabel": "LID_28",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.31098519171315,
				"choices": ["N", "X", "M", "B", "L"],
				"correctAnswer": "N",
				"audioFileNames": {
					"prompts": ["LID_N"]
				},
				"discrimination": 1.57912546422727
			}, {
				"itemId": 1028,
				"itemLabel": "LID_29",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.87275854815672,
				"choices": ["z", "d", "r", "k", "h"],
				"correctAnswer": "z",
				"audioFileNames": {
					"prompts": ["LID_Z"]
				},
				"discrimination": 3.0824450467704
			}, {
				"itemId": 1029,
				"itemLabel": "LID_30",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.61940740872476,
				"choices": ["i", "t", "y", "h", "a"],
				"correctAnswer": "i",
				"audioFileNames": {
					"prompts": ["LID_I"]
				},
				"discrimination": 3.24584541818219
			}, {
				"itemId": 1030,
				"itemLabel": "LID_31",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.64528004763057,
				"choices": ["Q", "N", "Z", "B", "G"],
				"correctAnswer": "Q",
				"audioFileNames": {
					"prompts": ["LID_Q"]
				},
				"discrimination": 2.88792742780959
			}, {
				"itemId": 1031,
				"itemLabel": "LID_32",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.35030152116993,
				"choices": ["I", "C", "T", "R", "V"],
				"correctAnswer": "I",
				"audioFileNames": {
					"prompts": ["LID_I"]
				},
				"discrimination": 2.60119986336166
			}, {
				"itemId": 1032,
				"itemLabel": "LID_33",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.49339517015021,
				"choices": ["e", "u", "i", "j", "p"],
				"correctAnswer": "e",
				"audioFileNames": {
					"prompts": ["LID_E"]
				},
				"discrimination": 2.37243937959576
			}, {
				"itemId": 1033,
				"itemLabel": "LID_34",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.70769839660821,
				"choices": ["y", "c", "u", "q", "e"],
				"correctAnswer": "y",
				"audioFileNames": {
					"prompts": ["LID_Y"]
				},
				"discrimination": 2.77127947895958
			}, {
				"itemId": 1034,
				"itemLabel": "LID_35",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.54647173023486,
				"choices": ["m", "a", "e", "l", "w"],
				"correctAnswer": "m",
				"audioFileNames": {
					"prompts": ["LID_M"]
				},
				"discrimination": 2.31010541663169
			}, {
				"itemId": 1036,
				"itemLabel": "LID_37",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.49258915398999,
				"choices": ["p", "b", "k", "g", "c"],
				"correctAnswer": "p",
				"audioFileNames": {
					"prompts": ["LID_P"]
				},
				"discrimination": 2.66763950839405
			}, {
				"itemId": 1040,
				"itemLabel": "LID_41",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.59761927700192,
				"choices": ["v", "q", "j", "m", "l"],
				"correctAnswer": "v",
				"audioFileNames": {
					"prompts": ["LID_V"]
				},
				"discrimination": 2.81126793316963
			}, {
				"itemId": 1041,
				"itemLabel": "LID_42",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.58498152525675,
				"choices": ["u", "l", "d", "t", "n"],
				"correctAnswer": "u",
				"audioFileNames": {
					"prompts": ["LID_U"]
				},
				"discrimination": 3.37942194865483
			}, {
				"itemId": 1042,
				"itemLabel": "LID_43",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.76624840820448,
				"choices": ["j", "d", "s", "m", "b"],
				"correctAnswer": "j",
				"audioFileNames": {
					"prompts": ["LID_J"]
				},
				"discrimination": 3.00631422787368
			}, {
				"itemId": 1043,
				"itemLabel": "LID_44",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.48886719525403,
				"choices": ["a", "y", "j", "h", "t"],
				"correctAnswer": "a",
				"audioFileNames": {
					"prompts": ["LID_A"]
				},
				"discrimination": 3.30122152735185
			}, {
				"itemId": 1044,
				"itemLabel": "LID_45",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.45210950563203,
				"choices": ["n", "f", "w", "j", "r"],
				"correctAnswer": "n",
				"audioFileNames": {
					"prompts": ["LID_N"]
				},
				"discrimination": 3.18922255164984
			}, {
				"itemId": 1045,
				"itemLabel": "LID_46",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.42745406406635,
				"choices": ["h", "i", "z", "n", "k"],
				"correctAnswer": "h",
				"audioFileNames": {
					"prompts": ["LID_H"]
				},
				"discrimination": 2.91215773174593
			}, {
				"itemId": 1046,
				"itemLabel": "LID_47",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.48002722368694,
				"choices": ["t", "h", "x", "k", "s"],
				"correctAnswer": "t",
				"audioFileNames": {
					"prompts": ["LID_T"]
				},
				"discrimination": 2.76229342999001
			}, {
				"itemId": 1047,
				"itemLabel": "LID_48",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.53258510915129,
				"choices": ["g", "m", "n", "o", "e"],
				"correctAnswer": "g",
				"audioFileNames": {
					"prompts": ["LID_G"]
				},
				"discrimination": 2.953824639312
			}, {
				"itemId": 1048,
				"itemLabel": "LID_49",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.64043752194393,
				"choices": ["b", "x", "n", "l", "w"],
				"correctAnswer": "b",
				"audioFileNames": {
					"prompts": ["LID_B"]
				},
				"discrimination": 2.92831085537573
			}, {
				"itemId": 1049,
				"itemLabel": "LID_50",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.68544506613406,
				"choices": ["l", "s", "z", "o", "a"],
				"correctAnswer": "l",
				"audioFileNames": {
					"prompts": ["LID_L"]
				},
				"discrimination": 2.92171479084714
			}, {
				"itemId": 1050,
				"itemLabel": "LID_51",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.49023233940118,
				"choices": ["d", "n", "r", "q", "s"],
				"correctAnswer": "d",
				"audioFileNames": {
					"prompts": ["LID_D"]
				},
				"discrimination": 2.84497868885077
			}, {
				"itemId": 1051,
				"itemLabel": "LID_52",
				"itemType": "letterIdentification",
				"itemClass": "active",
				"difficulty": -1.24963734702686,
				"choices": ["q", "s", "v", "r", "a"],
				"correctAnswer": "q",
				"audioFileNames": {
					"prompts": ["LID_Q"]
				},
				"discrimination": 2.14385884624676
			}]
		},
		"soundIdentification": {
			"trainingItemIds": [2003, 2015],
			"numTrainingItemRetries": 1,
			"items": [{
				"itemId": 2000,
				"itemLabel": "SID_1",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.74024403484951,
				"choices": ["A", "B", "I", "H", "N"],
				"correctAnswer": "A",
				"audioFileNames": {
					"prompts": ["SID_A_Ant"]
				},
				"discrimination": 3.16227017938057
			}, {
				"itemId": 2001,
				"itemLabel": "SID_2",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.46686471014308,
				"choices": ["B", "Y", "V", "U", "D"],
				"correctAnswer": "B",
				"audioFileNames": {
					"prompts": ["SID_B_Bubble"]
				},
				"discrimination": 2.04159297993146
			}, {
				"itemId": 2003,
				"itemLabel": "SID_4",
				"itemType": "soundIdentification",
				"itemClass": "training",
				"difficulty": -0.56,
				"choices": ["D", "C", "X", "K", "G"],
				"correctAnswer": "D",
				"audioFileNames": {
					"instructions": ["SID_Instructions"],
					"prompts": ["SID_T1_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["SID_T1_Feedback_correct"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["SID_T1_Feedback_incorrect", "Lets_try_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["SID_T1_Feedback_correct"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["Lets_try_another_one"]
					}]
				}
			}, {
				"itemId": 2004,
				"itemLabel": "SID_5",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.5800922692595,
				"choices": ["E", "Z", "K", "X", "W"],
				"correctAnswer": "E",
				"audioFileNames": {
					"prompts": ["SID_E_Eerie"]
				},
				"discrimination": 3.28581311109321
			}, {
				"itemId": 2005,
				"itemLabel": "SID_6",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.50303706746514,
				"choices": ["I", "W", "D", "N", "B"],
				"correctAnswer": "I",
				"audioFileNames": {
					"prompts": ["SID_I_Ivy"]
				},
				"discrimination": 3.18104450551024
			}, {
				"itemId": 2006,
				"itemLabel": "SID_7",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.38830354191012,
				"choices": ["K", "F", "X", "R", "O"],
				"correctAnswer": "K",
				"audioFileNames": {
					"prompts": ["SID_K_Kick"]
				},
				"discrimination": 2.88551657315749
			}, {
				"itemId": 2007,
				"itemLabel": "SID_8",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.38780636204276,
				"choices": ["P", "T", "M", "S", "C"],
				"correctAnswer": "P",
				"audioFileNames": {
					"prompts": ["SID_P_Panda"]
				},
				"discrimination": 2.9484705573689
			}, {
				"itemId": 2008,
				"itemLabel": "SID_9",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.45774890999994,
				"choices": ["T", "L", "W", "M", "J"],
				"correctAnswer": "T",
				"audioFileNames": {
					"prompts": ["SID_T_Tank"]
				},
				"discrimination": 3.38394668726435
			}, {
				"itemId": 2009,
				"itemLabel": "SID_10",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.4842547461993,
				"choices": ["V", "K", "I", "J", "A"],
				"correctAnswer": "V",
				"audioFileNames": {
					"prompts": ["SID_V_Velvet"]
				},
				"discrimination": 3.29405238344455
			}, {
				"itemId": 2010,
				"itemLabel": "SID_11",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.72400435504646,
				"choices": ["Z", "O", "D", "E", "L"],
				"correctAnswer": "Z",
				"audioFileNames": {
					"prompts": ["SID_Z_Zany"]
				},
				"discrimination": 3.28840877719516
			}, {
				"itemId": 2011,
				"itemLabel": "SID_12",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.43820442318428,
				"choices": ["F", "C", "Y", "D", "J"],
				"correctAnswer": "F",
				"audioFileNames": {
					"prompts": ["SID_F_Fish"]
				},
				"discrimination": 2.76605369892514
			}, {
				"itemId": 2012,
				"itemLabel": "SID_13",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.43237229371336,
				"choices": ["L", "G", "F", "Z", "W"],
				"correctAnswer": "L",
				"audioFileNames": {
					"prompts": ["SID_L_Loopy"]
				},
				"discrimination": 3.31417754565957
			}, {
				"itemId": 2013,
				"itemLabel": "SID_14",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.41395905642325,
				"choices": ["M", "R", "G", "I", "A"],
				"correctAnswer": "M",
				"audioFileNames": {
					"prompts": ["SID_M_Marker"]
				},
				"discrimination": 2.64516492316682
			}, {
				"itemId": 2014,
				"itemLabel": "SID_15",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.12686632843845,
				"choices": ["N", "M", "R", "S", "Q"],
				"correctAnswer": "N",
				"audioFileNames": {
					"prompts": ["SID_N_Noodle"]
				},
				"discrimination": 2.03530391186492
			}, {
				"itemId": 2015,
				"itemLabel": "SID_16",
				"itemType": "soundIdentification",
				"itemClass": "training",
				"difficulty": -1.434,
				"choices": ["R", "W", "V", "G", "A"],
				"correctAnswer": "R",
				"audioFileNames": {
					"instructions": [],
					"prompts": ["SID_T2_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["SID_T2_Feedback_correct", "SID_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["SID_T2_Feedback_incorrect", "Lets_try_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["SID_T2_Feedback_correct", "SID_Training_FinalPrompt"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["SID_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["SID_T2_Feedback_correct", "SID_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["SID_T2_Feedback_incorrect", "SID_Training_FinalPrompt"]
					}]
				}
			}, {
				"itemId": 2016,
				"itemLabel": "SID_17",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.643931687504,
				"choices": ["S", "O", "D", "U", "J"],
				"correctAnswer": "S",
				"audioFileNames": {
					"prompts": ["SID_S_Stew"]
				},
				"discrimination": 2.71939498771581
			}, {
				"itemId": 2017,
				"itemLabel": "SID_18",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.47206662535517,
				"choices": ["U", "J", "F", "V", "P"],
				"correctAnswer": "U",
				"audioFileNames": {
					"prompts": ["SID_U_Use"]
				},
				"discrimination": 2.79163769235557
			}, {
				"itemId": 2018,
				"itemLabel": "SID_19",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.4800360727114,
				"choices": ["T", "G", "D", "M", "C"],
				"correctAnswer": "T",
				"audioFileNames": {
					"prompts": ["SID_T_Tank"]
				},
				"discrimination": 3.09766329896362
			}, {
				"itemId": 2019,
				"itemLabel": "SID_20",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.7298673958838,
				"choices": ["X", "Q", "M", "W", "Z"],
				"correctAnswer": "X",
				"audioFileNames": {
					"prompts": ["SID_X_Xray"]
				},
				"discrimination": 2.63214042678495
			}, {
				"itemId": 2020,
				"itemLabel": "SID_21",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.2820983037923,
				"choices": ["G", "W", "Q", "C", "Z"],
				"correctAnswer": "G",
				"audioFileNames": {
					"prompts": ["SID_G_Grape"]
				},
				"discrimination": 3.02227626021995
			}, {
				"itemId": 2021,
				"itemLabel": "SID_22",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.29372130305927,
				"choices": ["C", "L", "W", "U", "F"],
				"correctAnswer": "C",
				"audioFileNames": {
					"prompts": ["SID_C_Copper"]
				},
				"discrimination": 2.87115044212761
			}, {
				"itemId": 2022,
				"itemLabel": "SID_23",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.17697331461675,
				"choices": ["E", "W", "P", "F", "T"],
				"correctAnswer": "E",
				"audioFileNames": {
					"prompts": ["SID_E_Empty"]
				},
				"discrimination": 2.07789526624523
			}, {
				"itemId": 2023,
				"itemLabel": "SID_24",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.07681797433738,
				"choices": ["I", "B", "T", "P", "G"],
				"correctAnswer": "I",
				"audioFileNames": {
					"prompts": ["SID_I_Icky"]
				},
				"discrimination": 2.15336293350031
			}, {
				"itemId": 2024,
				"itemLabel": "SID_25",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.60016053265077,
				"choices": ["O", "X", "M", "S", "E"],
				"correctAnswer": "O",
				"audioFileNames": {
					"prompts": ["SID_O_Opera"]
				},
				"discrimination": 2.87251352252473
			}, {
				"itemId": 2026,
				"itemLabel": "SID_27",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.5001202339026,
				"choices": ["H", "Q", "C", "I", "G"],
				"correctAnswer": "H",
				"audioFileNames": {
					"prompts": ["SID_H_Hippo"]
				},
				"discrimination": 2.94011379675113
			}, {
				"itemId": 2027,
				"itemLabel": "SID_28",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.5612452216518,
				"choices": ["J", "B", "O", "A", "R"],
				"correctAnswer": "J",
				"audioFileNames": {
					"prompts": ["SID_J_Juice"]
				},
				"discrimination": 3.50166611103552
			}, {
				"itemId": 2028,
				"itemLabel": "SID_29",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.48769467088937,
				"choices": ["Q", "M", "G", "N", "L"],
				"correctAnswer": "Q",
				"audioFileNames": {
					"prompts": ["SID_Q_Quiet"]
				},
				"discrimination": 3.10261703950016
			}, {
				"itemId": 2029,
				"itemLabel": "SID_30",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.41395381899117,
				"choices": ["W", "P", "B", "G", "L"],
				"correctAnswer": "W",
				"audioFileNames": {
					"prompts": ["SID_W_Wet"]
				},
				"discrimination": 3.22550636004668
			}, {
				"itemId": 2030,
				"itemLabel": "SID_31",
				"itemType": "soundIdentification",
				"itemClass": "active",
				"difficulty": -1.30013656730401,
				"choices": ["Y", "Z", "S", "J", "F"],
				"correctAnswer": "Y",
				"audioFileNames": {
					"prompts": ["SID_Y_Yankee"]
				},
				"discrimination": 2.20722943145111
			}]
		},
		"letters2Word": {
			"trainingItemIds": [3017, 3021],
			"numTrainingItemRetries": 1,
			"items": [{
				"itemId": 3000,
				"itemLabel": "L2W_1",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.805736211930288,
				"choices": ["h", "a", "t"],
				"correctAnswer": "hat",
				"audioFileNames": {
					"prompts": ["L2W_Hat"]
				},
				"discrimination": 5.56191348313328
			}, {
				"itemId": 3001,
				"itemLabel": "L2W_2",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.772018908817162,
				"choices": ["m", "o", "p"],
				"correctAnswer": "mop",
				"audioFileNames": {
					"prompts": ["L2W_Mop"]
				},
				"discrimination": 4.97629643502353
			}, {
				"itemId": 3002,
				"itemLabel": "L2W_3",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.732118971868732,
				"choices": ["t", "i", "p"],
				"correctAnswer": "tip",
				"audioFileNames": {
					"prompts": ["L2W_Tip"]
				},
				"discrimination": 4.83735849398654
			}, {
				"itemId": 3003,
				"itemLabel": "L2W_4",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.785503799872722,
				"choices": ["f", "u", "n"],
				"correctAnswer": "fun",
				"audioFileNames": {
					"prompts": ["L2W_Fun"]
				},
				"discrimination": 4.81295438498789
			}, {
				"itemId": 3004,
				"itemLabel": "L2W_5",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -1.20470994318278,
				"choices": ["s", "e", "e"],
				"correctAnswer": "see",
				"audioFileNames": {
					"prompts": ["L2W_See"]
				},
				"discrimination": 2.7132005254972
			}, {
				"itemId": 3005,
				"itemLabel": "L2W_6",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.705356537983327,
				"choices": ["h", "i", "s"],
				"correctAnswer": "his",
				"audioFileNames": {
					"prompts": ["L2W_His"]
				},
				"discrimination": 2.628072058694
			}, {
				"itemId": 3006,
				"itemLabel": "L2W_7",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.809702609502326,
				"choices": ["r", "e", "d"],
				"correctAnswer": "red",
				"audioFileNames": {
					"prompts": ["L2W_Red"]
				},
				"discrimination": 5.37456334392518
			}, {
				"itemId": 3007,
				"itemLabel": "L2W_8",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.833122037133718,
				"choices": ["w", "h", "o"],
				"correctAnswer": "who",
				"audioFileNames": {
					"prompts": ["L2W_Who"]
				},
				"discrimination": 1.35900264737983
			}, {
				"itemId": 3008,
				"itemLabel": "L2W_9",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.0427396554536287,
				"choices": ["o", "n", "e"],
				"correctAnswer": "one",
				"audioFileNames": {
					"prompts": ["L2W_One"]
				},
				"discrimination": 1.97203634557301
			}, {
				"itemId": 3009,
				"itemLabel": "L2W_10",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.624212425772707,
				"choices": ["w", "a", "s"],
				"correctAnswer": "was",
				"audioFileNames": {
					"prompts": ["L2W_Was"]
				},
				"discrimination": 2.66846283824229
			}, {
				"itemId": 3010,
				"itemLabel": "L2W_11",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.539037169171383,
				"choices": ["s", "a", "w"],
				"correctAnswer": "saw",
				"audioFileNames": {
					"prompts": ["L2W_Saw"]
				},
				"discrimination": 1.73979405832754
			}, {
				"itemId": 3011,
				"itemLabel": "L2W_12",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.853344821084969,
				"choices": ["m", "a", "p"],
				"correctAnswer": "map",
				"audioFileNames": {
					"prompts": ["L2W_Map"]
				},
				"discrimination": 3.69077790542112
			}, {
				"itemId": 3012,
				"itemLabel": "L2W_13",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.705836855550531,
				"choices": ["p", "i", "g", "j", "g"],
				"correctAnswer": "pig",
				"audioFileNames": {
					"prompts": ["L2Wex_Pig"]
				},
				"discrimination": 7.75698656627801
			}, {
				"itemId": 3013,
				"itemLabel": "L2W_14",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.692070542997458,
				"choices": ["t", "a", "g", "j", "s"],
				"correctAnswer": "tag",
				"audioFileNames": {
					"prompts": ["L2Wex_Tag"]
				},
				"discrimination": 4.68498383183023
			}, {
				"itemId": 3014,
				"itemLabel": "L2W_15",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.62818065955715,
				"choices": ["b", "u", "g", "j", "k"],
				"correctAnswer": "bug",
				"audioFileNames": {
					"prompts": ["L2Wex_Bug"]
				},
				"discrimination": 7.48896687504873
			}, {
				"itemId": 3015,
				"itemLabel": "L2W_16",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.654980574379779,
				"choices": ["l", "o", "g", "j", "a"],
				"correctAnswer": "log",
				"audioFileNames": {
					"prompts": ["L2Wex_Log"]
				},
				"discrimination": 6.10049904896144
			}, {
				"itemId": 3016,
				"itemLabel": "L2W_17",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.619472446949967,
				"choices": ["b", "a", "g", "j", "e"],
				"correctAnswer": "bag",
				"audioFileNames": {
					"prompts": ["L2Wex_Bag"]
				},
				"discrimination": 8.02988528402006
			}, {
				"itemId": 3017,
				"itemLabel": "L2W_18",
				"itemType": "letters2Word",
				"itemClass": "training",
				"difficulty": 0.094,
				"choices": ["d", "o", "g", "a", "l"],
				"correctAnswer": "dog",
				"audioFileNames": {
					"instructions": ["L2W_Instructions"],
					"prompts": ["L2W_T1_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["L2W_T1_Feedback_correct"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["L2W_T1_Feedback_incorrect", "Lets_try_it_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["L2W_T1_Feedback_correct"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["Lets_try_another_one"]
					}]
				}
			}, {
				"itemId": 3018,
				"itemLabel": "L2W_19",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.61909588343123,
				"choices": ["l", "e", "g", "j", "p"],
				"correctAnswer": "leg",
				"audioFileNames": {
					"prompts": ["L2Wex_Leg"]
				},
				"discrimination": 8.59225631220854
			}, {
				"itemId": 3019,
				"itemLabel": "L2W_20",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.366971008168553,
				"choices": ["k", "e", "y", "w", "w"],
				"correctAnswer": "key",
				"audioFileNames": {
					"prompts": ["L2Wex_Key"]
				},
				"discrimination": 2.71366857983719
			}, {
				"itemId": 3020,
				"itemLabel": "L2W_21",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.480347893000164,
				"choices": ["m", "e", "n", "i", "u"],
				"correctAnswer": "men",
				"audioFileNames": {
					"prompts": ["L2Wex_Men"]
				},
				"discrimination": 2.85637244058353
			}, {
				"itemId": 3021,
				"itemLabel": "L2W_22",
				"itemType": "letters2Word",
				"itemClass": "training",
				"difficulty": 0.094,
				"choices": ["s", "u", "n", "a", "p"],
				"correctAnswer": "sun",
				"audioFileNames": {
					"instructions": [],
					"prompts": ["L2W_T2_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["L2W_T2_Feedback_correct", "L2W_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["L2W_T2_Feedback_incorrect", "Lets_try_it_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["L2W_T2_Feedback_correct", "L2W_Training_FinalPrompt"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["L2W_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["L2W_T2_Feedback_correct", "L2W_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["L2W_T2_Feedback_incorrect", "L2W_Training_FinalPrompt"]
					}]
				}
			}, {
				"itemId": 3022,
				"itemLabel": "L2W_23",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.674628574535974,
				"choices": ["w", "e", "t", "p", "q"],
				"correctAnswer": "wet",
				"audioFileNames": {
					"prompts": ["L2Wex_Wet"]
				},
				"discrimination": 9.81029550444738
			}, {
				"itemId": 3023,
				"itemLabel": "L2W_24",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.510173784222027,
				"choices": ["j", "e", "t", "a", "b"],
				"correctAnswer": "jet",
				"audioFileNames": {
					"prompts": ["L2Wex_Jet"]
				},
				"discrimination": 6.21872357028406
			}, {
				"itemId": 3024,
				"itemLabel": "L2W_25",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.808027638185119,
				"choices": ["c", "a", "t", "e", "e"],
				"correctAnswer": "cat",
				"audioFileNames": {
					"prompts": ["L2Wex_Cat"]
				},
				"discrimination": 7.06440833536637
			}, {
				"itemId": 3025,
				"itemLabel": "L2W_26",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.727303367705444,
				"choices": ["b", "o", "x", "a", "h"],
				"correctAnswer": "box",
				"audioFileNames": {
					"prompts": ["L2Wex_Box"]
				},
				"discrimination": 7.25566016180175
			}, {
				"itemId": 3026,
				"itemLabel": "L2W_27",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.607137445794684,
				"choices": ["h", "o", "t", "a", "w"],
				"correctAnswer": "hot",
				"audioFileNames": {
					"prompts": ["L2Wex_Hot"]
				},
				"discrimination": 6.67234250800784
			}, {
				"itemId": 3027,
				"itemLabel": "L2W_28",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.714895245066053,
				"choices": ["f", "o", "x", "w", "c"],
				"correctAnswer": "fox",
				"audioFileNames": {
					"prompts": ["L2Wex_Fox"]
				},
				"discrimination": 6.87993133425741
			}, {
				"itemId": 3028,
				"itemLabel": "L2W_29",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.575489722685235,
				"choices": ["d", "o", "t", "u", "g", "d"],
				"correctAnswer": "dot",
				"audioFileNames": {
					"prompts": ["L2Wex_Dot"]
				},
				"discrimination": 6.91027635745974
			}, {
				"itemId": 3029,
				"itemLabel": "L2W_30",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.531293724954282,
				"choices": ["m", "a", "n", "i", "h", "g"],
				"correctAnswer": "man",
				"audioFileNames": {
					"prompts": ["L2Wex_Man"]
				},
				"discrimination": 3.91410269353841
			}, {
				"itemId": 3030,
				"itemLabel": "L2W_31",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.493355379063616,
				"choices": ["p", "i", "n", "m", "b", "a"],
				"correctAnswer": "pin",
				"audioFileNames": {
					"prompts": ["L2Wex_Pin"]
				},
				"discrimination": 4.76209394615258
			}, {
				"itemId": 3031,
				"itemLabel": "L2W_32",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.377297416675738,
				"choices": ["t", "a", "n", "m", "a", "e"],
				"correctAnswer": "tan",
				"audioFileNames": {
					"prompts": ["L2Wex_Tan"]
				},
				"discrimination": 2.4211131846193
			}, {
				"itemId": 3032,
				"itemLabel": "L2W_33",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.413418372741223,
				"choices": ["c", "a", "n", "e", "g", "k"],
				"correctAnswer": "can",
				"audioFileNames": {
					"prompts": ["L2Wex_Can"]
				},
				"discrimination": 3.065108869833
			}, {
				"itemId": 3033,
				"itemLabel": "L2W_34",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.491568186065221,
				"choices": ["s", "i", "x", "w", "e", "g"],
				"correctAnswer": "six",
				"audioFileNames": {
					"prompts": ["L2Wex_Six"]
				},
				"discrimination": 4.89160499074635
			}, {
				"itemId": 3034,
				"itemLabel": "L2W_35",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.652401972713623,
				"choices": ["n", "o", "t", "w", "l", "e"],
				"correctAnswer": "not",
				"audioFileNames": {
					"prompts": ["L2Wex_Not"]
				},
				"discrimination": 8.54928306369358
			}, {
				"itemId": 3035,
				"itemLabel": "L2W_36",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.647445772377207,
				"choices": ["c", "u", "p", "t", "x", "m"],
				"correctAnswer": "cup",
				"audioFileNames": {
					"prompts": ["L2Wex_Cup"]
				},
				"discrimination": 10.1145578974778
			}, {
				"itemId": 3036,
				"itemLabel": "L2W_37",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.67288433505368,
				"choices": ["t", "a", "p", "m", "w", "s"],
				"correctAnswer": "tap",
				"audioFileNames": {
					"prompts": ["L2Wex_Tap"]
				},
				"discrimination": 12.3065336504326
			}, {
				"itemId": 3037,
				"itemLabel": "L2W_38",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.408185083450263,
				"choices": ["b", "a", "t", "d", "d", "o"],
				"correctAnswer": "bat",
				"audioFileNames": {
					"prompts": ["L2Wex_Bat"]
				},
				"discrimination": 2.9883858395909
			}, {
				"itemId": 3038,
				"itemLabel": "L2W_39",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.452568090788598,
				"choices": ["p", "e", "t", "q", "s", "o"],
				"correctAnswer": "pet",
				"audioFileNames": {
					"prompts": ["L2Wex_Pet"]
				},
				"discrimination": 3.29885565844208
			}, {
				"itemId": 3039,
				"itemLabel": "L2W_40",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.683316619622639,
				"choices": ["s", "a", "t", "p", "g", "e"],
				"correctAnswer": "sat",
				"audioFileNames": {
					"prompts": ["L2Wex_Sat"]
				},
				"discrimination": 11.024416727862
			}, {
				"itemId": 3040,
				"itemLabel": "L2W_41",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.726797441396681,
				"choices": ["r", "a", "t", "d", "v", "a"],
				"correctAnswer": "rat",
				"audioFileNames": {
					"prompts": ["L2Wex_Rat"]
				},
				"discrimination": 11.4727211380839
			}, {
				"itemId": 3041,
				"itemLabel": "L2W_42",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.665437190961433,
				"choices": ["m", "e", "t", "d", "f", "d"],
				"correctAnswer": "met",
				"audioFileNames": {
					"prompts": ["L2Wex_Met"]
				},
				"discrimination": 11.4776354327042
			}, {
				"itemId": 3042,
				"itemLabel": "L2W_43",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.683344592670252,
				"choices": ["m", "a", "t", "p", "g", "v"],
				"correctAnswer": "mat",
				"audioFileNames": {
					"prompts": ["L2Wex_Mat"]
				},
				"discrimination": 12.4375158242133
			}, {
				"itemId": 3043,
				"itemLabel": "L2W_44",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.666154286253763,
				"choices": ["g", "e", "t", "p", "q", "e"],
				"correctAnswer": "get",
				"audioFileNames": {
					"prompts": ["L2Wex_Get"]
				},
				"discrimination": 9.80397271045093
			}, {
				"itemId": 3044,
				"itemLabel": "L2W_45",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.602129991984698,
				"choices": ["d", "i", "g", "j", "o", "p"],
				"correctAnswer": "dig",
				"audioFileNames": {
					"prompts": ["L2Wex_Dig"]
				},
				"discrimination": 8.67836542597107
			}, {
				"itemId": 3045,
				"itemLabel": "L2W_46",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.580228293494256,
				"choices": ["g", "a", "s", "t", "d", "h"],
				"correctAnswer": "gas",
				"audioFileNames": {
					"prompts": ["L2Wex_Gas"]
				},
				"discrimination": 7.71643916862601
			}, {
				"itemId": 3046,
				"itemLabel": "L2W_47",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.707022679952158,
				"choices": ["w", "o", "n", "v", "u", "m"],
				"correctAnswer": "won",
				"audioFileNames": {
					"prompts": ["L2Wex_Won"]
				},
				"discrimination": 1.52493256992711
			}, {
				"itemId": 3047,
				"itemLabel": "L2W_48",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.129668857815279,
				"choices": ["w", "i", "t", "h"],
				"correctAnswer": "with",
				"audioFileNames": {
					"prompts": ["L2W_With"]
				},
				"discrimination": 2.32374249825186
			}, {
				"itemId": 3048,
				"itemLabel": "L2W_49",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.048401724259074,
				"choices": ["f", "i", "n", "d"],
				"correctAnswer": "find",
				"audioFileNames": {
					"prompts": ["L2W_Find"]
				},
				"discrimination": 3.85156454618693
			}, {
				"itemId": 3049,
				"itemLabel": "L2W_50",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.0187731105894982,
				"choices": ["h", "a", "v", "e"],
				"correctAnswer": "have",
				"audioFileNames": {
					"prompts": ["L2W_Have"]
				},
				"discrimination": 3.25785292936129
			}, {
				"itemId": 3050,
				"itemLabel": "L2W_51",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.115331033567445,
				"choices": ["b", "a", "n", "k"],
				"correctAnswer": "bank",
				"audioFileNames": {
					"prompts": ["L2W_Bank"]
				},
				"discrimination": 3.58565734034751
			}, {
				"itemId": 3051,
				"itemLabel": "L2W_52",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.274717124031654,
				"choices": ["f", "a", "r", "m"],
				"correctAnswer": "farm",
				"audioFileNames": {
					"prompts": ["L2W_Farm"]
				},
				"discrimination": 2.56733608674019
			}, {
				"itemId": 3052,
				"itemLabel": "L2W_53",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.749596799454514,
				"choices": ["t", "u", "r", "n"],
				"correctAnswer": "turn",
				"audioFileNames": {
					"prompts": ["L2W_Turn"]
				},
				"discrimination": 3.53448720212773
			}, {
				"itemId": 3053,
				"itemLabel": "L2W_54",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.586557141786186,
				"choices": ["p", "a", "i", "n"],
				"correctAnswer": "pain",
				"audioFileNames": {
					"prompts": ["L2W_Pain"]
				},
				"discrimination": 3.20087816310706
			}, {
				"itemId": 3054,
				"itemLabel": "L2W_55",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.543178622435218,
				"choices": ["r", "a", "t", "e"],
				"correctAnswer": "rate",
				"audioFileNames": {
					"prompts": ["L2W_Rate"]
				},
				"discrimination": 2.44382065840785
			}, {
				"itemId": 3055,
				"itemLabel": "L2W_56",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.231321460715106,
				"choices": ["s", "o", "m", "e"],
				"correctAnswer": "some",
				"audioFileNames": {
					"prompts": ["L2W_Some"]
				},
				"discrimination": 2.89315259401476
			}, {
				"itemId": 3056,
				"itemLabel": "L2W_57",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.435881451916727,
				"choices": ["s", "a", "i", "d"],
				"correctAnswer": "said",
				"audioFileNames": {
					"prompts": ["L2W_Said"]
				},
				"discrimination": 2.4235670179394
			}, {
				"itemId": 3057,
				"itemLabel": "L2W_58",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.147484185483773,
				"choices": ["b", "l", "o", "w"],
				"correctAnswer": "blow",
				"audioFileNames": {
					"prompts": ["L2W_Blow"]
				},
				"discrimination": 2.14909904124537
			}, {
				"itemId": 3058,
				"itemLabel": "L2W_59",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.050033748372652,
				"choices": ["f", "r", "o", "m"],
				"correctAnswer": "from",
				"audioFileNames": {
					"prompts": ["L2W_From"]
				},
				"discrimination": 3.11664425601575
			}, {
				"itemId": 3060,
				"itemLabel": "L2W_61",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.312374913323662,
				"choices": ["t", "i", "n", "e"],
				"correctAnswer": "tine",
				"audioFileNames": {
					"prompts": ["L2W_Tine"]
				},
				"discrimination": 2.10006124894766
			}, {
				"itemId": 3061,
				"itemLabel": "L2W_62",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.402545063184969,
				"choices": ["b", "o", "n", "e", "d", "m"],
				"correctAnswer": "bone",
				"audioFileNames": {
					"prompts": ["L2Wex_Bone"]
				},
				"discrimination": 3.33923915891478
			}, {
				"itemId": 3062,
				"itemLabel": "L2W_63",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.669237086653862,
				"choices": ["t", "o", "n", "e", "p", "a"],
				"correctAnswer": "tone",
				"audioFileNames": {
					"prompts": ["L2Wex_Tone"]
				},
				"discrimination": 2.07439246419917
			}, {
				"itemId": 3063,
				"itemLabel": "L2W_64",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.1291601049178,
				"choices": ["c", "a", "n", "e", "k", "i"],
				"correctAnswer": "cane",
				"audioFileNames": {
					"prompts": ["L2Wex_Cane"]
				},
				"discrimination": 1.96058174348245
			}, {
				"itemId": 3064,
				"itemLabel": "L2W_65",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.583350877904059,
				"choices": ["c", "o", "n", "e", "k", "h"],
				"correctAnswer": "cone",
				"audioFileNames": {
					"prompts": ["L2Wex_Cone"]
				},
				"discrimination": 2.80357659130048
			}, {
				"itemId": 3067,
				"itemLabel": "L2W_68",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.56401447513609,
				"choices": ["p", "i", "n", "e", "b", "a"],
				"correctAnswer": "pine",
				"audioFileNames": {
					"prompts": ["L2Wex_Pine"]
				},
				"discrimination": 2.71769375333784
			}, {
				"itemId": 3068,
				"itemLabel": "L2W_69",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.585443367365448,
				"choices": ["d", "i", "n", "e", "p", "y"],
				"correctAnswer": "dine",
				"audioFileNames": {
					"prompts": ["L2Wex_Dine"]
				},
				"discrimination": 2.79892570343438
			}, {
				"itemId": 3069,
				"itemLabel": "L2W_70",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.41689024357429,
				"choices": ["f", "i", "n", "e", "p", "h"],
				"correctAnswer": "fine",
				"audioFileNames": {
					"prompts": ["L2Wex_Fine"]
				},
				"discrimination": 3.79365879334975
			}, {
				"itemId": 3071,
				"itemLabel": "L2W_72",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.544490881106732,
				"choices": ["c", "o", "d", "e", "k", "b"],
				"correctAnswer": "code",
				"audioFileNames": {
					"prompts": ["L2Wex_Code"]
				},
				"discrimination": 2.86406531071981
			}, {
				"itemId": 3072,
				"itemLabel": "L2W_73",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.357187380160392,
				"choices": ["n", "o", "t", "e", "m", "y"],
				"correctAnswer": "note",
				"audioFileNames": {
					"prompts": ["L2Wex_Note"]
				},
				"discrimination": 3.80254636944695
			}, {
				"itemId": 3073,
				"itemLabel": "L2W_74",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.635968258128889,
				"choices": ["c", "u", "b", "e", "d", "i"],
				"correctAnswer": "cube",
				"audioFileNames": {
					"prompts": ["L2Wex_Cube"]
				},
				"discrimination": 4.24522260332767
			}, {
				"itemId": 3074,
				"itemLabel": "L2W_75",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.451886138460745,
				"choices": ["c", "u", "t", "e", "i", "x"],
				"correctAnswer": "cute",
				"audioFileNames": {
					"prompts": ["L2Wex_Cute"]
				},
				"discrimination": 3.73210070549663
			}, {
				"itemId": 3075,
				"itemLabel": "L2W_76",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.773843394109111,
				"choices": ["h", "u", "g", "e", "j", "i"],
				"correctAnswer": "huge",
				"audioFileNames": {
					"prompts": ["L2Wex_Huge"]
				},
				"discrimination": 3.36770783553298
			}, {
				"itemId": 3076,
				"itemLabel": "L2W_77",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.521193567955744,
				"choices": ["t", "a", "m", "e", "s", "h"],
				"correctAnswer": "tame",
				"audioFileNames": {
					"prompts": ["L2Wex_Tame"]
				},
				"discrimination": 2.56565713872337
			}, {
				"itemId": 3077,
				"itemLabel": "L2W_78",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.64229789015421,
				"choices": ["f", "a", "m", "e", "p", "h"],
				"correctAnswer": "fame",
				"audioFileNames": {
					"prompts": ["L2Wex_Fame"]
				},
				"discrimination": 2.18286899282643
			}, {
				"itemId": 3078,
				"itemLabel": "L2W_79",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.454889874345205,
				"choices": ["l", "a", "t", "e", "y", "i"],
				"correctAnswer": "late",
				"audioFileNames": {
					"prompts": ["L2Wex_Late"]
				},
				"discrimination": 3.8999250962481
			}, {
				"itemId": 3079,
				"itemLabel": "L2W_80",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.577946508643652,
				"choices": ["v", "a", "s", "e", "w", "i"],
				"correctAnswer": "vase",
				"audioFileNames": {
					"prompts": ["L2Wex_Vase"]
				},
				"discrimination": 3.51396823238806
			}, {
				"itemId": 3080,
				"itemLabel": "L2W_81",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.511123170475667,
				"choices": ["g", "a", "t", "e", "j", "i"],
				"correctAnswer": "gate",
				"audioFileNames": {
					"prompts": ["L2Wex_Gate"]
				},
				"discrimination": 3.77854567589323
			}, {
				"itemId": 3081,
				"itemLabel": "L2W_82",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.519350687658281,
				"choices": ["r", "o", "p", "e", "w", "k"],
				"correctAnswer": "rope",
				"audioFileNames": {
					"prompts": ["L2Wex_Rope"]
				},
				"discrimination": 2.56604636183541
			}, {
				"itemId": 3082,
				"itemLabel": "L2W_83",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.131730142815479,
				"choices": ["h", "o", "m", "e", "g", "n"],
				"correctAnswer": "home",
				"audioFileNames": {
					"prompts": ["L2Wex_Home"]
				},
				"discrimination": 3.99271578246614
			}, {
				"itemId": 3083,
				"itemLabel": "L2W_84",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.561928464139757,
				"choices": ["t", "a", "p", "e", "y", "k"],
				"correctAnswer": "tape",
				"audioFileNames": {
					"prompts": ["L2Wex_Tape"]
				},
				"discrimination": 2.51391411848531
			}, {
				"itemId": 3084,
				"itemLabel": "L2W_85",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.563051772184986,
				"choices": ["t", "a", "k", "e", "c", "i"],
				"correctAnswer": "take",
				"audioFileNames": {
					"prompts": ["L2Wex_Take"]
				},
				"discrimination": 3.31548103509317
			}, {
				"itemId": 3085,
				"itemLabel": "L2W_86",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.0644260699343269,
				"choices": ["l", "i", "k", "e", "y", "c"],
				"correctAnswer": "like",
				"audioFileNames": {
					"prompts": ["L2Wex_Like"]
				},
				"discrimination": 2.18821374885545
			}, {
				"itemId": 3086,
				"itemLabel": "L2W_87",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.330630999952527,
				"choices": ["m", "a", "k", "e", "n", "c"],
				"correctAnswer": "make",
				"audioFileNames": {
					"prompts": ["L2Wex_Make"]
				},
				"discrimination": 2.74171135437601
			}, {
				"itemId": 3087,
				"itemLabel": "L2W_88",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.28411896890535,
				"choices": ["g", "i", "v", "e", "j", "y"],
				"correctAnswer": "give",
				"audioFileNames": {
					"prompts": ["L2Wex_Give"]
				},
				"discrimination": 3.60818580852586
			}, {
				"itemId": 3088,
				"itemLabel": "L2W_89",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.215130987437077,
				"choices": ["s", "o", "o", "n", "u", "c"],
				"correctAnswer": "soon",
				"audioFileNames": {
					"prompts": ["L2Wex_Soon"]
				},
				"discrimination": 2.46089122391215
			}, {
				"itemId": 3089,
				"itemLabel": "L2W_90",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.66735568722243,
				"choices": ["a", "l", "s", "o", "c", "u"],
				"correctAnswer": "also",
				"audioFileNames": {
					"prompts": ["L2Wex_Also"]
				},
				"discrimination": 3.97241822069058
			}, {
				"itemId": 3090,
				"itemLabel": "L2W_91",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.195882291847768,
				"choices": ["j", "u", "m", "p", "g", "a"],
				"correctAnswer": "jump",
				"audioFileNames": {
					"prompts": ["L2Wex_Jump"]
				},
				"discrimination": 3.00792135850585
			}, {
				"itemId": 3091,
				"itemLabel": "L2W_92",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.8891723318001,
				"choices": ["s", "i", "g", "n", "c", "y"],
				"correctAnswer": "sign",
				"audioFileNames": {
					"prompts": ["L2Wex_Sign"]
				},
				"discrimination": 1.37186388616492
			}, {
				"itemId": 3092,
				"itemLabel": "L2W_93",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.346638859645322,
				"choices": ["m", "o", "v", "e", "n", "u"],
				"correctAnswer": "move",
				"audioFileNames": {
					"prompts": ["L2Wex_Move"]
				},
				"discrimination": 2.99952499052562
			}, {
				"itemId": 3093,
				"itemLabel": "L2W_94",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.00707971897562356,
				"choices": ["l", "o", "v", "e", "u", "o"],
				"correctAnswer": "love",
				"audioFileNames": {
					"prompts": ["L2Wex_Love"]
				},
				"discrimination": 3.05228009011385
			}, {
				"itemId": 3094,
				"itemLabel": "L2W_95",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.584984974071259,
				"choices": ["g", "o", "n", "e", "j", "m"],
				"correctAnswer": "gone",
				"audioFileNames": {
					"prompts": ["L2Wex_Gone"]
				},
				"discrimination": 3.32281256060844
			}, {
				"itemId": 3095,
				"itemLabel": "L2W_96",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.343531268381017,
				"choices": ["g", "a", "v", "e", "j", "y"],
				"correctAnswer": "gave",
				"audioFileNames": {
					"prompts": ["L2Wex_Gave"]
				},
				"discrimination": 3.66065507480999
			}, {
				"itemId": 3096,
				"itemLabel": "L2W_97",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.358378610254292,
				"choices": ["o", "p", "e", "n", "i", "m", "b"],
				"correctAnswer": "open",
				"audioFileNames": {
					"prompts": ["L2Wex_Open"]
				},
				"discrimination": 2.50821560226075
			}, {
				"itemId": 3098,
				"itemLabel": "L2W_99",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.525557158908361,
				"choices": ["r", "i", "d", "e", "w", "y", "b"],
				"correctAnswer": "ride",
				"audioFileNames": {
					"prompts": ["L2Wex_Ride"]
				},
				"discrimination": 3.09971860382787
			}, {
				"itemId": 3099,
				"itemLabel": "L2W_100",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.568426639583087,
				"choices": ["d", "o", "n", "e", "b", "u", "m"],
				"correctAnswer": "done",
				"audioFileNames": {
					"prompts": ["L2Wex_Done"]
				},
				"discrimination": 2.8735020711657
			}, {
				"itemId": 3100,
				"itemLabel": "L2W_101",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.610449566031312,
				"choices": ["w", "o", "r", "k", "e", "c", "m"],
				"correctAnswer": "work",
				"audioFileNames": {
					"prompts": ["L2Wex_Work"]
				},
				"discrimination": 3.41522016243654
			}, {
				"itemId": 3101,
				"itemLabel": "L2W_102",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.358580079226876,
				"choices": ["t", "h", "e", "n", "i", "m", "v"],
				"correctAnswer": "then",
				"audioFileNames": {
					"prompts": ["L2Wex_Then"]
				},
				"discrimination": 2.18115352631021
			}, {
				"itemId": 3102,
				"itemLabel": "L2W_103",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.28171346634382,
				"choices": ["v", "e", "r", "y", "w", "i", "x"],
				"correctAnswer": "very",
				"audioFileNames": {
					"prompts": ["L2Wex_Very"]
				},
				"discrimination": 2.99496401894933
			}, {
				"itemId": 3103,
				"itemLabel": "L2W_104",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.241784614655594,
				"choices": ["y", "o", "u", "r", "w", "i", "t"],
				"correctAnswer": "your",
				"audioFileNames": {
					"prompts": ["L2Wex_Your"]
				},
				"discrimination": 2.66130080171462
			}, {
				"itemId": 3104,
				"itemLabel": "L2W_105",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.0759432279110069,
				"choices": ["g", "o", "o", "d", "j", "u", "b"],
				"correctAnswer": "good",
				"audioFileNames": {
					"prompts": ["L2Wex_Good"]
				},
				"discrimination": 3.13239798073181
			}, {
				"itemId": 3105,
				"itemLabel": "L2W_106",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.708726063393182,
				"choices": ["t", "a", "l", "k", "u", "c", "q"],
				"correctAnswer": "talk",
				"audioFileNames": {
					"prompts": ["L2Wex_Talk"]
				},
				"discrimination": 3.56807222634856
			}, {
				"itemId": 3106,
				"itemLabel": "L2W_107",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.06350505794886,
				"choices": ["h", "o", "u", "r", "y", "w", "a"],
				"correctAnswer": "hour",
				"audioFileNames": {
					"prompts": ["L2Wex_Hour"]
				},
				"discrimination": 1.19103014790951
			}, {
				"itemId": 3107,
				"itemLabel": "L2W_108",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.208935558435525,
				"choices": ["b", "l", "u", "e", "d", "h", "i"],
				"correctAnswer": "blue",
				"audioFileNames": {
					"prompts": ["L2Wex_Blue"]
				},
				"discrimination": 2.44478768127508
			}, {
				"itemId": 3108,
				"itemLabel": "L2W_109",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.363384767899098,
				"choices": ["m", "o", "r", "e", "n", "u", "z"],
				"correctAnswer": "more",
				"audioFileNames": {
					"prompts": ["L2Wex_More"]
				},
				"discrimination": 3.77488564606109
			}, {
				"itemId": 3109,
				"itemLabel": "L2W_110",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.148026347485658,
				"choices": ["n", "e", "x", "t", "a", "y", "w"],
				"correctAnswer": "next",
				"audioFileNames": {
					"prompts": ["L2Wex_Next"]
				},
				"discrimination": 3.17571045624808
			}, {
				"itemId": 3110,
				"itemLabel": "L2W_111",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.237986355422783,
				"choices": ["t", "h", "a", "t", "p", "y", "e"],
				"correctAnswer": "that",
				"audioFileNames": {
					"prompts": ["L2Wex_That"]
				},
				"discrimination": 2.90965501557871
			}, {
				"itemId": 3111,
				"itemLabel": "L2W_112",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.553951664724394,
				"choices": ["w", "a", "l", "k", "v", "u", "c"],
				"correctAnswer": "walk",
				"audioFileNames": {
					"prompts": ["L2Wex_Walk"]
				},
				"discrimination": 3.53136672423569
			}, {
				"itemId": 3112,
				"itemLabel": "L2W_113",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.769495042025877,
				"choices": ["s", "c", "o", "p", "e"],
				"correctAnswer": "scope",
				"audioFileNames": {
					"prompts": ["L2W_Scope"]
				},
				"discrimination": 2.5322019572674
			}, {
				"itemId": 3113,
				"itemLabel": "L2W_114",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.429757311773589,
				"choices": ["s", "h", "e", "e", "p"],
				"correctAnswer": "sheep",
				"audioFileNames": {
					"prompts": ["L2W_Sheep"]
				},
				"discrimination": 2.51592935252782
			}, {
				"itemId": 3114,
				"itemLabel": "L2W_115",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.715906062328246,
				"choices": ["c", "l", "o", "c", "k"],
				"correctAnswer": "clock",
				"audioFileNames": {
					"prompts": ["L2W_Clock"]
				},
				"discrimination": 2.99567920054926
			}, {
				"itemId": 3115,
				"itemLabel": "L2W_116",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.777867869814884,
				"choices": ["c", "l", "a", "w", "s"],
				"correctAnswer": "claws",
				"audioFileNames": {
					"prompts": ["L2W_Claws"]
				},
				"discrimination": 3.32091544004076
			}, {
				"itemId": 3116,
				"itemLabel": "L2W_117",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.887630812193384,
				"choices": ["p", "r", "i", "d", "e"],
				"correctAnswer": "pride",
				"audioFileNames": {
					"prompts": ["L2W_Pride"]
				},
				"discrimination": 1.89308509604488
			}, {
				"itemId": 3117,
				"itemLabel": "L2W_118",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.362548361119779,
				"choices": ["b", "r", "o", "w", "n"],
				"correctAnswer": "brown",
				"audioFileNames": {
					"prompts": ["L2W_Brown"]
				},
				"discrimination": 2.7953063205343
			}, {
				"itemId": 3118,
				"itemLabel": "L2W_119",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.654467222834989,
				"choices": ["r", "o", "u", "n", "d"],
				"correctAnswer": "round",
				"audioFileNames": {
					"prompts": ["L2W_Round"]
				},
				"discrimination": 3.32207835184125
			}, {
				"itemId": 3119,
				"itemLabel": "L2W_120",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.573306401273032,
				"choices": ["l", "e", "m", "o", "n"],
				"correctAnswer": "lemon",
				"audioFileNames": {
					"prompts": ["L2W_Lemon"]
				},
				"discrimination": 2.94195359724663
			}, {
				"itemId": 3120,
				"itemLabel": "L2W_121",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.958254136755266,
				"choices": ["f", "r", "u", "i", "t"],
				"correctAnswer": "fruit",
				"audioFileNames": {
					"prompts": ["L2W_Fruit"]
				},
				"discrimination": 2.24747425712576
			}, {
				"itemId": 3121,
				"itemLabel": "L2W_122",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.518445890669881,
				"choices": ["l", "i", "k", "e", "d"],
				"correctAnswer": "liked",
				"audioFileNames": {
					"prompts": ["L2W_Liked"]
				},
				"discrimination": 2.61077858075473
			}, {
				"itemId": 3122,
				"itemLabel": "L2W_123",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.932852672018305,
				"choices": ["r", "i", "g", "h", "t"],
				"correctAnswer": "right",
				"audioFileNames": {
					"prompts": ["L2W_Right"]
				},
				"discrimination": 3.41735796616794
			}, {
				"itemId": 3123,
				"itemLabel": "L2W_124",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.326094395489107,
				"choices": ["s", "e", "v", "e", "n"],
				"correctAnswer": "seven",
				"audioFileNames": {
					"prompts": ["L2W_Seven"]
				},
				"discrimination": 2.37192759244901
			}, {
				"itemId": 3124,
				"itemLabel": "L2W_125",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.432384058591832,
				"choices": ["c", "l", "o", "w", "n"],
				"correctAnswer": "clown",
				"audioFileNames": {
					"prompts": ["L2W_Clown"]
				},
				"discrimination": 2.81655646761166
			}, {
				"itemId": 3125,
				"itemLabel": "L2W_126",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.149785974468299,
				"choices": ["m", "a", "g", "i", "c"],
				"correctAnswer": "magic",
				"audioFileNames": {
					"prompts": ["L2W_Magic"]
				},
				"discrimination": 2.49555092120717
			}, {
				"itemId": 3126,
				"itemLabel": "L2W_127",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.11373255999993,
				"choices": ["t", "r", "i", "e", "s"],
				"correctAnswer": "tries",
				"audioFileNames": {
					"prompts": ["L2W_Tries"]
				},
				"discrimination": 1.71424593859149
			}, {
				"itemId": 3127,
				"itemLabel": "L2W_128",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.00406110050598,
				"choices": ["b", "r", "a", "i", "n"],
				"correctAnswer": "brain",
				"audioFileNames": {
					"prompts": ["L2W_Brain"]
				},
				"discrimination": 2.62122124589738
			}, {
				"itemId": 3128,
				"itemLabel": "L2W_129",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.42861936310415,
				"choices": ["c", "u", "r", "e", "d"],
				"correctAnswer": "cured",
				"audioFileNames": {
					"prompts": ["L2W_Cured"]
				},
				"discrimination": 2.03741642579948
			}, {
				"itemId": 3129,
				"itemLabel": "L2W_130",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.410365173858,
				"choices": ["b", "a", "s", "k", "s"],
				"correctAnswer": "basks",
				"audioFileNames": {
					"prompts": ["L2W_Basks"]
				},
				"discrimination": 1.64354429732818
			}, {
				"itemId": 3130,
				"itemLabel": "L2W_131",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.915624306879573,
				"choices": ["m", "i", "n", "o", "r"],
				"correctAnswer": "minor",
				"audioFileNames": {
					"prompts": ["L2W_Minor"]
				},
				"discrimination": 3.60543059237109
			}, {
				"itemId": 3131,
				"itemLabel": "L2W_132",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.59772486398089,
				"choices": ["m", "o", "n", "e", "y"],
				"correctAnswer": "money",
				"audioFileNames": {
					"prompts": ["L2W_Money"]
				},
				"discrimination": 2.38407528368646
			}, {
				"itemId": 3132,
				"itemLabel": "L2W_133",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.859961865232249,
				"choices": ["p", "o", "i", "n", "t"],
				"correctAnswer": "point",
				"audioFileNames": {
					"prompts": ["L2W_Point"]
				},
				"discrimination": 2.93502082067825
			}, {
				"itemId": 3133,
				"itemLabel": "L2W_134",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.10948855491334,
				"choices": ["t", "h", "e", "i", "r", "a", "v"],
				"correctAnswer": "their",
				"audioFileNames": {
					"prompts": ["L2Wex_Their"]
				},
				"discrimination": 1.09520814385456
			}, {
				"itemId": 3134,
				"itemLabel": "L2W_135",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.20469237817177,
				"choices": ["f", "i", "e", "l", "d", "p", "a"],
				"correctAnswer": "field",
				"audioFileNames": {
					"prompts": ["L2Wex_Field"]
				},
				"discrimination": 0.594881206101129
			}, {
				"itemId": 3135,
				"itemLabel": "L2W_136",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.11388247783584,
				"choices": ["s", "l", "o", "p", "e", "c", "u"],
				"correctAnswer": "slope",
				"audioFileNames": {
					"prompts": ["L2Wex_Slope"]
				},
				"discrimination": 2.63593615926949
			}, {
				"itemId": 3136,
				"itemLabel": "L2W_137",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.964788607338606,
				"choices": ["y", "o", "u", "n", "g", "i", "a"],
				"correctAnswer": "young",
				"audioFileNames": {
					"prompts": ["L2Wex_Young"]
				},
				"discrimination": 1.37290754824939
			}, {
				"itemId": 3138,
				"itemLabel": "L2W_139",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.829183600136571,
				"choices": ["t", "h", "e", "r", "e", "a", "n"],
				"correctAnswer": "there",
				"audioFileNames": {
					"prompts": ["L2Wex_There"]
				},
				"discrimination": 2.54741070745617
			}, {
				"itemId": 3139,
				"itemLabel": "L2W_140",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.9849578060098,
				"choices": ["e", "a", "r", "l", "y", "u", "I"],
				"correctAnswer": "early",
				"audioFileNames": {
					"prompts": ["L2Wex_Early"]
				},
				"discrimination": 0.55662622225708
			}, {
				"itemId": 3140,
				"itemLabel": "L2W_141",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.817924518994539,
				"choices": ["d", "r", "i", "n", "k", "e", "c"],
				"correctAnswer": "drink",
				"audioFileNames": {
					"prompts": ["L2Wex_Drink"]
				},
				"discrimination": 3.29349139938027
			}, {
				"itemId": 3141,
				"itemLabel": "L2W_142",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.850749537192895,
				"choices": ["w", "o", "r", "l", "d", "v", "e"],
				"correctAnswer": "world",
				"audioFileNames": {
					"prompts": ["L2Wex_World"]
				},
				"discrimination": 2.95286158681508
			}, {
				"itemId": 3142,
				"itemLabel": "L2W_143",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.751535996009102,
				"choices": ["t", "h", "i", "n", "k", "e", "c"],
				"correctAnswer": "think",
				"audioFileNames": {
					"prompts": ["L2Wex_Think"]
				},
				"discrimination": 3.28539847883907
			}, {
				"itemId": 3143,
				"itemLabel": "L2W_144",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.11120401363812,
				"choices": ["s", "k", "a", "t", "e", "c", "y"],
				"correctAnswer": "skate",
				"audioFileNames": {
					"prompts": ["L2Wex_Skate"]
				},
				"discrimination": 2.91580168987228
			}, {
				"itemId": 3144,
				"itemLabel": "L2W_145",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.675672647550397,
				"choices": ["p", "a", "p", "e", "r", "u", "i"],
				"correctAnswer": "paper",
				"audioFileNames": {
					"prompts": ["L2Wex_Paper"]
				},
				"discrimination": 3.84308489134499
			}, {
				"itemId": 3145,
				"itemLabel": "L2W_146",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.38291940897392,
				"choices": ["w", "r", "i", "t", "e", "y", "v"],
				"correctAnswer": "write",
				"audioFileNames": {
					"prompts": ["L2Wex_Write"]
				},
				"discrimination": 2.26887928536394
			}, {
				"itemId": 3146,
				"itemLabel": "L2W_147",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.727071889874067,
				"choices": ["b", "e", "i", "n", "g", "d", "y"],
				"correctAnswer": "being",
				"audioFileNames": {
					"prompts": ["L2Wex_Being"]
				},
				"discrimination": 2.42297161799324
			}, {
				"itemId": 3147,
				"itemLabel": "L2W_148",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.92430328835448,
				"choices": ["c", "o", "l", "o", "r", "k", "u", "q"],
				"correctAnswer": "color",
				"audioFileNames": {
					"prompts": ["L2Wex_Color"]
				},
				"discrimination": 3.13306968600492
			}, {
				"itemId": 3148,
				"itemLabel": "L2W_149",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.909609358398571,
				"choices": ["s", "t", "u", "d", "y", "e", "c", "a"],
				"correctAnswer": "study",
				"audioFileNames": {
					"prompts": ["L2Wex_Study"]
				},
				"discrimination": 2.618661064737
			}, {
				"itemId": 3150,
				"itemLabel": "L2W_151",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.54356937787262,
				"choices": ["h", "e", "a", "v", "y", "g", "i", "d"],
				"correctAnswer": "heavy",
				"audioFileNames": {
					"prompts": ["L2Wex_Heavy"]
				},
				"discrimination": 1.66019989093228
			}, {
				"itemId": 3151,
				"itemLabel": "L2W_152",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.691318750565432,
				"choices": ["u", "n", "d", "e", "r", "a", "m", "b"],
				"correctAnswer": "under",
				"audioFileNames": {
					"prompts": ["L2Wex_Under"]
				},
				"discrimination": 4.81561210106448
			}, {
				"itemId": 3152,
				"itemLabel": "L2W_153",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.40134460600201,
				"choices": ["m", "u", "r", "a", "l", "e", "w", "i"],
				"correctAnswer": "mural",
				"audioFileNames": {
					"prompts": ["L2Wex_Mural"]
				},
				"discrimination": 1.23278675453256
			}, {
				"itemId": 3153,
				"itemLabel": "L2W_154",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.666262339215798,
				"choices": ["s", "t", "o", "r", "y", "e", "c", "p"],
				"correctAnswer": "story",
				"audioFileNames": {
					"prompts": ["L2Wex_Story"]
				},
				"discrimination": 2.78895415460389
			}, {
				"itemId": 3154,
				"itemLabel": "L2W_155",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.5262802480589,
				"choices": ["p", "i", "e", "c", "e", "s", "q", "f"],
				"correctAnswer": "piece",
				"audioFileNames": {
					"prompts": ["L2Wex_Piece"]
				},
				"discrimination": 2.16095277056616
			}, {
				"itemId": 3155,
				"itemLabel": "L2W_156",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.18053756687329,
				"choices": ["w", "a", "t", "c", "h", "v", "u", "i"],
				"correctAnswer": "watch",
				"audioFileNames": {
					"prompts": ["L2Wex_Watch"]
				},
				"discrimination": 2.58762414265657
			}, {
				"itemId": 3156,
				"itemLabel": "L2W_157",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.854323856100387,
				"choices": ["n", "i", "g", "h", "t", "y", "w", "m"],
				"correctAnswer": "night",
				"audioFileNames": {
					"prompts": ["L2Wex_Night"]
				},
				"discrimination": 3.51634691487613
			}, {
				"itemId": 3157,
				"itemLabel": "L2W_158",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.761276252750444,
				"choices": ["l", "a", "t", "e", "r", "y", "u", "s"],
				"correctAnswer": "later",
				"audioFileNames": {
					"prompts": ["L2Wex_Later"]
				},
				"discrimination": 4.40639387693738
			}, {
				"itemId": 3158,
				"itemLabel": "L2W_159",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.58743150109984,
				"choices": ["v", "a", "l", "u", "e", "w", "y", "i"],
				"correctAnswer": "value",
				"audioFileNames": {
					"prompts": ["L2Wex_Value"]
				},
				"discrimination": 2.13669385780129
			}, {
				"itemId": 3159,
				"itemLabel": "L2W_160",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.43958953487494,
				"choices": ["c", "a", "r", "r", "y", "k", "e", "w"],
				"correctAnswer": "carry",
				"audioFileNames": {
					"prompts": ["L2Wex_Carry"]
				},
				"discrimination": 2.05677732167776
			}, {
				"itemId": 3160,
				"itemLabel": "L2W_161",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.05829618228487,
				"choices": ["l", "e", "a", "v", "e", "w", "i", "z"],
				"correctAnswer": "leave",
				"audioFileNames": {
					"prompts": ["L2Wex_Leave"]
				},
				"discrimination": 3.87127599540077
			}, {
				"itemId": 3161,
				"itemLabel": "L2W_162",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.42749584538322,
				"choices": ["b", "u", "i", "l", "d", "d", "a", "y"],
				"correctAnswer": "build",
				"audioFileNames": {
					"prompts": ["L2Wex_Build"]
				},
				"discrimination": 2.45543115699214
			}, {
				"itemId": 3162,
				"itemLabel": "L2W_163",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.92303035610887,
				"choices": ["p", "e", "n", "c", "i", "l"],
				"correctAnswer": "pencil",
				"audioFileNames": {
					"prompts": ["L2W_Pencil"]
				},
				"discrimination": 3.0829019967376
			}, {
				"itemId": 3163,
				"itemLabel": "L2W_164",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.01646628463755,
				"choices": ["a", "r", "o", "u", "n", "d"],
				"correctAnswer": "around",
				"audioFileNames": {
					"prompts": ["L2W_Around"]
				},
				"discrimination": 3.39199293623677
			}, {
				"itemId": 3164,
				"itemLabel": "L2W_165",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.613707820908628,
				"choices": ["m", "o", "t", "h", "e", "r"],
				"correctAnswer": "mother",
				"audioFileNames": {
					"prompts": ["L2W_Mother"]
				},
				"discrimination": 4.00340609740062
			}, {
				"itemId": 3165,
				"itemLabel": "L2W_166",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.817429165810943,
				"choices": ["m", "a", "r", "k", "e", "r"],
				"correctAnswer": "marker",
				"audioFileNames": {
					"prompts": ["L2W_Marker"]
				},
				"discrimination": 4.31703217630586
			}, {
				"itemId": 3166,
				"itemLabel": "L2W_167",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.85411981232702,
				"choices": ["b", "e", "a", "u", "t", "y"],
				"correctAnswer": "beauty",
				"audioFileNames": {
					"prompts": ["L2W_Beauty"]
				},
				"discrimination": 1.11906062385205
			}, {
				"itemId": 3167,
				"itemLabel": "L2W_168",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.37916187431962,
				"choices": ["n", "a", "t", "i", "o", "n"],
				"correctAnswer": "nation",
				"audioFileNames": {
					"prompts": ["L2W_Nation"]
				},
				"discrimination": 2.6353108895309
			}, {
				"itemId": 3168,
				"itemLabel": "L2W_169",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.653554366848894,
				"choices": ["s", "t", "a", "m", "p", "s"],
				"correctAnswer": "stamps",
				"audioFileNames": {
					"prompts": ["L2W_Stamps"]
				},
				"discrimination": 2.42767503221271
			}, {
				"itemId": 3169,
				"itemLabel": "L2W_170",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.02898272734865,
				"choices": ["f", "r", "i", "e", "n", "d"],
				"correctAnswer": "friend",
				"audioFileNames": {
					"prompts": ["L2W_Friend"]
				},
				"discrimination": 2.42032900641177
			}, {
				"itemId": 3170,
				"itemLabel": "L2W_171",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.732933594200931,
				"choices": ["i", "n", "s", "e", "c", "t"],
				"correctAnswer": "insect",
				"audioFileNames": {
					"prompts": ["L2W_Insect"]
				},
				"discrimination": 2.23893684514829
			}, {
				"itemId": 3171,
				"itemLabel": "L2W_172",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.43113667359633,
				"choices": ["w", "a", "r", "p", "e", "d"],
				"correctAnswer": "warped",
				"audioFileNames": {
					"prompts": ["L2W_Warped"]
				},
				"discrimination": 1.989045509658
			}, {
				"itemId": 3172,
				"itemLabel": "L2W_173",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.77069139488401,
				"choices": ["p", "a", "i", "n", "e", "d"],
				"correctAnswer": "pained",
				"audioFileNames": {
					"prompts": ["L2W_Pained"]
				},
				"discrimination": 1.84597314212543
			}, {
				"itemId": 3173,
				"itemLabel": "L2W_174",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.14300289677725,
				"choices": ["r", "a", "p", "t", "o", "r"],
				"correctAnswer": "raptor",
				"audioFileNames": {
					"prompts": ["L2W_Raptor"]
				},
				"discrimination": 3.72862237473821
			}, {
				"itemId": 3174,
				"itemLabel": "L2W_175",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.19644816446708,
				"choices": ["r", "a", "n", "k", "e", "d"],
				"correctAnswer": "ranked",
				"audioFileNames": {
					"prompts": ["L2W_Ranked"]
				},
				"discrimination": 3.49971606277178
			}, {
				"itemId": 3175,
				"itemLabel": "L2W_176",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.76721082135979,
				"choices": ["s", "m", "e", "l", "l", "y"],
				"correctAnswer": "smelly",
				"audioFileNames": {
					"prompts": ["L2W_Smelly"]
				},
				"discrimination": 2.17960259365381
			}, {
				"itemId": 3176,
				"itemLabel": "L2W_177",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.01203100094546,
				"choices": ["p", "u", "s", "h", "e", "d"],
				"correctAnswer": "pushed",
				"audioFileNames": {
					"prompts": ["L2W_Pushed"]
				},
				"discrimination": 3.3597848859062
			}, {
				"itemId": 3177,
				"itemLabel": "L2W_178",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.883331335977261,
				"choices": ["f", "o", "r", "m", "e", "r"],
				"correctAnswer": "former",
				"audioFileNames": {
					"prompts": ["L2W_Former"]
				},
				"discrimination": 3.25706848746511
			}, {
				"itemId": 3178,
				"itemLabel": "L2W_179",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.738060866470093,
				"choices": ["p", "r", "e", "t", "t", "y", "i", "d"],
				"correctAnswer": "pretty",
				"audioFileNames": {
					"prompts": ["L2Wex_Pretty"]
				},
				"discrimination": 1.06672973837336
			}, {
				"itemId": 3179,
				"itemLabel": "L2W_180",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.12086397431545,
				"choices": ["a", "c", "r", "o", "s", "s", "u", "t"],
				"correctAnswer": "across",
				"audioFileNames": {
					"prompts": ["L2Wex_Across"]
				},
				"discrimination": 1.31888323023147
			}, {
				"itemId": 3180,
				"itemLabel": "L2W_181",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.32208447418934,
				"choices": ["b", "e", "f", "o", "r", "e", "d", "i"],
				"correctAnswer": "before",
				"audioFileNames": {
					"prompts": ["L2Wex_Before"]
				},
				"discrimination": 1.81516010756562
			}, {
				"itemId": 3181,
				"itemLabel": "L2W_182",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.41121202191115,
				"choices": ["l", "i", "s", "t", "e", "n", "a", "d"],
				"correctAnswer": "listen",
				"audioFileNames": {
					"prompts": ["L2Wex_Listen"]
				},
				"discrimination": 1.50849203917711
			}, {
				"itemId": 3182,
				"itemLabel": "L2W_183",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.976264861922676,
				"choices": ["f", "a", "m", "i", "l", "y", "e", "p"],
				"correctAnswer": "family",
				"audioFileNames": {
					"prompts": ["L2Wex_Family"]
				},
				"discrimination": 2.72300599113219
			}, {
				"itemId": 3183,
				"itemLabel": "L2W_184",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.853992692495547,
				"choices": ["a", "l", "m", "o", "s", "t", "u", "c"],
				"correctAnswer": "almost",
				"audioFileNames": {
					"prompts": ["L2Wex_Almost"]
				},
				"discrimination": 4.17192601066523
			}, {
				"itemId": 3184,
				"itemLabel": "L2W_185",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.716287250917189,
				"choices": ["f", "a", "t", "h", "e", "r", "p", "h"],
				"correctAnswer": "father",
				"audioFileNames": {
					"prompts": ["L2Wex_Father"]
				},
				"discrimination": 3.66010023780074
			}, {
				"itemId": 3185,
				"itemLabel": "L2W_186",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.907891416765123,
				"choices": ["w", "a", "l", "k", "e", "r", "d", "t"],
				"correctAnswer": "walker",
				"audioFileNames": {
					"prompts": ["L2Wex_Walker"]
				},
				"discrimination": 3.9795607680972
			}, {
				"itemId": 3186,
				"itemLabel": "L2W_187",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.606845833062,
				"choices": ["t", "r", "u", "t", "h", "s", "o", "p"],
				"correctAnswer": "truths",
				"audioFileNames": {
					"prompts": ["L2Wex_Truths"]
				},
				"discrimination": 2.07059521115975
			}, {
				"itemId": 3187,
				"itemLabel": "L2W_188",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.764389640943843,
				"choices": ["s", "w", "i", "n", "g", "s", "d", "a"],
				"correctAnswer": "swings",
				"audioFileNames": {
					"prompts": ["L2Wex_Swings"]
				},
				"discrimination": 2.85609047058034
			}, {
				"itemId": 3188,
				"itemLabel": "L2W_189",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.539286855080019,
				"choices": ["f", "a", "c", "i", "n", "g", "b", "d"],
				"correctAnswer": "facing",
				"audioFileNames": {
					"prompts": ["L2Wex_Facing"]
				},
				"discrimination": 3.76504262209637
			}, {
				"itemId": 3189,
				"itemLabel": "L2W_190",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.44728220497168,
				"choices": ["r", "e", "a", "l", "l", "y", "i", "w", "s"],
				"correctAnswer": "really",
				"audioFileNames": {
					"prompts": ["L2Wex_Really"]
				},
				"discrimination": 1.43163011467653
			}, {
				"itemId": 3190,
				"itemLabel": "L2W_191",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.58580102380341,
				"choices": ["e", "i", "t", "h", "e", "r", "u", "y", "v"],
				"correctAnswer": "either",
				"audioFileNames": {
					"prompts": ["L2Wex_Either"]
				},
				"discrimination": 1.03921163271774
			}, {
				"itemId": 3191,
				"itemLabel": "L2W_192",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.19564340785122,
				"choices": ["p", "l", "e", "a", "s", "e", "q", "y", "d"],
				"correctAnswer": "please",
				"audioFileNames": {
					"prompts": ["L2Wex_Please"]
				},
				"discrimination": 2.56320998962965
			}, {
				"itemId": 3192,
				"itemLabel": "L2W_193",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.22937357616456,
				"choices": ["s", "e", "c", "o", "n", "d", "u", "m", "t"],
				"correctAnswer": "second",
				"audioFileNames": {
					"prompts": ["L2Wex_Second"]
				},
				"discrimination": 3.27570547361149
			}, {
				"itemId": 3193,
				"itemLabel": "L2W_194",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.6515045264241,
				"choices": ["q", "u", "e", "a", "s", "y", "c", "z", "i"],
				"correctAnswer": "queasy",
				"audioFileNames": {
					"prompts": ["L2Wex_Queasy"]
				},
				"discrimination": 0.689699496160843
			}, {
				"itemId": 3194,
				"itemLabel": "L2W_195",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.58248836962501,
				"choices": ["c", "o", "w", "a", "r", "d", "k", "u", "v"],
				"correctAnswer": "coward",
				"audioFileNames": {
					"prompts": ["L2Wex_Coward"]
				},
				"discrimination": 1.17918906019942
			}, {
				"itemId": 3195,
				"itemLabel": "L2W_196",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.820385428335092,
				"choices": ["n", "u", "m", "b", "e", "r", "w", "a", "d"],
				"correctAnswer": "number",
				"audioFileNames": {
					"prompts": ["L2Wex_Number"]
				},
				"discrimination": 3.96475074484628
			}, {
				"itemId": 3196,
				"itemLabel": "L2W_197",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.864181465571995,
				"choices": ["n", "e", "w", "e", "s", "t", "g", "e", "x"],
				"correctAnswer": "newest",
				"audioFileNames": {
					"prompts": ["L2Wex_Newest"]
				},
				"discrimination": 3.13853706188623
			}, {
				"itemId": 3197,
				"itemLabel": "L2W_198",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.42862688395402,
				"choices": ["f", "a", "c", "e", "t", "s", "h", "q", "o"],
				"correctAnswer": "facets",
				"audioFileNames": {
					"prompts": ["L2Wex_Facets"]
				},
				"discrimination": 1.26441660907379
			}, {
				"itemId": 3198,
				"itemLabel": "L2W_199",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.995568265295972,
				"choices": ["p", "a", "r", "k", "e", "d", "n", "x", "e"],
				"correctAnswer": "parked",
				"audioFileNames": {
					"prompts": ["L2Wex_Parked"]
				},
				"discrimination": 4.35498194383036
			}, {
				"itemId": 3199,
				"itemLabel": "L2W_200",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.48448449495627,
				"choices": ["h", "o", "r", "n", "e", "d", "s", "p", "a"],
				"correctAnswer": "horned",
				"audioFileNames": {
					"prompts": ["L2Wex_Horned"]
				},
				"discrimination": 2.50706414829058
			}, {
				"itemId": 3200,
				"itemLabel": "L2W_201",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.95354372011216,
				"choices": ["h", "a", "p", "p", "e", "n", "b", "s", "x"],
				"correctAnswer": "happen",
				"audioFileNames": {
					"prompts": ["L2Wex_Happen"]
				},
				"discrimination": 2.86396918530756
			}, {
				"itemId": 3201,
				"itemLabel": "L2W_202",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.739791786556353,
				"choices": ["w", "a", "n", "t", "e", "d", "g", "b", "n"],
				"correctAnswer": "wanted",
				"audioFileNames": {
					"prompts": ["L2Wex_Wanted"]
				},
				"discrimination": 3.79482209687172
			}, {
				"itemId": 3202,
				"itemLabel": "L2W_203",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.736754504615074,
				"choices": ["w", "i", "n", "t", "e", "r", "s", "n", "w"],
				"correctAnswer": "winter",
				"audioFileNames": {
					"prompts": ["L2Wex_Winter"]
				},
				"discrimination": 3.87220133755005
			}, {
				"itemId": 3203,
				"itemLabel": "L2W_204",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.940224513865155,
				"choices": ["s", "u", "m", "m", "e", "r", "o", "p", "l"],
				"correctAnswer": "summer",
				"audioFileNames": {
					"prompts": ["L2Wex_Summer"]
				},
				"discrimination": 3.45533607492504
			}, {
				"itemId": 3204,
				"itemLabel": "L2W_205",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.58490371021701,
				"choices": ["w", "e", "a", "t", "h", "e", "r"],
				"correctAnswer": "weather",
				"audioFileNames": {
					"prompts": ["L2W_Weather"]
				},
				"discrimination": 1.5859397036492
			}, {
				"itemId": 3205,
				"itemLabel": "L2W_206",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.60460241796211,
				"choices": ["p", "r", "a", "c", "t", "i", "c", "e"],
				"correctAnswer": "practice",
				"audioFileNames": {
					"prompts": ["L2W_Practice"]
				},
				"discrimination": 1.91361808673601
			}, {
				"itemId": 3206,
				"itemLabel": "L2W_207",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.931193660746937,
				"choices": ["d", "i", "a", "g", "r", "a", "m"],
				"correctAnswer": "diagram",
				"audioFileNames": {
					"prompts": ["L2W_Diagram"]
				},
				"discrimination": 2.41526036855579
			}, {
				"itemId": 3207,
				"itemLabel": "L2W_208",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.96098476901197,
				"choices": ["c", "o", "u", "r", "a", "g", "e"],
				"correctAnswer": "courage",
				"audioFileNames": {
					"prompts": ["L2W_Courage"]
				},
				"discrimination": 1.32084808852051
			}, {
				"itemId": 3208,
				"itemLabel": "L2W_209",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.94608593842114,
				"choices": ["b", "r", "o", "u", "g", "h", "t"],
				"correctAnswer": "brought",
				"audioFileNames": {
					"prompts": ["L2W_Brought"]
				},
				"discrimination": 1.59176532140153
			}, {
				"itemId": 3209,
				"itemLabel": "L2W_210",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.97744288520986,
				"choices": ["g", "h", "a", "s", "t", "l", "y"],
				"correctAnswer": "ghastly",
				"audioFileNames": {
					"prompts": ["L2W_Ghastly"]
				},
				"discrimination": 1.26994392396724
			}, {
				"itemId": 3210,
				"itemLabel": "L2W_211",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.24756940688945,
				"choices": ["c", "r", "o", "w", "d", "e", "d"],
				"correctAnswer": "crowded",
				"audioFileNames": {
					"prompts": ["L2W_Crowded"]
				},
				"discrimination": 2.66002634014254
			}, {
				"itemId": 3211,
				"itemLabel": "L2W_212",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.36332809385786,
				"choices": ["w", "o", "r", "r", "i", "e", "d"],
				"correctAnswer": "worried",
				"audioFileNames": {
					"prompts": ["L2W_Worried"]
				},
				"discrimination": 1.0899423372788
			}, {
				"itemId": 3212,
				"itemLabel": "L2W_213",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.87132208675615,
				"choices": ["c", "l", "i", "m", "a", "t", "e"],
				"correctAnswer": "climate",
				"audioFileNames": {
					"prompts": ["L2W_Climate"]
				},
				"discrimination": 1.38785286554174
			}, {
				"itemId": 3213,
				"itemLabel": "L2W_214",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.36890097681622,
				"choices": ["a", "b", "a", "n", "d", "o", "n"],
				"correctAnswer": "abandon",
				"audioFileNames": {
					"prompts": ["L2W_Abandon"]
				},
				"discrimination": 1.68965683428174
			}, {
				"itemId": 3214,
				"itemLabel": "L2W_215",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.08063696429613,
				"choices": ["b", "r", "e", "a", "t", "h", "e"],
				"correctAnswer": "breathe",
				"audioFileNames": {
					"prompts": ["L2W_Breathe"]
				},
				"discrimination": 0.582537980364237
			}, {
				"itemId": 3215,
				"itemLabel": "L2W_216",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.89528457364246,
				"choices": ["c", "h", "a", "p", "t", "e", "r"],
				"correctAnswer": "chapter",
				"audioFileNames": {
					"prompts": ["L2W_Chapter"]
				},
				"discrimination": 3.63224228275186
			}, {
				"itemId": 3216,
				"itemLabel": "L2W_217",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.727997868968618,
				"choices": ["w", "i", "t", "h", "o", "u", "t", "e", "z"],
				"correctAnswer": "without",
				"audioFileNames": {
					"prompts": ["L2Wex_Without"]
				},
				"discrimination": 3.62775408721858
			}, {
				"itemId": 3217,
				"itemLabel": "L2W_218",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.8568400418872,
				"choices": ["i", "n", "s", "t", "e", "a", "d", "o", "b"],
				"correctAnswer": "instead",
				"audioFileNames": {
					"prompts": ["L2Wex_Instead"]
				},
				"discrimination": 2.70152227848364
			}, {
				"itemId": 3218,
				"itemLabel": "L2W_219",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.992757902425118,
				"choices": ["b", "r", "o", "t", "h", "e", "r", "d", "u"],
				"correctAnswer": "brother",
				"audioFileNames": {
					"prompts": ["L2Wex_Brother"]
				},
				"discrimination": 3.6154627075055
			}, {
				"itemId": 3219,
				"itemLabel": "L2W_220",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.50899570593192,
				"choices": ["e", "x", "a", "m", "p", "l", "e", "i", "u"],
				"correctAnswer": "example",
				"audioFileNames": {
					"prompts": ["L2Wex_Example"]
				},
				"discrimination": 1.36676353964369
			}, {
				"itemId": 3220,
				"itemLabel": "L2W_221",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.1412229394674,
				"choices": ["p", "i", "c", "t", "u", "r", "e", "b", "k"],
				"correctAnswer": "picture",
				"audioFileNames": {
					"prompts": ["L2Wex_Picture"]
				},
				"discrimination": 1.45549737209134
			}, {
				"itemId": 3221,
				"itemLabel": "L2W_222",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.54199991597339,
				"choices": ["b", "e", "t", "w", "e", "e", "n", "i", "d"],
				"correctAnswer": "between",
				"audioFileNames": {
					"prompts": ["L2Wex_Between"]
				},
				"discrimination": 2.16083735707257
			}, {
				"itemId": 3222,
				"itemLabel": "L2W_223",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.184597690203,
				"choices": ["p", "r", "e", "s", "e", "n", "t", "c", "i"],
				"correctAnswer": "present",
				"audioFileNames": {
					"prompts": ["L2Wex_Present"]
				},
				"discrimination": 2.62204346732212
			}, {
				"itemId": 3223,
				"itemLabel": "L2W_224",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.993924733519637,
				"choices": ["w", "a", "r", "m", "i", "n", "g", "f", "u"],
				"correctAnswer": "warming",
				"audioFileNames": {
					"prompts": ["L2Wex_Warming"]
				},
				"discrimination": 3.6474697649306
			}, {
				"itemId": 3224,
				"itemLabel": "L2W_225",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.834944870423223,
				"choices": ["s", "t", "a", "n", "d", "i", "n", "g", "g", "b"],
				"correctAnswer": "standing",
				"audioFileNames": {
					"prompts": ["L2Wex_Standing"]
				},
				"discrimination": 3.45908170688887
			}, {
				"itemId": 3225,
				"itemLabel": "L2W_226",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.0612582844364,
				"choices": ["w", "a", "r", "n", "i", "n", "g", "c", "a"],
				"correctAnswer": "warning",
				"audioFileNames": {
					"prompts": ["L2Wex_Warning"]
				},
				"discrimination": 2.99933814621923
			}, {
				"itemId": 3226,
				"itemLabel": "L2W_227",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.10396708628268,
				"choices": ["r", "e", "p", "t", "i", "l", "e", "x", "o"],
				"correctAnswer": "reptile",
				"audioFileNames": {
					"prompts": ["L2Wex_Reptile"]
				},
				"discrimination": 3.01688008498279
			}, {
				"itemId": 3227,
				"itemLabel": "L2W_228",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.713818938749181,
				"choices": ["h", "a", "n", "d", "i", "n", "g", "q", "o"],
				"correctAnswer": "handing",
				"audioFileNames": {
					"prompts": ["L2Wex_Handing"]
				},
				"discrimination": 2.87868340931101
			}, {
				"itemId": 3228,
				"itemLabel": "L2W_229",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.14530313772238,
				"choices": ["b", "e", "c", "a", "u", "s", "e", "i", "k", "z"],
				"correctAnswer": "because",
				"audioFileNames": {
					"prompts": ["L2Wex_Because"]
				},
				"discrimination": 2.29666314129105
			}, {
				"itemId": 3229,
				"itemLabel": "L2W_230",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.24109789411569,
				"choices": ["e", "l", "e", "g", "a", "n", "t", "j", "m", "s"],
				"correctAnswer": "elegant",
				"audioFileNames": {
					"prompts": ["L2Wex_Elegant"]
				},
				"discrimination": 1.16436369291272
			}, {
				"itemId": 3230,
				"itemLabel": "L2W_231",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.57690772600661,
				"choices": ["s", "y", "n", "o", "n", "y", "m", "c", "a", "i"],
				"correctAnswer": "synonym",
				"audioFileNames": {
					"prompts": ["L2Wex_Synonym"]
				},
				"discrimination": 1.71852154632514
			}, {
				"itemId": 3231,
				"itemLabel": "L2W_232",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.26445863285529,
				"choices": ["t", "r", "i", "u", "m", "p", "h", "f", "y", "a"],
				"correctAnswer": "triumph",
				"audioFileNames": {
					"prompts": ["L2Wex_Triumph"]
				},
				"discrimination": 1.61859422832545
			}, {
				"itemId": 3232,
				"itemLabel": "L2W_233",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.67366995429916,
				"choices": ["a", "v", "e", "r", "a", "g", "e", "w", "u", "j"],
				"correctAnswer": "average",
				"audioFileNames": {
					"prompts": ["L2Wex_Average"]
				},
				"discrimination": 1.84005235997466
			}, {
				"itemId": 3233,
				"itemLabel": "L2W_234",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.71600895104335,
				"choices": ["j", "o", "u", "r", "n", "e", "y", "g", "a", "i"],
				"correctAnswer": "journey",
				"audioFileNames": {
					"prompts": ["L2Wex_Journey"]
				},
				"discrimination": 1.83944038066056
			}, {
				"itemId": 3234,
				"itemLabel": "L2W_235",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.37396753611963,
				"choices": ["m", "o", "n", "t", "h", "l", "y", "c", "e", "a"],
				"correctAnswer": "monthly",
				"audioFileNames": {
					"prompts": ["L2Wex_Monthly"]
				},
				"discrimination": 2.46240233945456
			}, {
				"itemId": 3235,
				"itemLabel": "L2W_236",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.71260919368531,
				"choices": ["h", "a", "r", "k", "e", "n", "s", "o", "u", "p"],
				"correctAnswer": "harkens",
				"audioFileNames": {
					"prompts": ["L2Wex_Harkens"]
				},
				"discrimination": 1.72235998608509
			}, {
				"itemId": 3236,
				"itemLabel": "L2W_237",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.888169213578747,
				"choices": ["b", "l", "a", "s", "t", "e", "d", "s", "f", "o"],
				"correctAnswer": "blasted",
				"audioFileNames": {
					"prompts": ["L2Wex_Blasted"]
				},
				"discrimination": 2.58124813331476
			}, {
				"itemId": 3237,
				"itemLabel": "L2W_238",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.661618746362406,
				"choices": ["p", "a", "r", "k", "i", "n", "g", "h", "e", "b"],
				"correctAnswer": "parking",
				"audioFileNames": {
					"prompts": ["L2Wex_Parking"]
				},
				"discrimination": 3.4316718624354
			}, {
				"itemId": 3238,
				"itemLabel": "L2W_239",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.609614329198799,
				"choices": ["w", "a", "l", "k", "i", "n", "g", "y", "h", "m"],
				"correctAnswer": "walking",
				"audioFileNames": {
					"prompts": ["L2Wex_Walking"]
				},
				"discrimination": 2.41775239482749
			}, {
				"itemId": 3239,
				"itemLabel": "L2W_240",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.726841060783395,
				"choices": ["f", "o", "r", "e", "v", "e", "r", "d", "h", "b"],
				"correctAnswer": "forever",
				"audioFileNames": {
					"prompts": ["L2Wex_Forever"]
				},
				"discrimination": 2.93182500870539
			}, {
				"itemId": 3240,
				"itemLabel": "L2W_241",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 4.05398508702223,
				"choices": ["f", "o", "r", "e", "i", "g", "n", "o", "p", "e"],
				"correctAnswer": "foreign",
				"audioFileNames": {
					"prompts": ["L2Wex_Foreign"]
				},
				"discrimination": 1.42996799431579
			}, {
				"itemId": 3241,
				"itemLabel": "L2W_242",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.69721171714704,
				"choices": ["c", "o", "m", "p", "l", "e", "t", "e"],
				"correctAnswer": "complete",
				"audioFileNames": {
					"prompts": ["L2W_Complete"]
				},
				"discrimination": 1.89025087789025
			}, {
				"itemId": 3242,
				"itemLabel": "L2W_243",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.09461388026267,
				"choices": ["c", "o", "n", "t", "r", "a", "s", "t"],
				"correctAnswer": "contrast",
				"audioFileNames": {
					"prompts": ["L2W_Contrast"]
				},
				"discrimination": 2.1261502977231
			}, {
				"itemId": 3243,
				"itemLabel": "L2W_244",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.26646712825679,
				"choices": ["a", "d", "j", "a", "c", "e", "n", "t"],
				"correctAnswer": "adjacent",
				"audioFileNames": {
					"prompts": ["L2W_Adjacent"]
				},
				"discrimination": 1.53877371541408
			}, {
				"itemId": 3244,
				"itemLabel": "L2W_245",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.166960861290213,
				"choices": ["h", "o", "m", "e", "w", "o", "r", "k"],
				"correctAnswer": "homework",
				"audioFileNames": {
					"prompts": ["L2W_Homework"]
				},
				"discrimination": 1.58969382765112
			}, {
				"itemId": 3245,
				"itemLabel": "L2W_246",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.51899869019452,
				"choices": ["c", "o", "n", "s", "t", "a", "n", "t"],
				"correctAnswer": "constant",
				"audioFileNames": {
					"prompts": ["L2W_Constant"]
				},
				"discrimination": 1.75510010523475
			}, {
				"itemId": 3246,
				"itemLabel": "L2W_247",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.51133508787817,
				"choices": ["a", "c", "a", "d", "e", "m", "i", "c"],
				"correctAnswer": "academic",
				"audioFileNames": {
					"prompts": ["L2W_Academic"]
				},
				"discrimination": 1.45026693529508
			}, {
				"itemId": 3247,
				"itemLabel": "L2W_248",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.75562910815833,
				"choices": ["p", "l", "a", "t", "o", "n", "i", "c"],
				"correctAnswer": "platonic",
				"audioFileNames": {
					"prompts": ["L2W_Platonic"]
				},
				"discrimination": 1.38047223074809
			}, {
				"itemId": 3248,
				"itemLabel": "L2W_249",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.30675766233223,
				"choices": ["r", "e", "g", "a", "r", "d", "e", "d"],
				"correctAnswer": "regarded",
				"audioFileNames": {
					"prompts": ["L2W_Regarded"]
				},
				"discrimination": 1.76026443245593
			}, {
				"itemId": 3249,
				"itemLabel": "L2W_250",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.693714374362653,
				"choices": ["p", "o", "w", "e", "r", "f", "u", "l"],
				"correctAnswer": "powerful",
				"audioFileNames": {
					"prompts": ["L2W_Powerful"]
				},
				"discrimination": 1.7474607974908
			}, {
				"itemId": 3250,
				"itemLabel": "L2W_251",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.48682412899986,
				"choices": ["b", "e", "c", "k", "o", "n", "e", "d"],
				"correctAnswer": "beckoned",
				"audioFileNames": {
					"prompts": ["L2W_Beckoned"]
				},
				"discrimination": 1.99615088747833
			}, {
				"itemId": 3251,
				"itemLabel": "L2W_252",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.0438035777268,
				"choices": ["h", "a", "n", "d", "l", "i", "n", "g"],
				"correctAnswer": "handling",
				"audioFileNames": {
					"prompts": ["L2W_Handling"]
				},
				"discrimination": 1.85322793198353
			}, {
				"itemId": 3252,
				"itemLabel": "L2W_253",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.11473613476509,
				"choices": ["c", "h", "i", "l", "d", "r", "e", "n", "s", "u"],
				"correctAnswer": "children",
				"audioFileNames": {
					"prompts": ["L2Wex_Children"]
				},
				"discrimination": 1.82114225726288
			}, {
				"itemId": 3253,
				"itemLabel": "L2W_254",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.03046901539616,
				"choices": ["t", "o", "g", "e", "t", "h", "e", "r", "w", "j"],
				"correctAnswer": "together",
				"audioFileNames": {
					"prompts": ["L2Wex_Together"]
				},
				"discrimination": 1.82843078577706
			}, {
				"itemId": 3254,
				"itemLabel": "L2W_255",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.00457024648917,
				"choices": ["q", "u", "e", "s", "t", "i", "o", "n", "w", "c"],
				"correctAnswer": "question",
				"audioFileNames": {
					"prompts": ["L2Wex_Question"]
				},
				"discrimination": 1.28975192604372
			}, {
				"itemId": 3255,
				"itemLabel": "L2W_256",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.55208596253787,
				"choices": ["s", "e", "n", "t", "e", "n", "c", "e", "m", "t"],
				"correctAnswer": "sentence",
				"audioFileNames": {
					"prompts": ["L2Wex_Sentence"]
				},
				"discrimination": 2.31485028773441
			}, {
				"itemId": 3256,
				"itemLabel": "L2W_257",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.92673614806776,
				"choices": ["f", "a", "v", "o", "r", "i", "t", "e", "u", "b"],
				"correctAnswer": "favorite",
				"audioFileNames": {
					"prompts": ["L2Wex_Favorite"]
				},
				"discrimination": 1.62404420625377
			}, {
				"itemId": 3257,
				"itemLabel": "L2W_258",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.550789355337084,
				"choices": ["s", "o", "m", "e", "o", "n", "e", "c", "u"],
				"correctAnswer": "someone",
				"audioFileNames": {
					"prompts": ["L2Wex_Someone"]
				},
				"discrimination": 1.42307857860192
			}, {
				"itemId": 3258,
				"itemLabel": "L2W_259",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.645698718393762,
				"choices": ["f", "o", "r", "g", "e", "t", "f", "u", "l", "o", "d"],
				"correctAnswer": "forgetful",
				"audioFileNames": {
					"prompts": ["L2Wex_Forgetful"]
				},
				"discrimination": 1.67010862721431
			}, {
				"itemId": 3259,
				"itemLabel": "L2W_260",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.941637687060847,
				"choices": ["r", "o", "u", "n", "d", "i", "n", "g", "u", "h"],
				"correctAnswer": "rounding",
				"audioFileNames": {
					"prompts": ["L2Wex_Rounding"]
				},
				"discrimination": 1.88917428286298
			}, {
				"itemId": 3260,
				"itemLabel": "L2W_261",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.73712340598446,
				"choices": ["b", "o", "a", "s", "t", "i", "n", "g", "h", "a"],
				"correctAnswer": "boasting",
				"audioFileNames": {
					"prompts": ["L2Wex_Boasting"]
				},
				"discrimination": 0.781166332300516
			}, {
				"itemId": 3261,
				"itemLabel": "L2W_262",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.20042735874392,
				"choices": ["e", "v", "e", "r", "y", "o", "n", "e", "w", "m", "a"],
				"correctAnswer": "everyone",
				"audioFileNames": {
					"prompts": ["L2Wex_Everyone"]
				},
				"discrimination": 1.82625419459658
			}, {
				"itemId": 3262,
				"itemLabel": "L2W_263",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.50341254510824,
				"choices": ["a", "t", "t", "i", "t", "u", "d", "e", "y", "b", "p"],
				"correctAnswer": "attitude",
				"audioFileNames": {
					"prompts": ["L2Wex_Attitude"]
				},
				"discrimination": 1.72067062071762
			}, {
				"itemId": 3263,
				"itemLabel": "L2W_264",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.8909101070244,
				"choices": ["s", "c", "h", "e", "d", "u", "l", "e", "k", "a", "j"],
				"correctAnswer": "schedule",
				"audioFileNames": {
					"prompts": ["L2Wex_Schedule"]
				},
				"discrimination": 1.46774621521073
			}, {
				"itemId": 3264,
				"itemLabel": "L2W_265",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.42230675837779,
				"choices": ["s", "o", "l", "u", "t", "i", "o", "n", "c", "h", "m"],
				"correctAnswer": "solution",
				"audioFileNames": {
					"prompts": ["L2Wex_Solution"]
				},
				"discrimination": 1.18038251965105
			}, {
				"itemId": 3265,
				"itemLabel": "L2W_266",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.34404488594777,
				"choices": ["a", "m", "b", "i", "t", "i", "o", "n", "d", "s", "h"],
				"correctAnswer": "ambition",
				"audioFileNames": {
					"prompts": ["L2Wex_Ambition"]
				},
				"discrimination": 1.12217115726612
			}, {
				"itemId": 3266,
				"itemLabel": "L2W_267",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.2354609245324,
				"choices": ["s", "u", "i", "t", "a", "b", "l", "e", "c", "w", "d"],
				"correctAnswer": "suitable",
				"audioFileNames": {
					"prompts": ["L2Wex_Suitable"]
				},
				"discrimination": 1.25932654361507
			}, {
				"itemId": 3267,
				"itemLabel": "L2W_268",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.84804437410539,
				"choices": ["r", "o", "a", "s", "t", "i", "n", "g", "f", "v", "u"],
				"correctAnswer": "roasting",
				"audioFileNames": {
					"prompts": ["L2Wex_Roasting"]
				},
				"discrimination": 1.54955634330197
			}, {
				"itemId": 3268,
				"itemLabel": "L2W_269",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.51873450105841,
				"choices": ["t", "o", "a", "s", "t", "i", "n", "g", "u", "d", "v"],
				"correctAnswer": "toasting",
				"audioFileNames": {
					"prompts": ["L2Wex_Toasting"]
				},
				"discrimination": 1.68245794943305
			}, {
				"itemId": 3269,
				"itemLabel": "L2W_270",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.69034929900271,
				"choices": ["s", "h", "a", "d", "o", "w", "e", "d", "y", "g", "n"],
				"correctAnswer": "shadowed",
				"audioFileNames": {
					"prompts": ["L2Wex_Shadowed"]
				},
				"discrimination": 1.91596982500379
			}, {
				"itemId": 3270,
				"itemLabel": "L2W_271",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.711331003806173,
				"choices": ["r", "e", "l", "a", "t", "i", "n", "g", "h", "w", "o"],
				"correctAnswer": "relating",
				"audioFileNames": {
					"prompts": ["L2Wex_Relating"]
				},
				"discrimination": 1.61199530889198
			}, {
				"itemId": 3271,
				"itemLabel": "L2W_272",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.37231798814389,
				"choices": ["b", "r", "e", "a", "k", "i", "n", "g", "j", "u", "p"],
				"correctAnswer": "breaking",
				"audioFileNames": {
					"prompts": ["L2Wex_Breaking"]
				},
				"discrimination": 0.681912781702685
			}, {
				"itemId": 3272,
				"itemLabel": "L2W_273",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.5185776454682,
				"choices": ["a", "u", "t", "h", "o", "r", "i", "t", "y"],
				"correctAnswer": "authority",
				"audioFileNames": {
					"prompts": ["L2W_Authority"]
				},
				"discrimination": 1.16623144056584
			}, {
				"itemId": 3273,
				"itemLabel": "L2W_274",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.64132900183706,
				"choices": ["p", "r", "e", "d", "a", "t", "o", "r", "y"],
				"correctAnswer": "predatory",
				"audioFileNames": {
					"prompts": ["L2W_Predatory"]
				},
				"discrimination": 1.73946661674952
			}, {
				"itemId": 3274,
				"itemLabel": "L2W_275",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.15856213450694,
				"choices": ["d", "e", "t", "e", "r", "m", "i", "n", "e"],
				"correctAnswer": "determine",
				"audioFileNames": {
					"prompts": ["L2W_Determine"]
				},
				"discrimination": 1.22074267938714
			}, {
				"itemId": 3275,
				"itemLabel": "L2W_276",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 4.65785193970747,
				"choices": ["f", "o", "r", "e", "s", "i", "g", "h", "t"],
				"correctAnswer": "foresight",
				"audioFileNames": {
					"prompts": ["L2W_Foresight"]
				},
				"discrimination": 0.899928133787728
			}, {
				"itemId": 3276,
				"itemLabel": "L2W_277",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.06149568690483,
				"choices": ["w", "a", "n", "d", "e", "r", "i", "n", "g"],
				"correctAnswer": "wandering",
				"audioFileNames": {
					"prompts": ["L2W_Wandering"]
				},
				"discrimination": 2.04473228230565
			}, {
				"itemId": 3277,
				"itemLabel": "L2W_278",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.37967878307767,
				"choices": ["p", "e", "r", "f", "o", "r", "a", "t", "e"],
				"correctAnswer": "perforate",
				"audioFileNames": {
					"prompts": ["L2W_Perforate"]
				},
				"discrimination": 0.715503433723976
			}, {
				"itemId": 3278,
				"itemLabel": "L2W_279",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.05832592328616,
				"choices": ["m", "e", "d", "i", "t", "a", "t", "e", "d"],
				"correctAnswer": "meditated",
				"audioFileNames": {
					"prompts": ["L2W_Meditated"]
				},
				"discrimination": 1.49037139371599
			}, {
				"itemId": 3279,
				"itemLabel": "L2W_280",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.37991846976078,
				"choices": ["g", "r", "e", "a", "t", "n", "e", "s", "s"],
				"correctAnswer": "greatness",
				"audioFileNames": {
					"prompts": ["L2W_Greatness"]
				},
				"discrimination": 1.07364524012978
			}, {
				"itemId": 3280,
				"itemLabel": "L2W_281",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.75886933711714,
				"choices": ["h", "a", "p", "p", "e", "n", "i", "n", "g"],
				"correctAnswer": "happening",
				"audioFileNames": {
					"prompts": ["L2W_Happening"]
				},
				"discrimination": 1.1536962923701
			}, {
				"itemId": 3281,
				"itemLabel": "L2W_282",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.90687889263703,
				"choices": ["b", "e", "a", "u", "t", "i", "f", "u", "l", "p", "h"],
				"correctAnswer": "beautiful",
				"audioFileNames": {
					"prompts": ["L2Wex_Beautiful"]
				},
				"discrimination": 0.995372004905479
			}, {
				"itemId": 3282,
				"itemLabel": "L2W_283",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.48668737034384,
				"choices": ["i", "m", "p", "o", "r", "t", "a", "n", "t", "b", "e"],
				"correctAnswer": "important",
				"audioFileNames": {
					"prompts": ["L2Wex_Important"]
				},
				"discrimination": 1.44659887938313
			}, {
				"itemId": 3283,
				"itemLabel": "L2W_284",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.57140441305089,
				"choices": ["c", "o", "n", "t", "i", "n", "e", "n", "t", "k", "u"],
				"correctAnswer": "continent",
				"audioFileNames": {
					"prompts": ["L2Wex_Continent"]
				},
				"discrimination": 1.6274272953223
			}, {
				"itemId": 3284,
				"itemLabel": "L2W_285",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.42453210693766,
				"choices": ["p", "r", "e", "f", "e", "r", "r", "e", "d", "u", "g"],
				"correctAnswer": "preferred",
				"audioFileNames": {
					"prompts": ["L2Wex_Preferred"]
				},
				"discrimination": 1.56053122067886
			}, {
				"itemId": 3285,
				"itemLabel": "L2W_286",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 4.89867159802749,
				"choices": ["p", "a", "r", "a", "l", "l", "e", "l", "s", "h", "o"],
				"correctAnswer": "parallels",
				"audioFileNames": {
					"prompts": ["L2Wex_Parallels"]
				},
				"discrimination": 0.893761217313417
			}, {
				"itemId": 3286,
				"itemLabel": "L2W_287",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.21730221435187,
				"choices": ["g", "r", "a", "p", "h", "i", "n", "g", "b", "i"],
				"correctAnswer": "graphing",
				"audioFileNames": {
					"prompts": ["L2Wex_Graphing"]
				},
				"discrimination": 1.46109217886638
			}, {
				"itemId": 3287,
				"itemLabel": "L2W_288",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.99041822311399,
				"choices": ["h", "o", "l", "o", "g", "r", "a", "p", "h", "d", "w"],
				"correctAnswer": "holograph",
				"audioFileNames": {
					"prompts": ["L2Wex_Holograph"]
				},
				"discrimination": 1.56983020647033
			}, {
				"itemId": 3288,
				"itemLabel": "L2W_289",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 4.28443433107089,
				"choices": ["q", "u", "i", "z", "z", "i", "c", "a", "l", "h", "e"],
				"correctAnswer": "quizzical",
				"audioFileNames": {
					"prompts": ["L2Wex_Quizzical"]
				},
				"discrimination": 0.95466753912128
			}, {
				"itemId": 3289,
				"itemLabel": "L2W_290",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.90382939200559,
				"choices": ["a", "c", "c", "l", "i", "m", "a", "t", "e", "d", "c"],
				"correctAnswer": "acclimate",
				"audioFileNames": {
					"prompts": ["L2Wex_Acclimate"]
				},
				"discrimination": 2.29357425185678
			}, {
				"itemId": 3290,
				"itemLabel": "L2W_291",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.75646925579385,
				"choices": ["a", "f", "f", "i", "r", "m", "i", "n", "g", "f", "o"],
				"correctAnswer": "affirming",
				"audioFileNames": {
					"prompts": ["L2Wex_Affirming"]
				},
				"discrimination": 2.22388088144967
			}, {
				"itemId": 3291,
				"itemLabel": "L2W_292",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.23459078648525,
				"choices": ["f", "o", "r", "t", "u", "n", "a", "t", "e", "p", "h", "i"],
				"correctAnswer": "fortunate",
				"audioFileNames": {
					"prompts": ["L2Wex_Fortunate"]
				},
				"discrimination": 1.05467390153815
			}, {
				"itemId": 3292,
				"itemLabel": "L2W_293",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.39176989469016,
				"choices": ["b", "r", "i", "l", "l", "i", "a", "n", "t", "d", "y", "u"],
				"correctAnswer": "brilliant",
				"audioFileNames": {
					"prompts": ["L2Wex_Brilliant"]
				},
				"discrimination": 1.10658155774798
			}, {
				"itemId": 3293,
				"itemLabel": "L2W_294",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.25326930913838,
				"choices": ["s", "c", "i", "e", "n", "t", "i", "f", "i", "c", "d", "s", "o"],
				"correctAnswer": "scientific",
				"audioFileNames": {
					"prompts": ["L2Wex_Scientific"]
				},
				"discrimination": 1.57858553708117
			}, {
				"itemId": 3294,
				"itemLabel": "L2W_295",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.79012395359202,
				"choices": ["g", "e", "n", "e", "r", "a", "t", "e", "d", "j", "n", "e"],
				"correctAnswer": "generated",
				"audioFileNames": {
					"prompts": ["L2Wex_Generated"]
				},
				"discrimination": 1.29400296614211
			}, {
				"itemId": 3295,
				"itemLabel": "L2W_296",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.55682957064923,
				"choices": ["c", "a", "s", "t", "a", "w", "a", "y", "s", "f", "r", "o"],
				"correctAnswer": "castaways",
				"audioFileNames": {
					"prompts": ["L2Wex_Castaways"]
				},
				"discrimination": 1.5193309084844
			}, {
				"itemId": 3296,
				"itemLabel": "L2W_297",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.36751973347447,
				"choices": ["d", "e", "s", "t", "r", "o", "y", "e", "r", "f", "v", "p"],
				"correctAnswer": "destroyer",
				"audioFileNames": {
					"prompts": ["L2Wex_Destroyer"]
				},
				"discrimination": 1.24063215064204
			}, {
				"itemId": 3297,
				"itemLabel": "L2W_298",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.97203889304261,
				"choices": ["d", "i", "f", "f", "i", "c", "u", "l", "t", "h", "s", "q"],
				"correctAnswer": "difficult",
				"audioFileNames": {
					"prompts": ["L2Wex_Difficult"]
				},
				"discrimination": 0.969301969718309
			}, {
				"itemId": 3298,
				"itemLabel": "L2W_299",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.31104747870958,
				"choices": ["d", "i", "s", "m", "i", "s", "s", "e", "d", "o", "u", "d"],
				"correctAnswer": "dismissed",
				"audioFileNames": {
					"prompts": ["L2Wex_Dismissed"]
				},
				"discrimination": 1.21385330926569
			}, {
				"itemId": 3299,
				"itemLabel": "L2W_300",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.89577803077384,
				"choices": ["e", "g", "y", "p", "t", "i", "a", "n", "s", "h", "b", "w"],
				"correctAnswer": "egyptians",
				"audioFileNames": {
					"prompts": ["L2Wex_Egyptians"]
				},
				"discrimination": 1.651770715337
			}, {
				"itemId": 3300,
				"itemLabel": "L2W_301",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.831356840494929,
				"choices": ["g", "r", "a", "v", "e", "y", "a", "r", "d", "o", "h", "p"],
				"correctAnswer": "graveyard",
				"audioFileNames": {
					"prompts": ["L2Wex_Graveyard"]
				},
				"discrimination": 1.83278661436075
			}, {
				"itemId": 3301,
				"itemLabel": "L2W_302",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 4.30227315289863,
				"choices": ["g", "u", "a", "r", "a", "n", "t", "e", "e", "g", "s", "p"],
				"correctAnswer": "guarantee",
				"audioFileNames": {
					"prompts": ["L2Wex_Guarantee"]
				},
				"discrimination": 0.924691038736816
			}, {
				"itemId": 3302,
				"itemLabel": "L2W_303",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.03486058627411,
				"choices": ["m", "a", "n", "i", "p", "u", "l", "a", "t", "e"],
				"correctAnswer": "manipulate",
				"audioFileNames": {
					"prompts": ["L2W_Manipulate"]
				},
				"discrimination": 1.48792322604586
			}, {
				"itemId": 3303,
				"itemLabel": "L2W_304",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.88965122776445,
				"choices": ["g", "e", "n", "e", "r", "o", "s", "i", "t", "y"],
				"correctAnswer": "generosity",
				"audioFileNames": {
					"prompts": ["L2W_Generosity"]
				},
				"discrimination": 1.12185201027378
			}, {
				"itemId": 3304,
				"itemLabel": "L2W_305",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.97698586169547,
				"choices": ["e", "v", "a", "l", "u", "a", "t", "i", "o", "n"],
				"correctAnswer": "evaluation",
				"audioFileNames": {
					"prompts": ["L2W_Evaluation"]
				},
				"discrimination": 1.01604270583876
			}, {
				"itemId": 3305,
				"itemLabel": "L2W_306",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.98956756701373,
				"choices": ["g", "a", "r", "g", "a", "n", "t", "u", "a", "n"],
				"correctAnswer": "gargantuan",
				"audioFileNames": {
					"prompts": ["L2W_Gargantuan"]
				},
				"discrimination": 1.21327863578638
			}, {
				"itemId": 3306,
				"itemLabel": "L2W_307",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.949116909620493,
				"choices": ["b", "l", "u", "s", "t", "e", "r", "i", "n", "g"],
				"correctAnswer": "blustering",
				"audioFileNames": {
					"prompts": ["L2W_Blustering"]
				},
				"discrimination": 1.41609270491029
			}, {
				"itemId": 3307,
				"itemLabel": "L2W_308",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.13422023557541,
				"choices": ["a", "m", "e", "l", "i", "o", "r", "a", "t", "e"],
				"correctAnswer": "ameliorate",
				"audioFileNames": {
					"prompts": ["L2W_Ameliorate"]
				},
				"discrimination": 1.59929067040582
			}, {
				"itemId": 3308,
				"itemLabel": "L2W_309",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.54518840082768,
				"choices": ["f", "o", "r", "t", "u", "i", "t", "o", "u", "s"],
				"correctAnswer": "fortuitous",
				"audioFileNames": {
					"prompts": ["L2W_Fortuitous"]
				},
				"discrimination": 1.69204442487328
			}, {
				"itemId": 3309,
				"itemLabel": "L2W_310",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.1085360397522,
				"choices": ["a", "b", "o", "r", "i", "g", "i", "n", "a", "l"],
				"correctAnswer": "aboriginal",
				"audioFileNames": {
					"prompts": ["L2W_Aboriginal"]
				},
				"discrimination": 1.23636302674925
			}, {
				"itemId": 3310,
				"itemLabel": "L2W_311",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.40365233738459,
				"choices": ["a", "b", "s", "o", "r", "b", "a", "n", "c", "e"],
				"correctAnswer": "absorbance",
				"audioFileNames": {
					"prompts": ["L2W_Absorbance"]
				},
				"discrimination": 1.41561152706147
			}, {
				"itemId": 3311,
				"itemLabel": "L2W_312",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.91468400301855,
				"choices": ["c", "a", "p", "t", "i", "v", "a", "t", "e", "s"],
				"correctAnswer": "captivates",
				"audioFileNames": {
					"prompts": ["L2W_Captivates"]
				},
				"discrimination": 1.75736589419505
			}, {
				"itemId": 3312,
				"itemLabel": "L2W_313",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.89967255799882,
				"choices": ["c", "a", "t", "a", "l", "y", "z", "i", "n", "g"],
				"correctAnswer": "catalyzing",
				"audioFileNames": {
					"prompts": ["L2W_Catalyzing"]
				},
				"discrimination": 1.16060518814579
			}, {
				"itemId": 3313,
				"itemLabel": "L2W_314",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.463415871727462,
				"choices": ["f", "i", "r", "e", "p", "l", "a", "c", "e", "s"],
				"correctAnswer": "fireplaces",
				"audioFileNames": {
					"prompts": ["L2W_Fireplaces"]
				},
				"discrimination": 1.31351363125935
			}, {
				"itemId": 3314,
				"itemLabel": "L2W_315",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.73150169988901,
				"choices": ["i", "n", "d", "i", "v", "i", "d", "u", "a", "l", "y", "b"],
				"correctAnswer": "individual",
				"audioFileNames": {
					"prompts": ["L2Wex_Individual"]
				},
				"discrimination": 1.01638521827414
			}, {
				"itemId": 3315,
				"itemLabel": "L2W_316",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.0177721520316311,
				"choices": ["a", "s", "t", "r", "o", "n", "o", "m", "i", "c", "g", "v"],
				"correctAnswer": "astronomic",
				"audioFileNames": {
					"prompts": ["L2Wex_Astronomic"]
				},
				"discrimination": 0.6993325239311
			}, {
				"itemId": 3316,
				"itemLabel": "L2W_317",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.03207919257334,
				"choices": ["f", "r", "a", "c", "t", "i", "o", "n", "e", "d", "h", "o"],
				"correctAnswer": "fractioned",
				"audioFileNames": {
					"prompts": ["L2Wex_Fractioned"]
				},
				"discrimination": 0.775191816186931
			}, {
				"itemId": 3317,
				"itemLabel": "L2W_318",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.0053282432989,
				"choices": ["g", "e", "n", "e", "r", "o", "u", "s", "l", "y", "j", "t"],
				"correctAnswer": "generously",
				"audioFileNames": {
					"prompts": ["L2Wex_Generously"]
				},
				"discrimination": 1.06170744895279
			}, {
				"itemId": 3318,
				"itemLabel": "L2W_319",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.310912009911579,
				"choices": ["g", "o", "d", "f", "a", "t", "h", "e", "r", "s", "p", "e"],
				"correctAnswer": "godfathers",
				"audioFileNames": {
					"prompts": ["L2Wex_Godfathers"]
				},
				"discrimination": 1.17592874559164
			}, {
				"itemId": 3319,
				"itemLabel": "L2W_320",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.80824984131725,
				"choices": ["i", "m", "p", "e", "r", "s", "o", "n", "a", "l", "h", "a"],
				"correctAnswer": "impersonal",
				"audioFileNames": {
					"prompts": ["L2Wex_Impersonal"]
				},
				"discrimination": 1.5449819197638
			}, {
				"itemId": 3320,
				"itemLabel": "L2W_321",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.81526414643039,
				"choices": ["i", "n", "c", "i", "d", "e", "n", "c", "e", "s", "c", "u"],
				"correctAnswer": "incidences",
				"audioFileNames": {
					"prompts": ["L2Wex_Incidences"]
				},
				"discrimination": 1.07311184734914
			}, {
				"itemId": 3321,
				"itemLabel": "L2W_322",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.74627996533509,
				"choices": ["l", "e", "g", "i", "s", "l", "a", "t", "e", "d", "u", "d"],
				"correctAnswer": "legislated",
				"audioFileNames": {
					"prompts": ["L2Wex_Legislated"]
				},
				"discrimination": 0.308551876406167
			}, {
				"itemId": 3322,
				"itemLabel": "L2W_323",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.46951589398948,
				"choices": ["l", "i", "m", "i", "t", "a", "t", "i", "o", "n", "b", "d"],
				"correctAnswer": "limitation",
				"audioFileNames": {
					"prompts": ["L2Wex_Limitation"]
				},
				"discrimination": 0.836177367838385
			}, {
				"itemId": 3323,
				"itemLabel": "L2W_324",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.43356527472763,
				"choices": ["l", "i", "v", "e", "l", "i", "h", "o", "o", "d", "b", "e"],
				"correctAnswer": "livelihood",
				"audioFileNames": {
					"prompts": ["L2Wex_Livelihood"]
				},
				"discrimination": 1.47138036027661
			}, {
				"itemId": 3324,
				"itemLabel": "L2W_325",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.90858722375315,
				"choices": ["b", "e", "n", "e", "v", "o", "l", "e", "n", "t", "d", "i", "w"],
				"correctAnswer": "benevolent",
				"audioFileNames": {
					"prompts": ["L2Wex_Benevolent"]
				},
				"discrimination": 1.79077043298635
			}, {
				"itemId": 3325,
				"itemLabel": "L2W_326",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.28855828785839,
				"choices": ["m", "a", "s", "s", "a", "c", "r", "e", "s", "g", "u", "h"],
				"correctAnswer": "massacres",
				"audioFileNames": {
					"prompts": ["L2Wex_Massacres"]
				},
				"discrimination": 2.41945955407724
			}, {
				"itemId": 3326,
				"itemLabel": "L2W_327",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.00145236520069,
				"choices": ["m", "a", "t", "h", "e", "m", "a", "t", "i", "c", "j", "p", "u"],
				"correctAnswer": "mathematic",
				"audioFileNames": {
					"prompts": ["L2Wex_Mathematic"]
				},
				"discrimination": 1.9867193839228
			}, {
				"itemId": 3327,
				"itemLabel": "L2W_328",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.97064191794753,
				"choices": ["m", "e", "d", "a", "l", "l", "i", "o", "n", "s", "w", "i", "o"],
				"correctAnswer": "medallions",
				"audioFileNames": {
					"prompts": ["L2Wex_Medallions"]
				},
				"discrimination": 1.57315344404043
			}, {
				"itemId": 3328,
				"itemLabel": "L2W_329",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.19540978378519,
				"choices": ["g", "r", "a", "c", "e", "f", "u", "l", "l", "y", "j", "w", "e"],
				"correctAnswer": "gracefully",
				"audioFileNames": {
					"prompts": ["L2Wex_Gracefully"]
				},
				"discrimination": 0.220261773882668
			}, {
				"itemId": 3329,
				"itemLabel": "L2W_330",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.80928564258064,
				"choices": ["g", "r", "a", "t", "i", "t", "u", "d", "e", "s", "h", "b", "o"],
				"correctAnswer": "gratitudes",
				"audioFileNames": {
					"prompts": ["L2Wex_Gratitudes"]
				},
				"discrimination": 1.39978213139366
			}, {
				"itemId": 3330,
				"itemLabel": "L2W_331",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 4.11551581595835,
				"choices": ["g", "l", "a", "m", "o", "r", "o", "u", "s", "y", "b", "d"],
				"correctAnswer": "glamorous",
				"audioFileNames": {
					"prompts": ["L2Wex_Glamorous"]
				},
				"discrimination": 0.647695189641191
			}, {
				"itemId": 3331,
				"itemLabel": "L2W_332",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.77777168700796,
				"choices": ["e", "s", "c", "a", "l", "a", "t", "o", "r", "s", "p", "e", "q"],
				"correctAnswer": "escalators",
				"audioFileNames": {
					"prompts": ["L2Wex_Escalators"]
				},
				"discrimination": 1.42408796835307
			}, {
				"itemId": 3332,
				"itemLabel": "L2W_333",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.09956366561127,
				"choices": ["d", "u", "m", "b", "s", "t", "r", "u", "c", "k", "r", "n", "s"],
				"correctAnswer": "dumbstruck",
				"audioFileNames": {
					"prompts": ["L2Wex_Dumbstruck"]
				},
				"discrimination": 1.59838387532949
			}, {
				"itemId": 3333,
				"itemLabel": "L2W_334",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.0778890109261666,
				"choices": ["d", "r", "a", "w", "s", "t", "r", "i", "n", "g", "j", "g", "b"],
				"correctAnswer": "drawstring",
				"audioFileNames": {
					"prompts": ["L2Wex_Drawstring"]
				},
				"discrimination": 0.633029075845457
			}, {
				"itemId": 3334,
				"itemLabel": "L2W_335",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.02973391918641,
				"choices": ["e", "m", "i", "g", "r", "a", "t", "i", "o", "n", "i", "n", "d"],
				"correctAnswer": "emigration",
				"audioFileNames": {
					"prompts": ["L2Wex_Emigration"]
				},
				"discrimination": 0.81794936976794
			}, {
				"itemId": 3335,
				"itemLabel": "L2W_336",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": -0.113140098951915,
				"choices": ["a", "d", "v", "e", "r", "t", "i", "s", "i", "n", "g"],
				"correctAnswer": "advertising",
				"audioFileNames": {
					"prompts": ["L2W_Advertising"]
				},
				"discrimination": 0.819142683820714
			}, {
				"itemId": 3336,
				"itemLabel": "L2W_337",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.25419263193488,
				"choices": ["a", "f", "f", "i", "l", "i", "a", "t", "i", "o", "n"],
				"correctAnswer": "affiliation",
				"audioFileNames": {
					"prompts": ["L2W_Affiliation"]
				},
				"discrimination": 1.04882319706267
			}, {
				"itemId": 3337,
				"itemLabel": "L2W_338",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.91098652459145,
				"choices": ["a", "f", "f", "e", "c", "t", "i", "v", "e", "l", "y"],
				"correctAnswer": "affectively",
				"audioFileNames": {
					"prompts": ["L2W_Affectively"]
				},
				"discrimination": 1.00625559535933
			}, {
				"itemId": 3338,
				"itemLabel": "L2W_339",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.24107697753072,
				"choices": ["b", "l", "a", "c", "k", "b", "o", "a", "r", "d", "s"],
				"correctAnswer": "blackboards",
				"audioFileNames": {
					"prompts": ["L2W_Blackboards"]
				},
				"discrimination": 1.12028570380272
			}, {
				"itemId": 3339,
				"itemLabel": "L2W_340",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.81981682398178,
				"choices": ["c", "a", "p", "a", "c", "i", "t", "a", "t", "e", "d"],
				"correctAnswer": "capacitated",
				"audioFileNames": {
					"prompts": ["L2W_Capacitated"]
				},
				"discrimination": 0.827035671357551
			}, {
				"itemId": 3340,
				"itemLabel": "L2W_341",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.04977159286465,
				"choices": ["c", "a", "r", "i", "c", "a", "t", "u", "r", "e", "s"],
				"correctAnswer": "caricatures",
				"audioFileNames": {
					"prompts": ["L2W_Caricatures"]
				},
				"discrimination": 1.3499451500671
			}, {
				"itemId": 3341,
				"itemLabel": "L2W_342",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.979607746918463,
				"choices": ["d", "e", "s", "p", "e", "r", "a", "t", "i", "o", "n"],
				"correctAnswer": "desperation",
				"audioFileNames": {
					"prompts": ["L2W_Desperation"]
				},
				"discrimination": 1.16807214399261
			}, {
				"itemId": 3342,
				"itemLabel": "L2W_343",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.13467286495792,
				"choices": ["d", "i", "s", "c", "e", "r", "n", "a", "b", "l", "e"],
				"correctAnswer": "discernable",
				"audioFileNames": {
					"prompts": ["L2W_Discernable"]
				},
				"discrimination": 2.24570577537192
			}, {
				"itemId": 3343,
				"itemLabel": "L2W_344",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.79304259221132,
				"choices": ["d", "i", "s", "c", "o", "v", "e", "r", "i", "e", "s"],
				"correctAnswer": "discoveries",
				"audioFileNames": {
					"prompts": ["L2W_Discoveries"]
				},
				"discrimination": 2.25485910086657
			}, {
				"itemId": 3344,
				"itemLabel": "L2W_345",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.29219704128588,
				"choices": ["r", "e", "s", "p", "o", "n", "s", "i", "b", "l", "e", "d", "y"],
				"correctAnswer": "responsible",
				"audioFileNames": {
					"prompts": ["L2Wex_Responsible"]
				},
				"discrimination": 0.925551693047288
			}, {
				"itemId": 3345,
				"itemLabel": "L2W_346",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.705138333996,
				"choices": ["m", "a", "g", "n", "i", "f", "i", "c", "e", "n", "t", "p", "h"],
				"correctAnswer": "magnificent",
				"audioFileNames": {
					"prompts": ["L2Wex_Magnificent"]
				},
				"discrimination": 1.29138120035496
			}, {
				"itemId": 3346,
				"itemLabel": "L2W_347",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.75937712430923,
				"choices": ["d", "e", "m", "o", "n", "s", "t", "r", "a", "t", "e", "b", "c"],
				"correctAnswer": "demonstrate",
				"audioFileNames": {
					"prompts": ["L2Wex_Demonstrate"]
				},
				"discrimination": 1.12290237781936
			}, {
				"itemId": 3347,
				"itemLabel": "L2W_348",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.75821216000113,
				"choices": ["a", "c", "h", "i", "e", "v", "e", "m", "e", "n", "t", "s", "u"],
				"correctAnswer": "achievement",
				"audioFileNames": {
					"prompts": ["L2Wex_Achievement"]
				},
				"discrimination": 1.39267633627502
			}, {
				"itemId": 3348,
				"itemLabel": "L2W_349",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.74800888423172,
				"choices": ["e", "n", "c", "r", "o", "a", "c", "h", "i", "n", "g", "r", "g"],
				"correctAnswer": "encroaching",
				"audioFileNames": {
					"prompts": ["L2Wex_Encroaching"]
				},
				"discrimination": 1.35878708000522
			}, {
				"itemId": 3349,
				"itemLabel": "L2W_350",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.80513479573935,
				"choices": ["d", "y", "n", "a", "m", "i", "c", "a", "l", "l", "y", "u", "i"],
				"correctAnswer": "dynamically",
				"audioFileNames": {
					"prompts": ["L2Wex_Dynamically"]
				},
				"discrimination": 1.02135660128388
			}, {
				"itemId": 3350,
				"itemLabel": "L2W_351",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.91886647867894,
				"choices": ["e", "l", "i", "c", "i", "t", "a", "t", "i", "o", "n", "y", "r"],
				"correctAnswer": "elicitation",
				"audioFileNames": {
					"prompts": ["L2Wex_Elicitation"]
				},
				"discrimination": 1.25714881047293
			}, {
				"itemId": 3351,
				"itemLabel": "L2W_352",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.88799054158102,
				"choices": ["e", "m", "a", "n", "c", "i", "p", "a", "t", "e", "s", "h", "b"],
				"correctAnswer": "emancipates",
				"audioFileNames": {
					"prompts": ["L2Wex_Emancipates"]
				},
				"discrimination": 2.16581900960277
			}, {
				"itemId": 3352,
				"itemLabel": "L2W_353",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.33429132980779,
				"choices": ["f", "o", "r", "e", "s", "e", "e", "a", "b", "l", "e", "b", "u"],
				"correctAnswer": "foreseeable",
				"audioFileNames": {
					"prompts": ["L2Wex_Foreseeable"]
				},
				"discrimination": 1.84481025979133
			}, {
				"itemId": 3353,
				"itemLabel": "L2W_354",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.32440271288769,
				"choices": ["f", "r", "a", "n", "t", "i", "c", "a", "l", "l", "y", "e", "g"],
				"correctAnswer": "frantically",
				"audioFileNames": {
					"prompts": ["L2Wex_Frantically"]
				},
				"discrimination": 1.83104129194592
			}, {
				"itemId": 3354,
				"itemLabel": "L2W_355",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 0.536196669022685,
				"choices": ["g", "i", "n", "g", "e", "r", "b", "r", "e", "a", "d", "u", "e"],
				"correctAnswer": "gingerbread",
				"audioFileNames": {
					"prompts": ["L2Wex_Gingerbread"]
				},
				"discrimination": 1.56807879599531
			}, {
				"itemId": 3355,
				"itemLabel": "L2W_356",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.58748673694798,
				"choices": ["p", "r", "o", "t", "a", "g", "o", "n", "i", "s", "t", "b", "e", "z"],
				"correctAnswer": "protagonist",
				"audioFileNames": {
					"prompts": ["L2Wex_Protagonist"]
				},
				"discrimination": 2.22309446585144
			}, {
				"itemId": 3356,
				"itemLabel": "L2W_357",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.19496782624152,
				"choices": ["c", "o", "n", "s", "e", "q", "u", "e", "n", "c", "e", "k", "a", "m"],
				"correctAnswer": "consequence",
				"audioFileNames": {
					"prompts": ["L2Wex_Consequence"]
				},
				"discrimination": 0.782007971910014
			}, {
				"itemId": 3357,
				"itemLabel": "L2W_358",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.15059968785846,
				"choices": ["o", "p", "p", "o", "r", "t", "u", "n", "i", "t", "y", "a", "m", "e"],
				"correctAnswer": "opportunity",
				"audioFileNames": {
					"prompts": ["L2Wex_Opportunity"]
				},
				"discrimination": 1.12742817838857
			}, {
				"itemId": 3358,
				"itemLabel": "L2W_359",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.86534870032996,
				"choices": ["d", "o", "m", "e", "s", "t", "i", "c", "a", "t", "e", "b", "n", "k"],
				"correctAnswer": "domesticate",
				"audioFileNames": {
					"prompts": ["L2Wex_Domesticate"]
				},
				"discrimination": 1.10154236197805
			}, {
				"itemId": 3359,
				"itemLabel": "L2W_360",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.08857888695759,
				"choices": ["h", "a", "n", "d", "w", "r", "i", "t", "t", "e", "n", "h", "u", "p"],
				"correctAnswer": "handwritten",
				"audioFileNames": {
					"prompts": ["L2Wex_Handwritten"]
				},
				"discrimination": 2.61293328879315
			}, {
				"itemId": 3360,
				"itemLabel": "L2W_361",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.27063734380935,
				"choices": ["i", "m", "a", "g", "i", "n", "a", "t", "i", "v", "e", "g", "b", "m"],
				"correctAnswer": "imaginative",
				"audioFileNames": {
					"prompts": ["L2Wex_Imaginative"]
				},
				"discrimination": 1.3129542181042
			}, {
				"itemId": 3361,
				"itemLabel": "L2W_362",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.78565653424242,
				"choices": ["i", "m", "p", "l", "a", "u", "s", "i", "b", "l", "e", "h", "a", "w"],
				"correctAnswer": "implausible",
				"audioFileNames": {
					"prompts": ["L2Wex_Implausible"]
				},
				"discrimination": 1.33288927050456
			}, {
				"itemId": 3362,
				"itemLabel": "L2W_363",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 3.13725222734492,
				"choices": ["i", "n", "g", "r", "e", "d", "i", "e", "n", "t", "s", "h", "a", "q"],
				"correctAnswer": "ingredients",
				"audioFileNames": {
					"prompts": ["L2Wex_Ingredients"]
				},
				"discrimination": 0.538868390126898
			}, {
				"itemId": 3363,
				"itemLabel": "L2W_364",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.36007403753556,
				"choices": ["l", "e", "g", "i", "s", "l", "a", "t", "i", "o", "n", "n", "b", "e"],
				"correctAnswer": "legislation",
				"audioFileNames": {
					"prompts": ["L2Wex_Legislation"]
				},
				"discrimination": 1.63466838025568
			}, {
				"itemId": 3364,
				"itemLabel": "L2W_365",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 2.15752048057047,
				"choices": ["l", "i", "q", "u", "i", "d", "a", "t", "i", "o", "n", "n", "b", "s"],
				"correctAnswer": "liquidation",
				"audioFileNames": {
					"prompts": ["L2Wex_Liquidation"]
				},
				"discrimination": 1.49116213766708
			}, {
				"itemId": 3365,
				"itemLabel": "L2W_366",
				"itemType": "letters2Word",
				"itemClass": "active",
				"difficulty": 1.36159880830216,
				"choices": ["m", "a", "s", "t", "e", "r", "f", "u", "l", "l", "y", "n", "d", "p"],
				"correctAnswer": "masterfully",
				"audioFileNames": {
					"prompts": ["L2Wex_Masterfully"]
				},
				"discrimination": 1.52296669083154
			}]
		},
		"wordRecognitionDecodable": {
			"trainingItemIds": [4003, 4006],
			"numTrainingItemRetries": 1,
			"items": [{
				"itemId": 4000,
				"itemLabel": "WR_1",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.583327230205832,
				"choices": ["Ace", "Ant", "Add", "And", "Can", "Apple", "Ahn"],
				"correctAnswer": "And",
				"audioFileNames": {
					"prompts": ["WR_And"]
				},
				"discrimination": 2.75753316082788
			}, {
				"itemId": 4001,
				"itemLabel": "WR_2",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.644671736991782,
				"choices": ["Cow", "Bit", "Large", "Pig", "Big", "Bag", "Beegg"],
				"correctAnswer": "Big",
				"audioFileNames": {
					"prompts": ["WR_Big"]
				},
				"discrimination": 3.07969805532867
			}, {
				"itemId": 4002,
				"itemLabel": "WR_3",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.699764534195767,
				"choices": ["Cow", "Can", "Person", "Man", "Map", "Mad", "Goop"],
				"correctAnswer": "Man",
				"audioFileNames": {
					"prompts": ["WR_Man"]
				},
				"discrimination": 2.78818039382539
			}, {
				"itemId": 4003,
				"itemLabel": "WR_4",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "training",
				"difficulty": -0.661,
				"choices": ["Bomb", "Man", "Moon", "Munk", "Mad", "Moom", "Mom"],
				"correctAnswer": "Mom",
				"audioFileNames": {
					"instructions": ["WR_Instructions"],
					"prompts": ["WR_T1_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["WR_T1_Feedback_correct"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["WR_T1_Feedback_incorrect", "Lets_try_it_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["WR_T1_Feedback_correct"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["Lets_try_another_one"]
					}]
				}
			}, {
				"itemId": 4004,
				"itemLabel": "WR_5",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.473202193049535,
				"choices": ["Fog", "Cow", "Cone", "Sow", "Steer", "Cod", "Gorp"],
				"correctAnswer": "Cow",
				"audioFileNames": {
					"prompts": ["WR_Cow"]
				},
				"discrimination": 2.09568217523374
			}, {
				"itemId": 4005,
				"itemLabel": "WR_6",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.0567265658928348,
				"choices": ["True", "Turew", "Right", "Mean", "Troop", "Blue", "Flew"],
				"correctAnswer": "True",
				"audioFileNames": {
					"prompts": ["WR_True"]
				},
				"discrimination": 1.67961836050522
			}, {
				"itemId": 4006,
				"itemLabel": "WR_7",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "training",
				"difficulty": -0.661,
				"choices": ["Bird", "Down", "Band", "Crow", "Bind", "Burn", "Grund"],
				"correctAnswer": "Bird",
				"audioFileNames": {
					"instructions": [],
					"prompts": ["WR_T2_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["WR_T2_Feedback_correct", "WR_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["WR_T2_Feedback_incorrect", "Lets_try_it_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["WR_T2_Feedback_correct", "WR_Training_FinalPrompt"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["WR_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["WR_T2_Feedback_correct", "WR_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["WR_T2_Feedback_incorrect", "WR_Training_FinalPrompt"]
					}]
				}
			}, {
				"itemId": 4007,
				"itemLabel": "WR_8",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.385856510925815,
				"choices": ["Game", "Carp", "Note", "Carve", "Cart", "Card", "Curps"],
				"correctAnswer": "Card",
				"audioFileNames": {
					"prompts": ["WR_Card"]
				},
				"discrimination": 2.63049877518286
			}, {
				"itemId": 4008,
				"itemLabel": "WR_9",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.603205466079862,
				"choices": ["Dance", "Junk", "Hop", "Jump", "Pump", "Jam", "Jampf"],
				"correctAnswer": "Jump",
				"audioFileNames": {
					"prompts": ["WR_Jump"]
				},
				"discrimination": 2.15034212139354
			}, {
				"itemId": 4009,
				"itemLabel": "WR_10",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.129638623377036,
				"choices": ["Stomp", "Frown", "Down", "Below", "Dawn", "Dowdy", "Dahnn"],
				"correctAnswer": "Down",
				"audioFileNames": {
					"prompts": ["WR_Down"]
				},
				"discrimination": 1.99351700074318
			}, {
				"itemId": 4010,
				"itemLabel": "WR_11",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.3043547670429,
				"choices": ["Went", "Cave", "Comb", "Cane", "Calve", "Came", "Cavle"],
				"correctAnswer": "Came",
				"audioFileNames": {
					"prompts": ["WR_Came"]
				},
				"discrimination": 2.17791983300233
			}, {
				"itemId": 4011,
				"itemLabel": "WR_12",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.622861555744172,
				"choices": ["Smart", "Goof", "Good", "Nice", "Wood", "Gone", "Gvod"],
				"correctAnswer": "Good",
				"audioFileNames": {
					"prompts": ["WR_Good"]
				},
				"discrimination": 2.05926725564372
			}, {
				"itemId": 4012,
				"itemLabel": "WR_13",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.219934780066896,
				"choices": ["Pond", "Roar", "Path", "Rope", "Road", "Wrote", "Rawd"],
				"correctAnswer": "Road",
				"audioFileNames": {
					"prompts": ["WR_Road"]
				},
				"discrimination": 1.92520401752915
			}, {
				"itemId": 4013,
				"itemLabel": "WR_14",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.450498912964565,
				"choices": ["Hot", "Colt", "Chilly", "Bold", "Cold", "Cane", "Colk"],
				"correctAnswer": "Cold",
				"audioFileNames": {
					"prompts": ["WR_Cold"]
				},
				"discrimination": 2.621072424827
			}, {
				"itemId": 4014,
				"itemLabel": "WR_15",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.11457173165353,
				"choices": ["Cake", "Make", "Made", "Create", "Paid", "Mail", "Maed"],
				"correctAnswer": "Made",
				"audioFileNames": {
					"prompts": ["WR_Made"]
				},
				"discrimination": 2.27014746267554
			}, {
				"itemId": 4015,
				"itemLabel": "WR_16",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.0584364906040089,
				"choices": ["Talk", "Sign", "Hum", "Swing", "Sing", "Sang", "Sleng"],
				"correctAnswer": "Sing",
				"audioFileNames": {
					"prompts": ["WR_Sing"]
				},
				"discrimination": 2.0233220236309
			}, {
				"itemId": 4016,
				"itemLabel": "WR_17",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.111656815285389,
				"choices": ["Snore", "Cash", "Scrub", "Rash", "Water", "Wash", "Warsh"],
				"correctAnswer": "Wash",
				"audioFileNames": {
					"prompts": ["WR_Wash"]
				},
				"discrimination": 2.14122789271873
			}, {
				"itemId": 4017,
				"itemLabel": "WR_18",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.548617495170341,
				"choices": ["Stick", "Pack", "Choose", "Rack", "Pick", "Park", "Puxk"],
				"correctAnswer": "Pick",
				"audioFileNames": {
					"prompts": ["WR_Pick"]
				},
				"discrimination": 2.54084068000678
			}, {
				"itemId": 4018,
				"itemLabel": "WR_19",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.150567249174597,
				"choices": ["Klook", "Tower", "Clock", "Clown", "Cluck", "Timer", "Block"],
				"correctAnswer": "Clock",
				"audioFileNames": {
					"prompts": ["WR_Clock"]
				},
				"discrimination": 2.19432020178762
			}, {
				"itemId": 4019,
				"itemLabel": "WR_20",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.430926925424227,
				"choices": ["Dog", "Groom", "Room", "Duster", "Bring", "Durp", "Broom"],
				"correctAnswer": "Broom",
				"audioFileNames": {
					"prompts": ["WR_Broom"]
				},
				"discrimination": 2.0273770594309
			}, {
				"itemId": 4020,
				"itemLabel": "WR_21",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.276861701437003,
				"choices": ["Gave", "Brand", "Carry", "Bright", "Bring", "Brown", "Booper"],
				"correctAnswer": "Bring",
				"audioFileNames": {
					"prompts": ["WR_Bring"]
				},
				"discrimination": 2.38095974068643
			}, {
				"itemId": 4021,
				"itemLabel": "WR_22",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.165293346682402,
				"choices": ["Away", "Govern", "Gifted", "Driven", "Gilded", "Ganny", "Given"],
				"correctAnswer": "Given",
				"audioFileNames": {
					"prompts": ["WR_Given"]
				},
				"discrimination": 2.04323585624576
			}, {
				"itemId": 4022,
				"itemLabel": "WR_23",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.38717630743851,
				"choices": ["Worst", "Grand", "Wrung", "Awful", "First", "Wound", "Wuffy"],
				"correctAnswer": "Worst",
				"audioFileNames": {
					"prompts": ["WR_Worst"]
				},
				"discrimination": 2.36633808029731
			}, {
				"itemId": 4023,
				"itemLabel": "WR_24",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.21896654084617,
				"choices": ["Right", "Wrote", "Wrist", "Ankle", "Fist", "Wrung", "Warged"],
				"correctAnswer": "Wrist",
				"audioFileNames": {
					"prompts": ["WR_Wrist"]
				},
				"discrimination": 2.86491888159046
			}, {
				"itemId": 4024,
				"itemLabel": "WR_25",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.0167879952036984,
				"choices": ["First", "Crush", "Want", "Cave", "Crashed", "Crave", "Crups"],
				"correctAnswer": "Crave",
				"audioFileNames": {
					"prompts": ["WR_Crave"]
				},
				"discrimination": 2.17634350239692
			}, {
				"itemId": 4025,
				"itemLabel": "WR_26",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.220819371675862,
				"choices": ["Raft", "Laughter", "Giggle", "Laugh", "Bath", "Launch", "Laft"],
				"correctAnswer": "Laugh",
				"audioFileNames": {
					"prompts": ["WR_Laugh"]
				},
				"discrimination": 2.72853780647439
			}, {
				"itemId": 4026,
				"itemLabel": "WR_27",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.0348139490381785,
				"choices": ["Soup", "Drank", "Gulp", "Brink", "Drink", "Drain", "Drinx"],
				"correctAnswer": "Drink",
				"audioFileNames": {
					"prompts": ["WR_Drink"]
				},
				"discrimination": 1.84919828290231
			}, {
				"itemId": 4027,
				"itemLabel": "WR_28",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.175070909675534,
				"choices": ["Drop", "Curry", "Lift", "Carry", "Berry", "Carrot", "Cawry"],
				"correctAnswer": "Carry",
				"audioFileNames": {
					"prompts": ["WR_Carry"]
				},
				"discrimination": 1.52398638916558
			}, {
				"itemId": 4028,
				"itemLabel": "WR_29",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.543293632526743,
				"choices": ["Lizard", "Tiger", "Typing", "Wildcat", "Tipped", "Fragged"],
				"correctAnswer": "Tiger",
				"audioFileNames": {
					"prompts": ["WR_Tiger"]
				},
				"discrimination": 2.27149973388517
			}, {
				"itemId": 4029,
				"itemLabel": "WR_30",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.239606774844834,
				"choices": ["Forest", "Gown", "Adult", "Throne", "Green", "Harney", "Grown"],
				"correctAnswer": "Grown",
				"audioFileNames": {
					"prompts": ["WR_Grown"]
				},
				"discrimination": 1.15084227608902
			}, {
				"itemId": 4030,
				"itemLabel": "WR_31",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.0247807504784966,
				"choices": ["Every", "Design", "Straighten", "Align", "Allover", "Elighten", "Gullfey"],
				"correctAnswer": "Align",
				"audioFileNames": {
					"prompts": ["WR_Align"]
				},
				"discrimination": 1.72078073219172
			}, {
				"itemId": 4031,
				"itemLabel": "WR_32",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.359689952011854,
				"choices": ["Down", "Shade", "Beach", "Shore", "Shyed", "Torn", "Jeffed"],
				"correctAnswer": "Shore",
				"audioFileNames": {
					"prompts": ["WR_Shore"]
				},
				"discrimination": 2.29355530900067
			}, {
				"itemId": 4032,
				"itemLabel": "WR_33",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.229043398620898,
				"choices": ["Clown", "Funky", "Happy", "Bunny", "Funny", "Funnel", "Fawny"],
				"correctAnswer": "Funny",
				"audioFileNames": {
					"prompts": ["WR_Funny"]
				},
				"discrimination": 2.25929136118634
			}, {
				"itemId": 4033,
				"itemLabel": "WR_34",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.0408919411382824,
				"choices": ["Further", "After", "Scared", "Afar", "Afraid", "Affect", "Fluther"],
				"correctAnswer": "Afraid",
				"audioFileNames": {
					"prompts": ["WR_Afraid"]
				},
				"discrimination": 2.42635254234822
			}, {
				"itemId": 4034,
				"itemLabel": "WR_35",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.0837191589768601,
				"choices": ["Bread", "Batter", "Best", "Wetter", "Better", "Boost", "Behwer"],
				"correctAnswer": "Better",
				"audioFileNames": {
					"prompts": ["WR_Better"]
				},
				"discrimination": 1.76740290089181
			}, {
				"itemId": 4035,
				"itemLabel": "WR_36",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.291852150678964,
				"choices": ["Forget", "Buyer", "Skip", "Wheatgrass", "Bygone", "Bypass", "Goymer"],
				"correctAnswer": "Bypass",
				"audioFileNames": {
					"prompts": ["WR_Bypass"]
				},
				"discrimination": 1.72784966627189
			}, {
				"itemId": 4036,
				"itemLabel": "WR_37",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.323457677538401,
				"choices": ["Mistake", "Myopic", "Me", "Herself", "Myth", "Myself", "Mizelt"],
				"correctAnswer": "Myself",
				"audioFileNames": {
					"prompts": ["WR_Myself"]
				},
				"discrimination": 2.80278004540614
			}, {
				"itemId": 4037,
				"itemLabel": "WR_38",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.29492800980175,
				"choices": ["Found", "Adored", "Clothed", "Reborn", "Adorned", "Added", "Gruffy"],
				"correctAnswer": "Adorned",
				"audioFileNames": {
					"prompts": ["WR_Adorned"]
				},
				"discrimination": 1.20061479266951
			}, {
				"itemId": 4038,
				"itemLabel": "WR_39",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.257208299684128,
				"choices": ["Foretell", "Perrdik", "Pretty", "Predicate", "Second", "Perfect", "Predict"],
				"correctAnswer": "Predict",
				"audioFileNames": {
					"prompts": ["WR_Predict"]
				},
				"discrimination": 1.35996149601948
			}, {
				"itemId": 4039,
				"itemLabel": "WR_40",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.123552514349719,
				"choices": ["Flavor", "Roost", "Rooster", "Turkey", "Booster", "Rover", "Gooper"],
				"correctAnswer": "Rooster",
				"audioFileNames": {
					"prompts": ["WR_Rooster"]
				},
				"discrimination": 1.84167643721918
			}, {
				"itemId": 4040,
				"itemLabel": "WR_41",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.104961506508411,
				"choices": ["Resources", "Accondmy", "Ecology", "Enough", "Dictionary", "Economy", "Autonomy"],
				"correctAnswer": "Economy",
				"audioFileNames": {
					"prompts": ["WR_Economy"]
				},
				"discrimination": 1.21235497150506
			}, {
				"itemId": 4041,
				"itemLabel": "WR_42",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.0525000753627773,
				"choices": ["Grounded", "Favored", "Favorite", "Best", "Farther", "Fathered", "Garper"],
				"correctAnswer": "Favorite",
				"audioFileNames": {
					"prompts": ["WR_Favorite"]
				},
				"discrimination": 1.61165582080433
			}, {
				"itemId": 4042,
				"itemLabel": "WR_43",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.361246543855914,
				"choices": ["Teach", "Instruct", "Insult", "Decide", "Insumpt", "Insect", "Induct"],
				"correctAnswer": "Instruct",
				"audioFileNames": {
					"prompts": ["WR_Instruct"]
				},
				"discrimination": 1.66834238677064
			}, {
				"itemId": 4043,
				"itemLabel": "WR_44",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.0130801787906736,
				"choices": ["Category", "Catfish", "Capacity", "Classification", "Kedatory", "Premier", "Regulatory"],
				"correctAnswer": "Category",
				"audioFileNames": {
					"prompts": ["WR_Category"]
				},
				"discrimination": 1.80903603250988
			}, {
				"itemId": 4044,
				"itemLabel": "WR_45",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.065435036032373,
				"choices": ["Transfer", "Double", "Change", "Transform", "Transparent", "Ranstfirm", "Trouble"],
				"correctAnswer": "Transform",
				"audioFileNames": {
					"prompts": ["WR_Transform"]
				},
				"discrimination": 2.38240677761012
			}, {
				"itemId": 4045,
				"itemLabel": "WR_46",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.141961740832969,
				"choices": ["Expect", "Tipertate", "Anticipate", "Antics", "Articulate", "Grapheme", "Participate"],
				"correctAnswer": "Anticipate",
				"audioFileNames": {
					"prompts": ["WR_Anticipate"]
				},
				"discrimination": 1.78609572624087
			}, {
				"itemId": 4046,
				"itemLabel": "WR_47",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": -0.00395141524397595,
				"choices": ["Copercite", "Interact", "Decide", "Communicate", "Competitive", "Common", "Complicate"],
				"correctAnswer": "Communicate",
				"audioFileNames": {
					"prompts": ["WR_Communicate"]
				},
				"discrimination": 1.66784001944494
			}, {
				"itemId": 4047,
				"itemLabel": "WR_48",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.4112023578576,
				"choices": ["Adaptation", "Modification", "Stranded", "Qualification", "Motivation", "Moderate", "Mofication"],
				"correctAnswer": "Modification",
				"audioFileNames": {
					"prompts": ["WR_Modification"]
				},
				"discrimination": 0.944050202519676
			}, {
				"itemId": 4048,
				"itemLabel": "WR_49",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.40888886502074,
				"choices": ["Accessibility", "Convenience", "Academic", "Akesible", "Duration", "Accessories", "Enforceability"],
				"correctAnswer": "Accessibility",
				"audioFileNames": {
					"prompts": ["WR_Accessibility"]
				},
				"discrimination": 1.48566221905265
			}, {
				"itemId": 4049,
				"itemLabel": "WR_50",
				"itemType": "wordRecognitionDecodable",
				"itemClass": "active",
				"difficulty": 0.373532422805738,
				"choices": ["Unessential", "Understate", "Etermaste", "Overestimate", "Breakfast", "Underneath", "Underestimate"],
				"correctAnswer": "Underestimate",
				"audioFileNames": {
					"prompts": ["WR_Underestimate"]
				},
				"discrimination": 1.29536213038825
			}]
		},
		"wordRecognitionNonDecodable": {
			"trainingItemIds": [],
			"numTrainingItemRetries": 0,
			"items": [{
				"itemId": 5000,
				"itemLabel": "WR_51",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.823020171932041,
				"choices": ["Deh", "Do", "Make", "Door", "For", "Dog", "To"],
				"correctAnswer": "Do",
				"audioFileNames": {
					"prompts": ["NDWR_Do"]
				},
				"discrimination": 2.58094762465331
			}, {
				"itemId": 5001,
				"itemLabel": "WR_52",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.366647926260726,
				"choices": ["Rah", "Arena", "Are", "Arch", "Occur", "Car", "Hair"],
				"correctAnswer": "Are",
				"audioFileNames": {
					"prompts": ["NDWR_Are"]
				},
				"discrimination": 3.16672964109878
			}, {
				"itemId": 5002,
				"itemLabel": "WR_53",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.534484770203589,
				"choices": ["Buy", "Up", "Fuse", "Use", "Muse", "Undo", "Burg"],
				"correctAnswer": "Use",
				"audioFileNames": {
					"prompts": ["NDWR_Use"]
				},
				"discrimination": 2.279650377685
			}, {
				"itemId": 5003,
				"itemLabel": "WR_54",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.735048403057656,
				"choices": ["Grave", "Ate", "Chew", "Eat", "Meat", "Ear", "Merm"],
				"correctAnswer": "Eat",
				"audioFileNames": {
					"prompts": ["NDWR_Eat"]
				},
				"discrimination": 2.34853238655173
			}, {
				"itemId": 5004,
				"itemLabel": "WR_55",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.585440577654132,
				"choices": ["Run", "Why", "Soar", "Cry", "Float", "Fly", "Floo"],
				"correctAnswer": "Fly",
				"audioFileNames": {
					"prompts": ["NDWR_Fly"]
				},
				"discrimination": 2.50705193380738
			}, {
				"itemId": 5005,
				"itemLabel": "WR_56",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.23420091043655,
				"choices": ["Them", "Down", "Then", "They", "Stem", "There", "Thands"],
				"correctAnswer": "Them",
				"audioFileNames": {
					"prompts": ["NDWR_Them"]
				},
				"discrimination": 2.43056938247671
			}, {
				"itemId": 5006,
				"itemLabel": "WR_57",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.249096086870226,
				"choices": ["Lamp", "Flour", "Time", "Sour", "Hour", "Higher", "Hurrs"],
				"correctAnswer": "Hour",
				"audioFileNames": {
					"prompts": ["NDWR_Hour"]
				},
				"discrimination": 2.65754662847548
			}, {
				"itemId": 5007,
				"itemLabel": "WR_58",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.565691675264104,
				"choices": ["Begs", "Come", "Cone", "Gone", "From", "Cod", "Murph"],
				"correctAnswer": "Come",
				"audioFileNames": {
					"prompts": ["NDWR_Come"]
				},
				"discrimination": 2.89815188646378
			}, {
				"itemId": 5008,
				"itemLabel": "WR_59",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.208986175590297,
				"choices": ["Want", "Said", "Sad", "Talked", "Paid", "Sand", "Mappy"],
				"correctAnswer": "Said",
				"audioFileNames": {
					"prompts": ["NDWR_Said"]
				},
				"discrimination": 2.02032664269819
			}, {
				"itemId": 5009,
				"itemLabel": "WR_60",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.563282004068941,
				"choices": ["Make", "Hive", "Number", "Fads", "First", "Five", "Frumpy"],
				"correctAnswer": "Five",
				"audioFileNames": {
					"prompts": ["NDWR_Five"]
				},
				"discrimination": 2.56833463900685
			}, {
				"itemId": 5010,
				"itemLabel": "WR_61",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.501581737765208,
				"choices": ["How", "Gong", "Tall", "Lawn", "Long", "Load", "Lappy"],
				"correctAnswer": "Long",
				"audioFileNames": {
					"prompts": ["NDWR_Long"]
				},
				"discrimination": 2.59363324647036
			}, {
				"itemId": 5011,
				"itemLabel": "WR_62",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.137424536997377,
				"choices": ["Does", "Sees", "Talks", "Says", "Saws", "Salt", "Sewz"],
				"correctAnswer": "Says",
				"audioFileNames": {
					"prompts": ["NDWR_Says"]
				},
				"discrimination": 2.0398740425261
			}, {
				"itemId": 5012,
				"itemLabel": "WR_63",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.409105461548165,
				"choices": ["Four", "Kick", "Foam", "Many", "Found", "Floor", "Foop"],
				"correctAnswer": "Four",
				"audioFileNames": {
					"prompts": ["NDWR_Four"]
				},
				"discrimination": 2.12289192078555
			}, {
				"itemId": 5013,
				"itemLabel": "WR_64",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.519887978205854,
				"choices": ["Play", "Cord", "Job", "Word", "Worm", "Work", "Woomp"],
				"correctAnswer": "Work",
				"audioFileNames": {
					"prompts": ["NDWR_Work"]
				},
				"discrimination": 2.93957742315754
			}, {
				"itemId": 5014,
				"itemLabel": "WR_65",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.274132208029291,
				"choices": ["One", "When", "Aspen", "Flat", "Want", "What", "Whoop"],
				"correctAnswer": "What",
				"audioFileNames": {
					"prompts": ["NDWR_What"]
				},
				"discrimination": 2.26864090788773
			}, {
				"itemId": 5015,
				"itemLabel": "WR_66",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.101306288106712,
				"choices": ["Sing", "Walk", "Chat", "Tall", "Tail", "Talk", "Take"],
				"correctAnswer": "Talk",
				"audioFileNames": {
					"prompts": ["NDWR_Talk"]
				},
				"discrimination": 2.22281297601596
			}, {
				"itemId": 5016,
				"itemLabel": "WR_67",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.354758294171408,
				"choices": ["Lung", "Chart", "Heart", "Love", "Hard", "Heavy", "Heargle"],
				"correctAnswer": "Heart",
				"audioFileNames": {
					"prompts": ["NDWR_Heart"]
				},
				"discrimination": 2.29374978228166
			}, {
				"itemId": 5017,
				"itemLabel": "WR_68",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.0273216311209054,
				"choices": ["World", "City", "Told", "Earth", "Word", "Purr", "Wobble"],
				"correctAnswer": "World",
				"audioFileNames": {
					"prompts": ["NDWR_World"]
				},
				"discrimination": 1.59160274125454
			}, {
				"itemId": 5018,
				"itemLabel": "WR_69",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.12138981390549,
				"choices": ["Old", "Young", "Your", "Child", "Younger", "Yard", "Yoorg"],
				"correctAnswer": "Young",
				"audioFileNames": {
					"prompts": ["NDWR_Young"]
				},
				"discrimination": 2.42569719826545
			}, {
				"itemId": 5019,
				"itemLabel": "WR_70",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.0269935517980194,
				"choices": ["Late", "Earth", "Soon", "Early", "Yearly", "Yearning", "Yarble"],
				"correctAnswer": "Early",
				"audioFileNames": {
					"prompts": ["NDWR_Early"]
				},
				"discrimination": 2.05601569044393
			}, {
				"itemId": 5020,
				"itemLabel": "WR_71",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.0418511138294089,
				"choices": ["Above", "Below", "Dove", "Upper", "Buffer", "About", "Argyle"],
				"correctAnswer": "Above",
				"audioFileNames": {
					"prompts": ["NDWR_Above"]
				},
				"discrimination": 2.74968831939687
			}, {
				"itemId": 5021,
				"itemLabel": "WR_72",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.236465036032421,
				"choices": ["Been", "Could", "Should", "Began", "World", "Would", "World"],
				"correctAnswer": "Would",
				"audioFileNames": {
					"prompts": ["NDWR_Would"]
				},
				"discrimination": 2.5610807634467
			}, {
				"itemId": 5022,
				"itemLabel": "WR_73",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.0796450735229121,
				"choices": ["Square", "Pound", "Circle", "Rowdy", "Route", "Round", "Rawnd"],
				"correctAnswer": "Round",
				"audioFileNames": {
					"prompts": ["NDWR_Round"]
				},
				"discrimination": 1.78733360343926
			}, {
				"itemId": 5023,
				"itemLabel": "WR_74",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 1.10449835562242,
				"choices": ["Island", "Aisle", "Passage", "Isttle", "Energy", "Tile", "Affect"],
				"correctAnswer": "Aisle",
				"audioFileNames": {
					"prompts": ["NDWR_Aisle"]
				},
				"discrimination": 0.978304880576061
			}, {
				"itemId": 5024,
				"itemLabel": "WR_75",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.0760563607661416,
				"choices": ["Drink", "Waiter", "Liquid", "Whether", "Writer", "Wanter", "Water"],
				"correctAnswer": "Water",
				"audioFileNames": {
					"prompts": ["NDWR_Water"]
				},
				"discrimination": 2.03986643394687
			}, {
				"itemId": 5025,
				"itemLabel": "WR_76",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.0777825386490675,
				"choices": ["Doorway", "Month", "Palate", "South", "Mouse", "Mouth", "Moose"],
				"correctAnswer": "Mouth",
				"audioFileNames": {
					"prompts": ["NDWR_Mouth"]
				},
				"discrimination": 1.53781551336068
			}, {
				"itemId": 5026,
				"itemLabel": "WR_77",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.121101450932023,
				"choices": ["Hunger", "Brown", "Brawn", "Color", "Drown", "Brain", "Blag"],
				"correctAnswer": "Brown",
				"audioFileNames": {
					"prompts": ["NDWR_Brown"]
				},
				"discrimination": 1.81195794048175
			}, {
				"itemId": 5027,
				"itemLabel": "WR_78",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.326194644055254,
				"choices": ["Down", "Wharf", "There", "White", "Where", "When", "Whoff"],
				"correctAnswer": "Where",
				"audioFileNames": {
					"prompts": ["NDWR_Where"]
				},
				"discrimination": 3.01994813033788
			}, {
				"itemId": 5028,
				"itemLabel": "WR_79",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.4054537397821,
				"choices": ["Paper", "Green", "Cluster", "Croup", "Gather", "Group", "Goofer"],
				"correctAnswer": "Group",
				"audioFileNames": {
					"prompts": ["NDWR_Group"]
				},
				"discrimination": 1.6896086235426
			}, {
				"itemId": 5029,
				"itemLabel": "WR_80",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.491588750849082,
				"choices": ["Crushed", "Sensed", "Seven", "Heaven", "Numbers", "Sensored", "Sooky"],
				"correctAnswer": "Seven",
				"audioFileNames": {
					"prompts": ["NDWR_Seven"]
				},
				"discrimination": 2.10338256829939
			}, {
				"itemId": 5030,
				"itemLabel": "WR_81",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.165467027666077,
				"choices": ["Ground", "Regain", "Over", "Reason", "Again", "Agape", "Fagan"],
				"correctAnswer": "Again",
				"audioFileNames": {
					"prompts": ["NDWR_Again"]
				},
				"discrimination": 3.00146283955116
			}, {
				"itemId": 5031,
				"itemLabel": "WR_82",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.1042534897739,
				"choices": ["Gives", "Cover", "Above", "Other", "Oath", "Obrey", "Over"],
				"correctAnswer": "Other",
				"audioFileNames": {
					"prompts": ["NDWR_Other"]
				},
				"discrimination": 1.71244466682856
			}, {
				"itemId": 5032,
				"itemLabel": "WR_83",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.11227430925494,
				"choices": ["Hands", "Hired", "Blank", "With", "What", "White", "Wappy"],
				"correctAnswer": "White",
				"audioFileNames": {
					"prompts": ["NDWR_White"]
				},
				"discrimination": 1.97524645600204
			}, {
				"itemId": 5033,
				"itemLabel": "WR_84",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.163719981779489,
				"choices": ["Grown", "Tree", "Three", "Triple", "Thrush", "Scream", "Traffed"],
				"correctAnswer": "Three",
				"audioFileNames": {
					"prompts": ["NDWR_Three"]
				},
				"discrimination": 2.21806561067358
			}, {
				"itemId": 5034,
				"itemLabel": "WR_85",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.236352070977448,
				"choices": ["Flavor", "Purple", "Humans", "People", "Panted", "Cready", "Popple"],
				"correctAnswer": "People",
				"audioFileNames": {
					"prompts": ["NDWR_People"]
				},
				"discrimination": 1.70660231183267
			}, {
				"itemId": 5035,
				"itemLabel": "WR_86",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.250413255974431,
				"choices": ["Enemy", "Freeze", "Pal", "Bend", "Frown", "Friend", "Fraynd"],
				"correctAnswer": "Friend",
				"audioFileNames": {
					"prompts": ["NDWR_Friend"]
				},
				"discrimination": 2.21123294789839
			}, {
				"itemId": 5036,
				"itemLabel": "WR_87",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.3873300971402,
				"choices": ["Few", "Extra", "Lots", "Rough", "Entertain", "Enough", "Enaff"],
				"correctAnswer": "Enough",
				"audioFileNames": {
					"prompts": ["NDWR_Enough"]
				},
				"discrimination": 2.54194252080613
			}, {
				"itemId": 5037,
				"itemLabel": "WR_88",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.332582924693513,
				"choices": ["Mother", "Faith", "Daddy", "Farther", "Father", "Faster", "Fawer"],
				"correctAnswer": "Father",
				"audioFileNames": {
					"prompts": ["NDWR_Father"]
				},
				"discrimination": 1.12582259879655
			}, {
				"itemId": 5038,
				"itemLabel": "WR_89",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.518101497677184,
				"choices": ["Tempory", "United", "Yunike", "Beak", "Underlie", "Distinct", "Unique"],
				"correctAnswer": "Unique",
				"audioFileNames": {
					"prompts": ["NDWR_Unique"]
				},
				"discrimination": 1.58688110623729
			}, {
				"itemId": 5039,
				"itemLabel": "WR_90",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.027396808574338,
				"choices": ["Please", "Appease", "Manners", "Peas", "Plays", "Planks", "Patsy"],
				"correctAnswer": "Please",
				"audioFileNames": {
					"prompts": ["NDWR_Please"]
				},
				"discrimination": 2.02745930455406
			}, {
				"itemId": 5040,
				"itemLabel": "WR_91",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.532363393085095,
				"choices": ["Norse", "Planks", "Colors", "Pungent", "Plum", "Pakker", "Purple"],
				"correctAnswer": "Purple",
				"audioFileNames": {
					"prompts": ["NDWR_Purple"]
				},
				"discrimination": 3.39384672979349
			}, {
				"itemId": 5041,
				"itemLabel": "WR_92",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.630313914030631,
				"choices": ["Breath", "Plasma", "Aspect", "Thelmatic", "Devote", "Assume", "Asthma"],
				"correctAnswer": "Asthma",
				"audioFileNames": {
					"prompts": ["NDWR_Asthma"]
				},
				"discrimination": 1.28514445375559
			}, {
				"itemId": 5042,
				"itemLabel": "WR_93",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.11893069796294,
				"choices": ["Every", "Should", "Could", "Best", "Shown", "Sheared", "Sangey"],
				"correctAnswer": "Should",
				"audioFileNames": {
					"prompts": ["NDWR_Should"]
				},
				"discrimination": 2.14712685881256
			}, {
				"itemId": 5043,
				"itemLabel": "WR_94",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.342169118400301,
				"choices": ["Hands", "Oaken", "Fruit", "Foreign", "Orange", "Organ", "Ookey"],
				"correctAnswer": "Orange",
				"audioFileNames": {
					"prompts": ["NDWR_Orange"]
				},
				"discrimination": 1.79824404531935
			}, {
				"itemId": 5044,
				"itemLabel": "WR_95",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.536341087409204,
				"choices": ["Balance", "Frozen", "Fillet", "Callect", "Ballet", "Battle", "Performance"],
				"correctAnswer": "Ballet",
				"audioFileNames": {
					"prompts": ["NDWR_Ballet"]
				},
				"discrimination": 1.5475228453685
			}, {
				"itemId": 5045,
				"itemLabel": "WR_96",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.604107564047434,
				"choices": ["Theme", "Throw", "Accurate", "Roughtly", "Opening", "Through", "Glue"],
				"correctAnswer": "Through",
				"audioFileNames": {
					"prompts": ["NDWR_Through"]
				},
				"discrimination": 1.89846825340338
			}, {
				"itemId": 5046,
				"itemLabel": "WR_97",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.011139983475214,
				"choices": ["Children", "County", "Nation", "Poultry", "Citizen", "Country", "Cooper"],
				"correctAnswer": "Country",
				"audioFileNames": {
					"prompts": ["NDWR_Country"]
				},
				"discrimination": 1.26932593506462
			}, {
				"itemId": 5047,
				"itemLabel": "WR_98",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.30250702491206,
				"choices": ["Lerlical", "Officer", "Colonel", "Normal", "College", "Classic", "Journal"],
				"correctAnswer": "Colonel",
				"audioFileNames": {
					"prompts": ["NDWR_Colonel"]
				},
				"discrimination": 1.48886465759929
			}, {
				"itemId": 5048,
				"itemLabel": "WR_99",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.42772303311816,
				"choices": ["Ethnic", "Leopard", "Learning", "Pardodicid", "Catlike", "License", "Shepard"],
				"correctAnswer": "Leopard",
				"audioFileNames": {
					"prompts": ["NDWR_Leopard"]
				},
				"discrimination": 2.19420707784364
			}, {
				"itemId": 5049,
				"itemLabel": "WR_100",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.05877174807306,
				"choices": ["Ancestry", "Roommate", "Ancient", "Older", "Orient", "Antonyms", "Hympcient"],
				"correctAnswer": "Ancient",
				"audioFileNames": {
					"prompts": ["NDWR_Ancient"]
				},
				"discrimination": 1.89540620680784
			}, {
				"itemId": 5050,
				"itemLabel": "WR_101",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.210875077123423,
				"choices": ["Business", "Busy", "Nesbick", "Profession", "Butternut", "Fragment", "Dizziness"],
				"correctAnswer": "Business",
				"audioFileNames": {
					"prompts": ["NDWR_Business"]
				},
				"discrimination": 1.82990369434352
			}, {
				"itemId": 5051,
				"itemLabel": "WR_102",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.839546749990991,
				"choices": ["College", "Abrupt", "Collect", "Goglectic", "Colleague", "Coworker", "Intrigue"],
				"correctAnswer": "Colleague",
				"audioFileNames": {
					"prompts": ["NDWR_Colleague"]
				},
				"discrimination": 1.24633504265228
			}, {
				"itemId": 5052,
				"itemLabel": "WR_103",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": -0.105494162901682,
				"choices": ["Magazine", "Newton", "Tabloid", "Weekly", "Never", "Newspaper", "Newtonspur"],
				"correctAnswer": "Newspaper",
				"audioFileNames": {
					"prompts": ["NDWR_Newspaper"]
				},
				"discrimination": 2.16962966651085
			}, {
				"itemId": 5053,
				"itemLabel": "WR_104",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.905621400754464,
				"choices": ["Arrowdynamite", "Adolescent", "Apple", "Aerospace", "Brandishing", "Aerodynamic", "Misanthrope"],
				"correctAnswer": "Aerodynamic",
				"audioFileNames": {
					"prompts": ["NDWR_Aerodynamic"]
				},
				"discrimination": 0.558818059582945
			}, {
				"itemId": 5054,
				"itemLabel": "WR_105",
				"itemType": "wordRecognitionNonDecodable",
				"itemClass": "active",
				"difficulty": 0.925515830036529,
				"choices": ["Aclecknoge", "Achievement", "Layer", "Accept", "Acknowledge", "Analogy", "Advantage"],
				"correctAnswer": "Acknowledge",
				"audioFileNames": {
					"prompts": ["NDWR_Acknowledge"]
				},
				"discrimination": 0.966165774089779
			}]
		},
		"words2Sentence": {
			"trainingItemIds": [6000, 6001],
			"numTrainingItemRetries": 1,
			"items": [{
				"itemId": 6000,
				"itemLabel": "W2S_1",
				"itemType": "words2Sentence",
				"itemClass": "training",
				"difficulty": 0.21,
				"choices": ["I", "am", "happy."],
				"correctAnswer": "I am happy.",
				"audioFileNames": {
					"instructions": ["W2S_Instructions"],
					"prompts": ["W2S_T1_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["W2S_T1_Feedback_Correct"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["W2S_T1_Feedback_incorrect", "Lets_try_it_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["W2S_T1_Feedback_Correct"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["Lets_try_another_one"]
					}]
				}
			}, {
				"itemId": 6001,
				"itemLabel": "W2S_2",
				"itemType": "words2Sentence",
				"itemClass": "training",
				"difficulty": 0.21,
				"choices": ["Give", "me", "more."],
				"correctAnswer": "Give me more.",
				"audioFileNames": {
					"instructions": [],
					"prompts": ["W2S_T2_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["W2S_T2_Feedback_correct", "W2S_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["W2S_T2_Feedback_incorrect", "Lets_try_it_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["W2S_T2_Feedback_correct", "W2S_Training_FinalPrompt"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["W2S_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["W2S_T2_Feedback_correct", "W2S_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["W2S_T2_Feedback_incorrect", "W2S_Training_FinalPrompt"]
					}]
				}
			}, {
				"itemId": 6002,
				"itemLabel": "W2S_3",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -1.07553701214029,
				"choices": ["I", "like", "apples."],
				"correctAnswer": "I like apples.",
				"audioFileNames": null,
				"discrimination": 1.52299089717154
			}, {
				"itemId": 6003,
				"itemLabel": "W2S_4",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.820085232588988,
				"choices": ["She", "hates", "carrots."],
				"correctAnswer": "She hates carrots.",
				"audioFileNames": null,
				"discrimination": 1.85563384970082
			}, {
				"itemId": 6004,
				"itemLabel": "W2S_5",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.314905875216315,
				"choices": ["They", "ran", "here."],
				"correctAnswer": "They ran here.",
				"audioFileNames": null,
				"discrimination": 2.3534633877012
			}, {
				"itemId": 6005,
				"itemLabel": "W2S_6",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -1.07675341880647,
				"choices": ["I", "am", "smart."],
				"correctAnswer": "I am smart.",
				"audioFileNames": null,
				"discrimination": 1.52080117386578
			}, {
				"itemId": 6006,
				"itemLabel": "W2S_7",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -1.22945865426355,
				"choices": ["I", "like", "boats."],
				"correctAnswer": "I like boats.",
				"audioFileNames": null,
				"discrimination": 1.69651603242751
			}, {
				"itemId": 6007,
				"itemLabel": "W2S_8",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.872969367781866,
				"choices": ["I", "am", "lost."],
				"correctAnswer": "I am lost.",
				"audioFileNames": null,
				"discrimination": 1.3301989657591
			}, {
				"itemId": 6008,
				"itemLabel": "W2S_9",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.0598378376409869,
				"choices": ["See", "the", "pig."],
				"correctAnswer": "See the pig.",
				"audioFileNames": null,
				"discrimination": 1.96765756501888
			}, {
				"itemId": 6009,
				"itemLabel": "W2S_10",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.0682320396400575,
				"choices": ["Tap", "the", "box."],
				"correctAnswer": "Tap the box.",
				"audioFileNames": null,
				"discrimination": 1.55162789667948
			}, {
				"itemId": 6010,
				"itemLabel": "W2S_11",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.213550652653734,
				"choices": ["Pet", "the", "cat."],
				"correctAnswer": "Pet the cat.",
				"audioFileNames": null,
				"discrimination": 0.628389795358865
			}, {
				"itemId": 6011,
				"itemLabel": "W2S_12",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.00703042342808619,
				"choices": ["Snails", "are", "slimy."],
				"correctAnswer": "Snails are slimy.",
				"audioFileNames": null,
				"discrimination": 1.21287453570551
			}, {
				"itemId": 6012,
				"itemLabel": "W2S_13",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.374919962633928,
				"choices": ["She", "is", "pretty."],
				"correctAnswer": "She is pretty.",
				"audioFileNames": null,
				"discrimination": 2.15372794643526
			}, {
				"itemId": 6013,
				"itemLabel": "W2S_14",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.500078501780473,
				"choices": ["She", "jumped", "high."],
				"correctAnswer": "She jumped high.",
				"audioFileNames": null,
				"discrimination": 1.56301388608648
			}, {
				"itemId": 6014,
				"itemLabel": "W2S_15",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.0914680748164935,
				"choices": ["Roses", "are", "red."],
				"correctAnswer": "Roses are red.",
				"audioFileNames": null,
				"discrimination": 1.20152002729987
			}, {
				"itemId": 6015,
				"itemLabel": "W2S_16",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.148591657701029,
				"choices": ["Leaves", "are", "green."],
				"correctAnswer": "Leaves are green.",
				"audioFileNames": null,
				"discrimination": 1.51413610281723
			}, {
				"itemId": 6016,
				"itemLabel": "W2S_17",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.141031682013537,
				"choices": ["I", "want", "more", "juice."],
				"correctAnswer": "I want more juice.",
				"audioFileNames": null,
				"discrimination": 3.28201501278961
			}, {
				"itemId": 6017,
				"itemLabel": "W2S_18",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.141006133002269,
				"choices": ["Do", "you", "see", "me?"],
				"correctAnswer": "Do you see me?",
				"audioFileNames": null,
				"discrimination": 1.7315844258269
			}, {
				"itemId": 6018,
				"itemLabel": "W2S_19",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.347946599549421,
				"choices": ["She", "has", "a", "pet."],
				"correctAnswer": "She has a pet.",
				"audioFileNames": null,
				"discrimination": 2.0733950628446
			}, {
				"itemId": 6019,
				"itemLabel": "W2S_20",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.0850356864034818,
				"choices": ["How", "did", "he", "do?"],
				"correctAnswer": "How did he do?",
				"audioFileNames": null,
				"discrimination": 2.29911335166917
			}, {
				"itemId": 6020,
				"itemLabel": "W2S_21",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.456853787918515,
				"choices": ["You", "can", "go", "again."],
				"correctAnswer": "You can go again.",
				"audioFileNames": null,
				"discrimination": 1.24411641716693
			}, {
				"itemId": 6021,
				"itemLabel": "W2S_22",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.377922866405045,
				"choices": ["The", "sun", "is", "hot."],
				"correctAnswer": "The sun is hot.",
				"audioFileNames": null,
				"discrimination": 1.60335662501101
			}, {
				"itemId": 6023,
				"itemLabel": "W2S_24",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.818319457266544,
				"choices": ["A", "bug", "is", "there."],
				"correctAnswer": "A bug is there.",
				"audioFileNames": null,
				"discrimination": 3.36568967524096
			}, {
				"itemId": 6027,
				"itemLabel": "W2S_28",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.00771596136099615,
				"choices": ["The", "sun", "is", "hot."],
				"correctAnswer": "The sun is hot.",
				"audioFileNames": null,
				"discrimination": 2.50565680913197
			}, {
				"itemId": 6028,
				"itemLabel": "W2S_29",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.0986451014071347,
				"choices": ["Here", "is", "a", "box."],
				"correctAnswer": "Here is a box.",
				"audioFileNames": null,
				"discrimination": 2.50953470670795
			}, {
				"itemId": 6029,
				"itemLabel": "W2S_30",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.534118901928139,
				"choices": ["Our", "home", "is", "new."],
				"correctAnswer": "Our home is new.",
				"audioFileNames": null,
				"discrimination": 1.75100909003928
			}, {
				"itemId": 6033,
				"itemLabel": "W2S_34",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.313318973178284,
				"choices": ["A", "circle", "is", "round."],
				"correctAnswer": "A circle is round.",
				"audioFileNames": null,
				"discrimination": 1.95531769393391
			}, {
				"itemId": 6035,
				"itemLabel": "W2S_36",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.619066873824906,
				"choices": ["Can", "we", "have", "more?"],
				"correctAnswer": "Can we have more?",
				"audioFileNames": null,
				"discrimination": 3.67291169159742
			}, {
				"itemId": 6036,
				"itemLabel": "W2S_37",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.579256961177886,
				"choices": ["I", "brushed", "my", "teeth."],
				"correctAnswer": "I brushed my teeth.",
				"audioFileNames": null,
				"discrimination": 5.427750724075
			}, {
				"itemId": 6039,
				"itemLabel": "W2S_40",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.0171161121109634,
				"choices": ["The", "key", "is", "at", "home."],
				"correctAnswer": "The key is at home.",
				"audioFileNames": null,
				"discrimination": 1.58720361934449
			}, {
				"itemId": 6040,
				"itemLabel": "W2S_41",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.758613158683055,
				"choices": ["Why", "does", "she", "do", "that?"],
				"correctAnswer": "Why does she do that?",
				"audioFileNames": null,
				"discrimination": 3.2667756502376
			}, {
				"itemId": 6043,
				"itemLabel": "W2S_44",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.623234644193585,
				"choices": ["I", "ran", "to", "catch", "the", "bus."],
				"correctAnswer": "I ran to catch the bus.",
				"audioFileNames": null,
				"discrimination": 1.89148798797317
			}, {
				"itemId": 6046,
				"itemLabel": "W2S_47",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.967746945245234,
				"choices": ["Whales", "swim", "in", "the", "ocean."],
				"correctAnswer": "Whales swim in the ocean.",
				"audioFileNames": null,
				"discrimination": 3.22314585845488
			}, {
				"itemId": 6049,
				"itemLabel": "W2S_50",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.664990594189753,
				"choices": ["We", "eat", "all", "of", "our", "vegetables."],
				"correctAnswer": "We eat all of our vegetables.",
				"audioFileNames": null,
				"discrimination": 1.67026694024557
			}, {
				"itemId": 6050,
				"itemLabel": "W2S_51",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.633283767014949,
				"choices": ["It", "is", "fun", "to", "fly", "kites."],
				"correctAnswer": "It is fun to fly kites.",
				"audioFileNames": null,
				"discrimination": 2.61726501830707
			}, {
				"itemId": 6052,
				"itemLabel": "W2S_53",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.162710589393754,
				"choices": ["Are", "you", "going", "home", "now?"],
				"correctAnswer": "Are you going home now?",
				"audioFileNames": null,
				"discrimination": 1.63231496141382
			}, {
				"itemId": 6053,
				"itemLabel": "W2S_54",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.892571704888662,
				"choices": ["We", "saw", "a", "fish", "in", "the", "pond."],
				"correctAnswer": "We saw a fish in the pond.",
				"audioFileNames": null,
				"discrimination": 2.20651308583351
			}, {
				"itemId": 6056,
				"itemLabel": "W2S_57",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.656533741260489,
				"choices": ["My", "puppy", "wags", "his", "tail."],
				"correctAnswer": "My puppy wags his tail.",
				"audioFileNames": null,
				"discrimination": 3.21234792991225
			}, {
				"itemId": 6059,
				"itemLabel": "W2S_60",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.09284254293266,
				"choices": ["The", "vehicle", "zoomed", "down", "the", "street."],
				"correctAnswer": "The vehicle zoomed down the street.",
				"audioFileNames": null,
				"discrimination": 3.57941421168976
			}, {
				"itemId": 6060,
				"itemLabel": "W2S_61",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.31304579289145,
				"choices": ["A", "globe", "shows", "the", "countries", "of", "the", "world."],
				"correctAnswer": "A globe shows the countries of the world.",
				"audioFileNames": null,
				"discrimination": 3.05278008172612
			}, {
				"itemId": 6061,
				"itemLabel": "W2S_62",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.920549064454468,
				"choices": ["They", "could", "have", "pizza", "for", "dinner."],
				"correctAnswer": "They could have pizza for dinner.",
				"audioFileNames": null,
				"discrimination": 2.2077831488679
			}, {
				"itemId": 6062,
				"itemLabel": "W2S_63",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.17548352574933,
				"choices": ["The", "sled", "slid", "over", "the", "slope."],
				"correctAnswer": "The sled slid over the slope.",
				"audioFileNames": null,
				"discrimination": 2.52366139803413
			}, {
				"itemId": 6063,
				"itemLabel": "W2S_64",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.01874756718293,
				"choices": ["I", "can", "give", "the", "map", "to", "the", "man."],
				"correctAnswer": "I can give the map to the man.",
				"audioFileNames": null,
				"discrimination": 1.80379858079859
			}, {
				"itemId": 6064,
				"itemLabel": "W2S_65",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.713533010852201,
				"choices": ["The", "kid", "hopped", "over", "the", "box."],
				"correctAnswer": "The kid hopped over the box.",
				"audioFileNames": null,
				"discrimination": 2.12972433012228
			}, {
				"itemId": 6065,
				"itemLabel": "W2S_66",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.38059747335373,
				"choices": ["See", "if", "the", "vase", "is", "on", "the", "table."],
				"correctAnswer": "See if the vase is on the table.",
				"audioFileNames": null,
				"discrimination": 2.23808334678633
			}, {
				"itemId": 6066,
				"itemLabel": "W2S_67",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.752313036025502,
				"choices": ["Some", "milk", "spilled", "on", "the", "rug."],
				"correctAnswer": "Some milk spilled on the rug.",
				"audioFileNames": null,
				"discrimination": 2.41546857215316
			}, {
				"itemId": 6067,
				"itemLabel": "W2S_68",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.660324620178913,
				"choices": ["Dad", "can", "fix", "the", "rake."],
				"correctAnswer": "Dad can fix the rake.",
				"audioFileNames": null,
				"discrimination": 2.53664577881997
			}, {
				"itemId": 6069,
				"itemLabel": "W2S_70",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.0189538039461656,
				"choices": ["There", "is", "a", "little", "bug."],
				"correctAnswer": "There is a little bug.",
				"audioFileNames": null,
				"discrimination": 1.89887779914152
			}, {
				"itemId": 6070,
				"itemLabel": "W2S_71",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.842598542537264,
				"choices": ["Yes,", "we", "will", "go", "with", "them."],
				"correctAnswer": "Yes, we will go with them.",
				"audioFileNames": null,
				"discrimination": 3.45543176812139
			}, {
				"itemId": 6071,
				"itemLabel": "W2S_72",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.322230768364482,
				"choices": ["I", "didn't", "finish", "my", "work."],
				"correctAnswer": "I didn't finish my work.",
				"audioFileNames": null,
				"discrimination": 2.72816878306083
			}, {
				"itemId": 6074,
				"itemLabel": "W2S_75",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.737352900005291,
				"choices": ["The", "fly", "landed", "on", "his", "head."],
				"correctAnswer": "The fly landed on his head.",
				"audioFileNames": null,
				"discrimination": 2.6652500432627
			}, {
				"itemId": 6076,
				"itemLabel": "W2S_77",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.709079623717212,
				"choices": ["We", "got", "candy", "for", "being", "good."],
				"correctAnswer": "We got candy for being good.",
				"audioFileNames": null,
				"discrimination": 2.36056996030732
			}, {
				"itemId": 6077,
				"itemLabel": "W2S_78",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.28540405814619,
				"choices": ["They", "want", "to", "talk", "to", "one", "another."],
				"correctAnswer": "They want to talk to one another.",
				"audioFileNames": null,
				"discrimination": 1.99154663597512
			}, {
				"itemId": 6078,
				"itemLabel": "W2S_79",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.888809895163655,
				"choices": ["Can", "I", "have", "a", "quarter?"],
				"correctAnswer": "Can I have a quarter?",
				"audioFileNames": null,
				"discrimination": 2.56298274908561
			}, {
				"itemId": 6079,
				"itemLabel": "W2S_80",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.580471475324691,
				"choices": ["We", "have", "to", "walk", "to", "the", "field."],
				"correctAnswer": "We have to walk to the field.",
				"audioFileNames": null,
				"discrimination": 1.7045424421935
			}, {
				"itemId": 6080,
				"itemLabel": "W2S_81",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.12173170902076,
				"choices": ["I", "know", "every", "word", "on", "my", "list."],
				"correctAnswer": "I know every word on my list.",
				"audioFileNames": null,
				"discrimination": 2.46324360074611
			}, {
				"itemId": 6082,
				"itemLabel": "W2S_83",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.0046839091224,
				"choices": ["He", "will", "be", "in", "the", "school", "play."],
				"correctAnswer": "He will be in the school play.",
				"audioFileNames": null,
				"discrimination": 2.41611178632183
			}, {
				"itemId": 6084,
				"itemLabel": "W2S_85",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.617372856850543,
				"choices": ["We", "waited", "at", "the", "bus", "stop."],
				"correctAnswer": "We waited at the bus stop.",
				"audioFileNames": null,
				"discrimination": 4.29554041294802
			}, {
				"itemId": 6086,
				"itemLabel": "W2S_87",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 0.647421341229918,
				"choices": ["The", "arctic", "is", "always", "cold."],
				"correctAnswer": "The arctic is always cold.",
				"audioFileNames": null,
				"discrimination": 2.50590809492292
			}, {
				"itemId": 6087,
				"itemLabel": "W2S_88",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": -0.322704823082673,
				"choices": ["Where", "is", "the", "small", "pinecone?"],
				"correctAnswer": "Where is the small pinecone?",
				"audioFileNames": null,
				"discrimination": 1.17900672556372
			}, {
				"itemId": 6088,
				"itemLabel": "W2S_89",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.15708470084253,
				"choices": ["The", "answer", "was", "marked", "true."],
				"correctAnswer": "The answer was marked true.",
				"audioFileNames": null,
				"discrimination": 0.885381750609071
			}, {
				"itemId": 6089,
				"itemLabel": "W2S_90",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.78689691722164,
				"choices": ["Many", "different", "people", "live", "in", "the", "world."],
				"correctAnswer": "Many different people live in the world.",
				"audioFileNames": null,
				"discrimination": 1.27583812124565
			}, {
				"itemId": 6090,
				"itemLabel": "W2S_91",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.23349260709972,
				"choices": ["The", "old", "clock", "chimes", "every", "hour."],
				"correctAnswer": "The old clock chimes every hour.",
				"audioFileNames": null,
				"discrimination": 2.28498802667408
			}, {
				"itemId": 6091,
				"itemLabel": "W2S_92",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.7904299240681,
				"choices": ["The", "smell", "didn't", "keep", "him", "from", "going", "in", "the", "room."],
				"correctAnswer": "The smell didn't keep him from going in the room.",
				"audioFileNames": null,
				"discrimination": 3.45736594028059
			}, {
				"itemId": 6092,
				"itemLabel": "W2S_93",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.8362996615725,
				"choices": ["Light", "produces", "energy", "to", "help", "plants", "grow."],
				"correctAnswer": "Light produces energy to help plants grow.",
				"audioFileNames": null,
				"discrimination": 3.26862169419526
			}, {
				"itemId": 6093,
				"itemLabel": "W2S_94",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.57240902799728,
				"choices": ["We", "work", "as", "a", "team", "to", "get", "the", "job", "done."],
				"correctAnswer": "We work as a team to get the job done.",
				"audioFileNames": null,
				"discrimination": 2.3135306770296
			}, {
				"itemId": 6095,
				"itemLabel": "W2S_96",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.24377008434943,
				"choices": ["The", "people", "said", "the", "weather", "was", "beautiful."],
				"correctAnswer": "The people said the weather was beautiful.",
				"audioFileNames": null,
				"discrimination": 2.50450089086527
			}, {
				"itemId": 6096,
				"itemLabel": "W2S_97",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.07489546577797,
				"choices": ["The", "shopkeeper", "said", "they", "could", "pick", "any", "kitten", "from", "the", "litter."],
				"correctAnswer": "The shopkeeper said they could pick any kitten from the litter.",
				"audioFileNames": null,
				"discrimination": 3.04472064296136
			}, {
				"itemId": 6097,
				"itemLabel": "W2S_98",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.66478355083211,
				"choices": ["We", "stopped", "to", "thank", "them", "for", "the", "present."],
				"correctAnswer": "We stopped to thank them for the present.",
				"audioFileNames": null,
				"discrimination": 2.86169970300708
			}, {
				"itemId": 6098,
				"itemLabel": "W2S_99",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.98778489985429,
				"choices": ["She", "helped", "me", "carry", "the", "heavy", "bottles", "of", "water", "to", "the", "room."],
				"correctAnswer": "She helped me carry the heavy bottles of water to the room.",
				"audioFileNames": null,
				"discrimination": 2.50418562293545
			}, {
				"itemId": 6099,
				"itemLabel": "W2S_100",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.81293717618445,
				"choices": ["When", "the", "group", "saw", "the", "vehicle", "pass,", "they", "moved", "into", "the", "street."],
				"correctAnswer": "When the group saw the vehicle pass, they moved into the street.",
				"audioFileNames": null,
				"discrimination": 1.91804721033283
			}, {
				"itemId": 6100,
				"itemLabel": "W2S_101",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.56144652778623,
				"choices": ["Someone", "opened", "the", "door", "and", "let", "the", "chicken", "in", "the", "house."],
				"correctAnswer": "Someone opened the door and let the chicken in the house.",
				"audioFileNames": null,
				"discrimination": 2.7149890317042
			}, {
				"itemId": 6101,
				"itemLabel": "W2S_102",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.25241641620185,
				"choices": ["She", "wants", "to", "be", "a", "ballerina", "when", "she", "grows", "up."],
				"correctAnswer": "She wants to be a ballerina when she grows up.",
				"audioFileNames": null,
				"discrimination": 2.51425675088085
			}, {
				"itemId": 6102,
				"itemLabel": "W2S_103",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.44542601393135,
				"choices": ["I", "went", "to", "the", "dentist", "today", "because", "I", "had", "a", "cavity."],
				"correctAnswer": "I went to the dentist today because I had a cavity.",
				"audioFileNames": null,
				"discrimination": 2.44164760510336
			}, {
				"itemId": 6103,
				"itemLabel": "W2S_104",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.90532246321087,
				"choices": ["I", "jumped", "off", "of", "the", "diving", "board", "into", "the", "swimming", "pool."],
				"correctAnswer": "I jumped off of the diving board into the swimming pool.",
				"audioFileNames": null,
				"discrimination": 2.88114766893697
			}, {
				"itemId": 6104,
				"itemLabel": "W2S_105",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.78256474289067,
				"choices": ["He", "likes", "to", "eat", "a", "snack", "when", "he", "gets", "home", "from", "school."],
				"correctAnswer": "He likes to eat a snack when he gets home from school.",
				"audioFileNames": null,
				"discrimination": 3.2092642892673
			}, {
				"itemId": 6105,
				"itemLabel": "W2S_106",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.9060854411325,
				"choices": ["I", "brush", "my", "teeth", "when", "I", "wake", "up", "in", "the", "morning."],
				"correctAnswer": "I brush my teeth when I wake up in the morning.",
				"audioFileNames": null,
				"discrimination": 2.04377164391456
			}, {
				"itemId": 6106,
				"itemLabel": "W2S_107",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.1558609498104,
				"choices": ["His", "favorite", "thing", "to", "do", "is", "play", "video", "games."],
				"correctAnswer": "His favorite thing to do is play video games.",
				"audioFileNames": null,
				"discrimination": 2.55011546166926
			}, {
				"itemId": 6107,
				"itemLabel": "W2S_108",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.30459923731808,
				"choices": ["Dinosaurs", "were", "reptiles", "that", "roamed", "the", "earth", "millions", "of", "years", "ago"],
				"correctAnswer": "Dinosaurs were reptiles that roamed the earth millions of years ago",
				"audioFileNames": null,
				"discrimination": 3.00888840882475
			}, {
				"itemId": 6108,
				"itemLabel": "W2S_109",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.85090565142307,
				"choices": ["Monarch", "butterflies", "migrate", "just", "like", "birds", "do."],
				"correctAnswer": "Monarch butterflies migrate just like birds do.",
				"audioFileNames": null,
				"discrimination": 3.506798120538
			}, {
				"itemId": 6109,
				"itemLabel": "W2S_110",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.57984716772813,
				"choices": ["Camels", "can", "go", "weeks", "without", "drinking", "any", "water."],
				"correctAnswer": "Camels can go weeks without drinking any water.",
				"audioFileNames": null,
				"discrimination": 3.23291375415061
			}, {
				"itemId": 6110,
				"itemLabel": "W2S_111",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.28838744693382,
				"choices": ["Hermit", "crabs", "will", "exchange", "their", "shells", "for", "bigger", "ones", "as", "they", "grow."],
				"correctAnswer": "Hermit crabs will exchange their shells for bigger ones as they grow.",
				"audioFileNames": null,
				"discrimination": 3.31641002783363
			}, {
				"itemId": 6111,
				"itemLabel": "W2S_112",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.74986879757289,
				"choices": ["The", "students", "jumped", "in", "jubilation", "after", "winning", "the", "tournament."],
				"correctAnswer": "The students jumped in jubilation after winning the tournament.",
				"audioFileNames": null,
				"discrimination": 2.38869467543491
			}, {
				"itemId": 6112,
				"itemLabel": "W2S_113",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.61942982504893,
				"choices": ["We", "washed", "my", "Dad's", "car", "until", "it", "was", "completely", "clean."],
				"correctAnswer": "We washed my Dad's car until it was completely clean.",
				"audioFileNames": null,
				"discrimination": 2.25005555116324
			}, {
				"itemId": 6113,
				"itemLabel": "W2S_114",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.11272608389573,
				"choices": ["I", "want", "to", "be", "a", "fireman", "when", "I", "grow", "up."],
				"correctAnswer": "I want to be a fireman when I grow up.",
				"audioFileNames": null,
				"discrimination": 1.75976574439935
			}, {
				"itemId": 6114,
				"itemLabel": "W2S_115",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.06426987389657,
				"choices": ["We", "camped", "next", "to", "a", "beautiful", "lake."],
				"correctAnswer": "We camped next to a beautiful lake.",
				"audioFileNames": null,
				"discrimination": 1.90395023250759
			}, {
				"itemId": 6115,
				"itemLabel": "W2S_116",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.38593838145189,
				"choices": ["She", "is", "eating", "a", "giant", "cookie", "with", "sprinkles", "on", "top."],
				"correctAnswer": "She is eating a giant cookie with sprinkles on top.",
				"audioFileNames": null,
				"discrimination": 1.83621446890779
			}, {
				"itemId": 6116,
				"itemLabel": "W2S_117",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.06348764216723,
				"choices": ["He", "can", "run", "across", "the", "playground", "faster", "than", "his", "friend."],
				"correctAnswer": "He can run across the playground faster than his friend.",
				"audioFileNames": null,
				"discrimination": 1.48119036599955
			}, {
				"itemId": 6117,
				"itemLabel": "W2S_118",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.87083395085639,
				"choices": ["The", "ants", "explore", "the", "forest", "searching", "for", "food."],
				"correctAnswer": "The ants explore the forest searching for food.",
				"audioFileNames": null,
				"discrimination": 2.8918163165467
			}, {
				"itemId": 6118,
				"itemLabel": "W2S_119",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.66681920097406,
				"choices": ["Swallows", "can", "fly", "more", "than", "seven", "thousand", "miles", "in", "thirty", "days."],
				"correctAnswer": "Swallows can fly more than seven thousand miles in thirty days.",
				"audioFileNames": null,
				"discrimination": 1.55436756314098
			}, {
				"itemId": 6119,
				"itemLabel": "W2S_120",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.87119162791847,
				"choices": ["Monarch", "butterflies", "change", "from", "caterpillars", "into", "a", "chrysalis."],
				"correctAnswer": "Monarch butterflies change from caterpillars into a chrysalis.",
				"audioFileNames": null,
				"discrimination": 1.60073002445131
			}, {
				"itemId": 6120,
				"itemLabel": "W2S_121",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.00231992571891,
				"choices": ["Whooping", "cranes", "are", "majestic", "birds", "with", "a", "wide", "wing", "span."],
				"correctAnswer": "Whooping cranes are majestic birds with a wide wing span.",
				"audioFileNames": null,
				"discrimination": 2.18334192716451
			}, {
				"itemId": 6121,
				"itemLabel": "W2S_122",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.11438482088711,
				"choices": ["Gravity", "is", "a", "strong", "force", "that", "allows", "people", "to", "stay", "on", "the", "earth."],
				"correctAnswer": "Gravity is a strong force that allows people to stay on the earth.",
				"audioFileNames": null,
				"discrimination": 2.76319163527005
			}, {
				"itemId": 6122,
				"itemLabel": "W2S_123",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.23325056523151,
				"choices": ["The", "three", "pigs", "had", "a", "silly", "conversation", "with", "the", "big,", "bad", "wolf."],
				"correctAnswer": "The three pigs had a silly conversation with the big, bad wolf.",
				"audioFileNames": null,
				"discrimination": 1.14579771890702
			}, {
				"itemId": 6123,
				"itemLabel": "W2S_124",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.20542234613632,
				"choices": ["My", "friend", "wanted", "to", "accompany", "me", "to", "the", "talent", "show."],
				"correctAnswer": "My friend wanted to accompany me to the talent show.",
				"audioFileNames": null,
				"discrimination": 1.82419068611354
			}, {
				"itemId": 6124,
				"itemLabel": "W2S_125",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.48850931772356,
				"choices": ["The", "hummingbird", "drank", "nectar", "from", "the", "flower."],
				"correctAnswer": "The hummingbird drank nectar from the flower.",
				"audioFileNames": null,
				"discrimination": 2.24182302013651
			}, {
				"itemId": 6125,
				"itemLabel": "W2S_126",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.13174587855079,
				"choices": ["An", "intelligent", "individual", "would", "have", "a", "better", "attitude."],
				"correctAnswer": "An intelligent individual would have a better attitude.",
				"audioFileNames": null,
				"discrimination": 2.49963971115507
			}, {
				"itemId": 6126,
				"itemLabel": "W2S_127",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.59568212377937,
				"choices": ["Throughout", "the", "entire", "ordeal,", "her", "wisdom", "prevailed."],
				"correctAnswer": "Throughout the entire ordeal, her wisdom prevailed.",
				"audioFileNames": null,
				"discrimination": 2.09791926651304
			}, {
				"itemId": 6127,
				"itemLabel": "W2S_128",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.44263115793591,
				"choices": ["Each", "student", "was", "responsible", "for", "their", "own", "schedule."],
				"correctAnswer": "Each student was responsible for their own schedule.",
				"audioFileNames": null,
				"discrimination": 3.11419347854796
			}, {
				"itemId": 6128,
				"itemLabel": "W2S_129",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.10449198423425,
				"choices": ["Although", "we", "were", "behind,", "we", "were", "able", "to", "finish", "the", "race."],
				"correctAnswer": "Although we were behind, we were able to finish the race.",
				"audioFileNames": null,
				"discrimination": 2.41413181909662
			}, {
				"itemId": 6129,
				"itemLabel": "W2S_130",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.00904151897283,
				"choices": ["The", "monster", "plunged", "into", "the", "marsh."],
				"correctAnswer": "The monster plunged into the marsh.",
				"audioFileNames": null,
				"discrimination": 2.28057861830298
			}, {
				"itemId": 6130,
				"itemLabel": "W2S_131",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.14700107097855,
				"choices": ["Sometimes", "grandfather", "can", "be", "forgetful."],
				"correctAnswer": "Sometimes grandfather can be forgetful.",
				"audioFileNames": null,
				"discrimination": 1.91889937132064
			}, {
				"itemId": 6131,
				"itemLabel": "W2S_132",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.98634667826013,
				"choices": ["The", "astronauts", "watched", "the", "rocket", "launch", "from", "the", "pad."],
				"correctAnswer": "The astronauts watched the rocket launch from the pad.",
				"audioFileNames": null,
				"discrimination": 1.19232638051473
			}, {
				"itemId": 6132,
				"itemLabel": "W2S_133",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.9619443548443,
				"choices": ["The", "minister", "began", "to", "doze", "after", "the", "choir", "performed", "their", "first", "song."],
				"correctAnswer": "The minister began to doze after the choir performed their first song.",
				"audioFileNames": null,
				"discrimination": 2.01935443682774
			}, {
				"itemId": 6133,
				"itemLabel": "W2S_134",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.60879161855902,
				"choices": ["The", "individual", "struggled", "to", "confess", "to", "the", "cowardly", "act."],
				"correctAnswer": "The individual struggled to confess to the cowardly act.",
				"audioFileNames": null,
				"discrimination": 1.11765351906698
			}, {
				"itemId": 6134,
				"itemLabel": "W2S_135",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.99848992341168,
				"choices": ["The", "veteran", "was", "elated", "when", "they", "unfurled", "the", "American", "flag."],
				"correctAnswer": "The veteran was elated when they unfurled the American flag.",
				"audioFileNames": null,
				"discrimination": 1.20386596588425
			}, {
				"itemId": 6135,
				"itemLabel": "W2S_136",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.30078181802005,
				"choices": ["When", "I", "got", "home", "from", "school,", "I", "ran", "to", "the", "television", "to", "watch", "my", "favorite", "show."],
				"correctAnswer": "When I got home from school, I ran to the television to watch my favorite show.",
				"audioFileNames": null,
				"discrimination": 3.09038122113943
			}, {
				"itemId": 6136,
				"itemLabel": "W2S_137",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.79573832002328,
				"choices": ["When", "I", "was", "little,", "my", "favorite", "thing", "to", "do", "was", "chase", "my", "dog", "around", "the", "yard."],
				"correctAnswer": "When I was little, my favorite thing to do was chase my dog around the yard.",
				"audioFileNames": null,
				"discrimination": 1.79962014704739
			}, {
				"itemId": 6137,
				"itemLabel": "W2S_138",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.64453439361241,
				"choices": ["Instead", "of", "eating,", "butterflies", "get", "their", "nourishment", "from", "drinking."],
				"correctAnswer": "Instead of eating, butterflies get their nourishment from drinking.",
				"audioFileNames": null,
				"discrimination": 1.31939814045842
			}, {
				"itemId": 6138,
				"itemLabel": "W2S_139",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.26370869554434,
				"choices": ["The", "character", "in", "the", "novel", "was", "an", "aggressive", "antagonist."],
				"correctAnswer": "The character in the novel was an aggressive antagonist.",
				"audioFileNames": null,
				"discrimination": 1.48337140051446
			}, {
				"itemId": 6139,
				"itemLabel": "W2S_140",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.5176557097057,
				"choices": ["I", "loaned", "my", "friend", "money", "to", "get", "a", "medium", "rare", "hamburger."],
				"correctAnswer": "I loaned my friend money to get a medium rare hamburger.",
				"audioFileNames": null,
				"discrimination": 1.54025868217529
			}, {
				"itemId": 6140,
				"itemLabel": "W2S_141",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 9.21908656987981,
				"choices": ["The", "professor's", "monotonous", "tone", "made", "him", "an", "inept", "communicator."],
				"correctAnswer": "The professor's monotonous tone made him an inept communicator.",
				"audioFileNames": null,
				"discrimination": 0.460404645201005
			}, {
				"itemId": 6141,
				"itemLabel": "W2S_142",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 4.66784056799848,
				"choices": ["His", "irresponsibility", "contributed", "to", "the", "company", "going", "bankrupt."],
				"correctAnswer": "His irresponsibility contributed to the company going bankrupt.",
				"audioFileNames": null,
				"discrimination": 1.21784661111984
			}, {
				"itemId": 6142,
				"itemLabel": "W2S_143",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 6.46994408946583,
				"choices": ["The", "annoying", "woman", "who", "was", "hovering", "around", "aggravated", "the", "couple."],
				"correctAnswer": "The annoying woman who was hovering around aggravated the couple.",
				"audioFileNames": null,
				"discrimination": 1.12894553124178
			}, {
				"itemId": 6143,
				"itemLabel": "W2S_144",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.49291937384445,
				"choices": ["The", "policeman", "ordered", "them", "to", "vacate", "the", "premises."],
				"correctAnswer": "The policeman ordered them to vacate the premises.",
				"audioFileNames": null,
				"discrimination": 1.27178087544281
			}, {
				"itemId": 6144,
				"itemLabel": "W2S_145",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.39271954609027,
				"choices": ["The", "lawyer", "interpreted", "the", "document", "for", "the", "immigrant."],
				"correctAnswer": "The lawyer interpreted the document for the immigrant.",
				"audioFileNames": null,
				"discrimination": 1.3465454142699
			}, {
				"itemId": 6145,
				"itemLabel": "W2S_146",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 1.93152934781244,
				"choices": ["His", "woe", "was", "almost", "beyond", "unbearable."],
				"correctAnswer": "His woe was almost beyond unbearable.",
				"audioFileNames": null,
				"discrimination": 1.51049939968726
			}, {
				"itemId": 6146,
				"itemLabel": "W2S_147",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 4.12158498988675,
				"choices": ["The", "people", "challenged", "him", "to", "overpower", "the", "alligator", "without", "endangering", "everyone", "else."],
				"correctAnswer": "The people challenged him to overpower the alligator without endangering everyone else.",
				"audioFileNames": null,
				"discrimination": 1.80641220877547
			}, {
				"itemId": 6147,
				"itemLabel": "W2S_148",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 5.01897661303966,
				"choices": ["The", "student", "was", "nonchalant", "when", "the", "principal", "suspended", "him."],
				"correctAnswer": "The student was nonchalant when the principal suspended him.",
				"audioFileNames": null,
				"discrimination": 0.744304620529428
			}, {
				"itemId": 6148,
				"itemLabel": "W2S_149",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 2.45010627655472,
				"choices": ["We", "didn't", "anticipate", "the", "gentleman", "being", "so", "benevolent."],
				"correctAnswer": "We didn't anticipate the gentleman being so benevolent.",
				"audioFileNames": null,
				"discrimination": 2.77454344362574
			}, {
				"itemId": 6149,
				"itemLabel": "W2S_150",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 26.6740242266778,
				"choices": ["The", "sheriff", "said", "the", "assailant", "was", "in", "custody", "downtown."],
				"correctAnswer": "The sheriff said the assailant was in custody downtown.",
				"audioFileNames": null,
				"discrimination": 0.114810458555857
			}, {
				"itemId": 6150,
				"itemLabel": "W2S_151",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.10865216490543,
				"choices": ["The", "miners", "worked", "to", "excavate", "the", "minerals", "from", "the", "valley."],
				"correctAnswer": "The miners worked to excavate the minerals from the valley.",
				"audioFileNames": null,
				"discrimination": 1.35377322307647
			}, {
				"itemId": 6151,
				"itemLabel": "W2S_152",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 4.02094031589407,
				"choices": ["The", "athlete", "was", "surprised", "at", "how", "fatigued", "the", "run", "made", "him."],
				"correctAnswer": "The athlete was surprised at how fatigued the run made him.",
				"audioFileNames": null,
				"discrimination": 1.49949379103036
			}, {
				"itemId": 6152,
				"itemLabel": "W2S_153",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 3.31002792095432,
				"choices": ["Some", "species", "of", "octopus", "can", "change", "their", "color", "to", "blend", "into", "their", "surroundings."],
				"correctAnswer": "Some species of octopus can change their color to blend into their surroundings.",
				"audioFileNames": null,
				"discrimination": 1.47122681423183
			}, {
				"itemId": 6153,
				"itemLabel": "W2S_154",
				"itemType": "words2Sentence",
				"itemClass": "active",
				"difficulty": 6.15211034774921,
				"choices": ["The", "pedestrian", "pushed", "the", "buggy", "through", "the", "intersection", "in", "the", "blistering", "December", "weather."],
				"correctAnswer": "The pedestrian pushed the buggy through the intersection in the blistering December weather.",
				"audioFileNames": null,
				"discrimination": 1.01558649519365
			}]
		},
		"sentences2Paragraph": {
			"trainingItemIds": [7000, 7001],
			"numTrainingItemRetries": 1,
			"items": [{
				"itemId": 7000,
				"itemLabel": "S2P_1",
				"itemType": "sentences2Paragraph",
				"itemClass": "training",
				"difficulty": 1.403,
				"choices": ["First, we packed our lunches.", "Then we put on our coats.", "Finally, we got in the car."],
				"correctAnswer": "First, we packed our lunches. Then we put on our coats. Finally, we got in the car.",
				"audioFileNames": {
					"instructions": ["S2P_Instructions"],
					"prompts": ["S2P_T1_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["S2P_T1_Feedback_correct"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["S2P_T1_Feedback_incorrect", "Lets_try_it_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["S2P_T1_Feedback_correct"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["Lets_try_another_one"]
					}]
				}
			}, {
				"itemId": 7001,
				"itemLabel": "S2P_2",
				"itemType": "sentences2Paragraph",
				"itemClass": "training",
				"difficulty": 1.403,
				"choices": ["First, I looked in the mirror.", "Next, I brushed my hair.", "Finally, I was ready to go."],
				"correctAnswer": "First, I looked in the mirror. Next, I brushed my hair. Finally, I was ready to go.",
				"audioFileNames": {
					"instructions": [],
					"prompts": ["S2P_T2_Prompt"],
					"feedback": [{
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": true,
						"feedback": ["S2P_T2_Feedback_correct", "S2P_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 1,
						"correct": false,
						"feedback": ["S2P_T2_Feedback_incorrect", "Lets_try_it_again"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["S2P_T2_Feedback_correct", "S2P_Training_FinalPrompt"]
					}, {
						"tryCount": 2,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["S2P_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": true,
						"feedback": ["S2P_T2_Feedback_correct", "S2P_Training_FinalPrompt"]
					}, {
						"tryCount": 1,
						"retriesRemaining": 0,
						"correct": false,
						"feedback": ["S2P_T2_Feedback_incorrect", "S2P_Training_FinalPrompt"]
					}]
				}
			}, {
				"itemId": 7002,
				"itemLabel": "S2P_3",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": -1.439,
				"choices": ["I have a cat.", "His name is Tiger.", "Tiger likes to play."],
				"correctAnswer": "I have a cat. His name is Tiger. Tiger likes to play.",
				"audioFileNames": null
			}, {
				"itemId": 7003,
				"itemLabel": "S2P_4",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": -0.853,
				"choices": ["First I woke up.", "Next, I put on my clothes.", "Then I had breakfast."],
				"correctAnswer": "First I woke up. Next, I put on my clothes. Then I had breakfast.",
				"audioFileNames": null
			}, {
				"itemId": 7004,
				"itemLabel": "S2P_5",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": -0.082,
				"choices": ["I found a ladybug!", "It crawled up my finger.", "When it got to the top, it flew away."],
				"correctAnswer": "I found a ladybug! It crawled up my finger. When it got to the top, it flew away.",
				"audioFileNames": null
			}, {
				"itemId": 7005,
				"itemLabel": "S2P_6",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": -0.03,
				"choices": ["My Mom makes my lunch.", "Sometimes she puts a note in it.", "The note says 'I love you!'"],
				"correctAnswer": "My Mom makes my lunch. Sometimes she puts a note in it. The note says 'I love you!'",
				"audioFileNames": null
			}, {
				"itemId": 7006,
				"itemLabel": "S2P_7",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 0.04,
				"choices": ["I like to eat sandwiches.", "First, I take off the crust.", "Then, I squish it flat.", "Finally, I take a big bite."],
				"correctAnswer": "I like to eat sandwiches. First, I take off the crust. Then, I squish it flat. Finally, I take a big bite.",
				"audioFileNames": null
			}, {
				"itemId": 7007,
				"itemLabel": "S2P_8",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 0.1,
				"choices": ["Today I made a pizza.", "First, I put on the sauce.", "Next, I put on the cheese.", "Finally, I baked it in the oven."],
				"correctAnswer": "Today I made a pizza. First, I put on the sauce. Next, I put on the cheese. Finally, I baked it in the oven.",
				"audioFileNames": null
			}, {
				"itemId": 7008,
				"itemLabel": "S2P_9",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 1.341,
				"choices": ["First, I put on my swimsuit.", "Second, I grab a towel.", "Then, I jump in the car.", "Finally, mom drives me to the pool."],
				"correctAnswer": "First, I put on my swimsuit. Second, I grab a towel. Then, I jump in the car. Finally, mom drives me to the pool.",
				"audioFileNames": null
			}, {
				"itemId": 7009,
				"itemLabel": "S2P_10",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": -0.458,
				"choices": ["Today my class went to the zoo.", "There were many animals there.", "My favorite was the elephant.", "It used its trunk to pick up hay."],
				"correctAnswer": "Today my class went to the zoo. There were many animals there. My favorite was the elephant. It used its trunk to pick up hay.",
				"audioFileNames": null
			}, {
				"itemId": 7010,
				"itemLabel": "S2P_11",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": -0.1,
				"choices": ["Katy likes kitty cats.", "Her Aunt gave her a kitty for her birthday.", "Her kitty's name is Felix.", "Felix has blue eyes."],
				"correctAnswer": "Katy likes kitty cats. Her Aunt gave her a kitty for her birthday. Her kitty's name is Felix. Felix has blue eyes.",
				"audioFileNames": null
			}, {
				"itemId": 7011,
				"itemLabel": "S2P_12",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 2.921,
				"choices": ["Yesterday I got to play with my friends at the park.", "Today I get to go to swimming lessons.", "Tomorrow I am going to read with my mom."],
				"correctAnswer": "Yesterday I got to play with my friends at the park. Today I get to go to swimming lessons. Tomorrow I am going to read with my mom.",
				"audioFileNames": null
			}, {
				"itemId": 7012,
				"itemLabel": "S2P_13",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": -0.316,
				"choices": ["Today at school lunch we got to have pizza!", "I picked cheese pizza, and my friend picked pepperoni pizza.", "I hope we get to have pizza for lunch again soon."],
				"correctAnswer": "Today at school lunch we got to have pizza! I picked cheese pizza, and my friend picked pepperoni pizza. I hope we get to have pizza for lunch again soon.",
				"audioFileNames": null
			}, {
				"itemId": 7013,
				"itemLabel": "S2P_14",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 0.727,
				"choices": ["When I get home from school, I first like to eat a snack.", "After that, I do my homework.", "Today I have homework for Math and Science.", "I hope I get a good grade."],
				"correctAnswer": "When I get home from school, I first like to eat a snack. After that, I do my homework. Today I have homework for Math and Science. I hope I get a good grade.",
				"audioFileNames": null
			}, {
				"itemId": 7014,
				"itemLabel": "S2P_15",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": -0.151,
				"choices": ["This morning I asked my dad if we could go to the park and he said yes.", "At the park I played on the slide and the swings.", "I had so much fun; I can't wait to go back again!"],
				"correctAnswer": "This morning I asked my dad if we could go to the park and he said yes. At the park I played on the slide and the swings. I had so much fun; I can't wait to go back again!",
				"audioFileNames": null
			}, {
				"itemId": 7015,
				"itemLabel": "S2P_16",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 2.119,
				"choices": ["I asked my parents if I could get a dog.", "My parents thought carefully. They asked, \"Will you be responsible?\"", "Once I show them that I am responsible, I can get a dog!"],
				"correctAnswer": "I asked my parents if I could get a dog. My parents thought carefully. They asked, \"Will you be responsible?\" Once I show them that I am responsible, I can get a dog!",
				"audioFileNames": null
			}, {
				"itemId": 7016,
				"itemLabel": "S2P_17",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 0.5,
				"choices": ["Joe saw a big tent.", "It was a circus tent!", "He wanted to go inside.", "His mom told him they had to buy tickets first.", "After they bought tickets, Joe got to go inside.", "He saw lions and tigers inside the big tent.", "Joe went home feeling very happy that he got to go inside the big circus tent."],
				"correctAnswer": "Joe saw a big tent. It was a circus tent! He wanted to go inside. His mom told him they had to buy tickets first. After they bought tickets, Joe got to go inside. He saw lions and tigers inside the big tent. Joe went home feeling very happy that he got to go inside the big circus tent.",
				"audioFileNames": null
			}, {
				"itemId": 7017,
				"itemLabel": "S2P_18",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 1.048,
				"choices": ["I like to look for bugs.", "First, I look for lady bugs.", "They are black and red.", "Then, I look for spiders.", "They have eight legs.", "Finally, I look for butterflies.", "They have majestic wings."],
				"correctAnswer": "I like to look for bugs. First, I look for lady bugs. They are black and red. Then, I look for spiders. They have eight legs. Finally, I look for butterflies. They have majestic wings.",
				"audioFileNames": null
			}, {
				"itemId": 7018,
				"itemLabel": "S2P_19",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 5.007,
				"choices": ["Today we went to the park.", "I played on the swings.", "Then we had a picnic.", "I had a peanut butter and jelly sandwich.", "After lunch, we walked home.", "I had a great day at the park."],
				"correctAnswer": "Today we went to the park. I played on the swings. Then we had a picnic. I had a peanut butter and jelly sandwich. After lunch, we walked home. I had a great day at the park.",
				"audioFileNames": null
			}, {
				"itemId": 7019,
				"itemLabel": "S2P_20",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 1.047,
				"choices": ["When you make a cake the first thing you have to do is get all of the ingredients.", "The second thing you have to do is mix all of the ingredients in a bowl together.", "The third thing you do is put it in the oven to bake!"],
				"correctAnswer": "When you make a cake the first thing you have to do is get all of the ingredients. The second thing you have to do is mix all of the ingredients in a bowl together. The third thing you do is put it in the oven to bake!",
				"audioFileNames": null
			}, {
				"itemId": 7020,
				"itemLabel": "S2P_21",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 0.386,
				"choices": ["Mom said we are going to see a movie today.", "We got in the car and Mom drove to the movie theater.", "Mom bought our tickets.", "Then, we got a big tub of popcorn.", "We then went into the theater and found our seats and waited for the movie to start."],
				"correctAnswer": "Mom said we are going to see a movie today. We got in the car and Mom drove to the movie theater. Mom bought our tickets. Then, we got a big tub of popcorn. We then went into the theater and found our seats and waited for the movie to start.",
				"audioFileNames": null
			}, {
				"itemId": 7021,
				"itemLabel": "S2P_22",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 4.961,
				"choices": ["This is my dog Boomer.", "I walk him outside every morning.", "I throw the ball and he brings it back.", "He gets very hungry from playing.", "I feed him and give him plenty of water.", "Now he is tired and takes a nap."],
				"correctAnswer": "This is my dog Boomer. I walk him outside every morning. I throw the ball and he brings it back. He gets very hungry from playing. I feed him and give him plenty of water. Now he is tired and takes a nap.",
				"audioFileNames": null
			}, {
				"itemId": 7022,
				"itemLabel": "S2P_23",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 2.654,
				"choices": ["I am going to the movies with my brother Bill.", "We will purchase the movie's tickets outside of the theater.", "We will buy popcorn, candy, and soda when we get inside.", "I will eat all of the popcorn and candy during the movie."],
				"correctAnswer": "I am going to the movies with my brother Bill. We will purchase the movie's tickets outside of the theater. We will buy popcorn, candy, and soda when we get inside. I will eat all of the popcorn and candy during the movie.",
				"audioFileNames": null
			}, {
				"itemId": 7023,
				"itemLabel": "S2P_24",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 1.686,
				"choices": ["All plants start off as tiny seeds.", "These seeds find their way into the soil.", "A small sprout will grow from the seed.", "The sprout gets bigger and bigger.", "Finally, a plant is formed.", "When that plant gets big enough, it makes seeds of its own."],
				"correctAnswer": "All plants start off as tiny seeds. These seeds find their way into the soil. A small sprout will grow from the seed. The sprout gets bigger and bigger. Finally, a plant is formed. When that plant gets big enough, it makes seeds of its own.",
				"audioFileNames": null
			}, {
				"itemId": 7024,
				"itemLabel": "S2P_25",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 1.403,
				"choices": ["Paleontologists study dinosaurs.", "They learn about dinosaurs by studying fossils.", "Fossils are the preserved remains of plants or animals that died long ago.", "In order to find fossils, paleontologists have to dig in the ground."],
				"correctAnswer": "Paleontologists study dinosaurs. They learn about dinosaurs by studying fossils. Fossils are the preserved remains of plants or animals that died long ago. In order to find fossils, paleontologists have to dig in the ground.",
				"audioFileNames": null
			}, {
				"itemId": 7025,
				"itemLabel": "S2P_26",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 1.403,
				"choices": ["Life on a farm is different.", "We wake up early to get to work.", "First, we feed all of the animals.", "Next, we tend to all of the fields.", "Finally, we come in for dinner.", "After we are finished, we go to sleep."],
				"correctAnswer": "Life on a farm is different. We wake up early to get to work. First, we feed all of the animals. Next, we tend to all of the fields. Finally, we come in for dinner. After we are finished, we go to sleep.",
				"audioFileNames": null
			}, {
				"itemId": 7026,
				"itemLabel": "S2P_27",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 1.403,
				"choices": ["My teacher, Ms. Smith stayed home sick today.", "We had a substitute teacher named Mr. Brown.", "Mr. Brown was smart and funny.", "He taught my class how to make a volcano.", "The day was fun, but I hope Ms. Smith feels better tomorrow."],
				"correctAnswer": "My teacher, Ms. Smith stayed home sick today. We had a substitute teacher named Mr. Brown. Mr. Brown was smart and funny. He taught my class how to make a volcano. The day was fun, but I hope Ms. Smith feels better tomorrow.",
				"audioFileNames": null
			}, {
				"itemId": 7027,
				"itemLabel": "S2P_28",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 4.182,
				"choices": ["I went to the beach with my family.", "First, I built a sand castle.", "Then, the water knocked it down.", "Next, I went swimming.", "I saw fish in the water.", "Last, I collected shells with my mom.", "I found big shells and small shells."],
				"correctAnswer": "I went to the beach with my family. First, I built a sand castle. Then, the water knocked it down. Next, I went swimming. I saw fish in the water. Last, I collected shells with my mom. I found big shells and small shells.",
				"audioFileNames": null
			}, {
				"itemId": 7028,
				"itemLabel": "S2P_29",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 3.293,
				"choices": ["My dad is a first grade teacher.", "In the morning, he teaches the first graders how to read and do math.", "In the afternoon, he eats lunch and goes to recess with the first graders.", "At the end of the day, he comes home and plays with me.", "The next day, he wakes up and does it all over again."],
				"correctAnswer": "My dad is a first grade teacher. In the morning, he teaches the first graders how to read and do math. In the afternoon, he eats lunch and goes to recess with the first graders. At the end of the day, he comes home and plays with me. The next day, he wakes up and does it all over again.",
				"audioFileNames": null
			}, {
				"itemId": 7029,
				"itemLabel": "S2P_30",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 4.341,
				"choices": ["Most small snakes eat rats or mice.", "They can swallow a rat whole, fur and all.", "They have to unhinge their jaw so that it will fit around it.", "After they are done, they don't need to eat for a long time."],
				"correctAnswer": "Most small snakes eat rats or mice. They can swallow a rat whole, fur and all. They have to unhinge their jaw so that it will fit around it. After they are done, they don't need to eat for a long time.",
				"audioFileNames": null
			}, {
				"itemId": 7030,
				"itemLabel": "S2P_31",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 5.414,
				"choices": ["Long ago, before humans lived on earth, all of the continents were in one big chunk.", "This chunk of land was called Pangea.", "Over many millions of years, the chunk split apart into pieces.", "These pieces floated away from each other across the oceans.", "If you look closely at our continents, you can see that they almost fit together like a puzzle."],
				"correctAnswer": "Long ago, before humans lived on earth, all of the continents were in one big chunk. This chunk of land was called Pangea. Over many millions of years, the chunk split apart into pieces. These pieces floated away from each other across the oceans. If you look closely at our continents, you can see that they almost fit together like a puzzle.",
				"audioFileNames": null
			}, {
				"itemId": 7031,
				"itemLabel": "S2P_32",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 1.403,
				"choices": ["After an animal dies, its skeleton settles on the sea floor and is buried by sediment.", "The sediment surrounding the skeleton thickens and begins to turn to stone.", "The skeleton dissolves and a mold is formed.", "Minerals crystallize inside the mold and a cast is formed.", "The fossil is then exposed on Earth's surface."],
				"correctAnswer": "After an animal dies, its skeleton settles on the sea floor and is buried by sediment. The sediment surrounding the skeleton thickens and begins to turn to stone. The skeleton dissolves and a mold is formed. Minerals crystallize inside the mold and a cast is formed. The fossil is then exposed on Earth's surface.",
				"audioFileNames": null
			}, {
				"itemId": 7032,
				"itemLabel": "S2P_33",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 3.441,
				"choices": ["Ted goes to bed at six o'clock every night.", "Before he goes to bed, he brushes his teeth.", "When his teeth are shiny and clean, he puts on his pajamas.", "The pajamas with the cows and pigs on them are his favorite.", "When he is dressed, Mom comes in and gives him a big hug.", "\"Good night, Ted.\" Mom says."],
				"correctAnswer": "Ted goes to bed at six o'clock every night. Before he goes to bed, he brushes his teeth. When his teeth are shiny and clean, he puts on his pajamas. The pajamas with the cows and pigs on them are his favorite. When he is dressed, Mom comes in and gives him a big hug. \"Good night, Ted.\" Mom says.",
				"audioFileNames": null
			}, {
				"itemId": 7033,
				"itemLabel": "S2P_34",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 4.063,
				"choices": ["Kate's job in first grade is to feed the pet hamster every day.", "First, she has to wash her hands before she gets the food and water.", "The hamster's favorite food is sliced apples.", "It also likes vegetables.", "After the hamster is done eating, sometimes, Kate likes to carry it around the room."],
				"correctAnswer": "Kate's job in first grade is to feed the pet hamster every day. First, she has to wash her hands before she gets the food and water. The hamster's favorite food is sliced apples. It also likes vegetables. After the hamster is done eating, sometimes, Kate likes to carry it around the room.",
				"audioFileNames": null
			}, {
				"itemId": 7034,
				"itemLabel": "S2P_35",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 5.229,
				"choices": ["Cats sleep for about 13 to 14 hours a day to conserve energy.", "Waking up energized helps them to be very good and sneaky hunters.", "When they walk, their back paws step almost exactly in the same place as their front paws did beforehand.", "This helps keep their noise to a minimum and limits their foot tracks from being more visible."],
				"correctAnswer": "Cats sleep for about 13 to 14 hours a day to conserve energy. Waking up energized helps them to be very good and sneaky hunters. When they walk, their back paws step almost exactly in the same place as their front paws did beforehand. This helps keep their noise to a minimum and limits their foot tracks from being more visible.",
				"audioFileNames": null
			}, {
				"itemId": 7035,
				"itemLabel": "S2P_36",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 5.64,
				"choices": ["The Ancient Egyptians created many things.", "Most notably, they made the pyramids.", "Inside the pyramids, they wrote and drew on the walls.", "These drawings are called hieroglyphs.", "They were used to tell stories and communicate."],
				"correctAnswer": "The Ancient Egyptians created many things. Most notably, they made the pyramids. Inside the pyramids, they wrote and drew on the walls. These drawings are called hieroglyphs. They were used to tell stories and communicate.",
				"audioFileNames": null
			}, {
				"itemId": 7036,
				"itemLabel": "S2P_37",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 3.169,
				"choices": ["Peanut Butter and Jelly sandwiches are delicious and easy to make.", "You will need a knife, bread, peanut butter, and jelly.", "First you spread the peanut butter on the bread.", "Next, you add the Jelly.", "You squish the pieces of bread together.", "Then you eat it!"],
				"correctAnswer": "Peanut Butter and Jelly sandwiches are delicious and easy to make. You will need a knife, bread, peanut butter, and jelly. First you spread the peanut butter on the bread. Next, you add the Jelly. You squish the pieces of bread together. Then you eat it!",
				"audioFileNames": null
			}, {
				"itemId": 7037,
				"itemLabel": "S2P_38",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 1.403,
				"choices": ["The United States uses rockets to carry humans to the moon.", "To launch into space, rockets have engines.", "Like most engines, rocket engines burn fuel.", "Most rocket engines turn the fuel into hot gas.", "The engine pushes the gas out its back.", "The gas makes the rocket move forward."],
				"correctAnswer": "The United States uses rockets to carry humans to the moon. To launch into space, rockets have engines. Like most engines, rocket engines burn fuel. Most rocket engines turn the fuel into hot gas. The engine pushes the gas out its back. The gas makes the rocket move forward.",
				"audioFileNames": null
			}, {
				"itemId": 7038,
				"itemLabel": "S2P_39",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 6.849,
				"choices": ["My family likes to go skiing in winter.", "First, we drive up to the cabin.", "The cabin is always covered in snow.", "Next, we put on our ski gear and go outside.", "It is so cold outside, but our jackets keep us warm.", "Finally, we climb the slope and ski back down.", "This is my favorite time of year."],
				"correctAnswer": "My family likes to go skiing in winter. First, we drive up to the cabin. The cabin is always covered in snow. Next, we put on our ski gear and go outside. It is so cold outside, but our jackets keep us warm. Finally, we climb the slope and ski back down. This is my favorite time of year.",
				"audioFileNames": null
			}, {
				"itemId": 7039,
				"itemLabel": "S2P_40",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 6.321,
				"choices": ["The blue whale is the largest animal on Earth.", "But how do they get so big? Interestingly, they get big by eating a very small animal. Blue whales eat krill, which are like tiny shrimp.", "The whale will swim by krill with its mouth open.", "While it does this, it sucks them into its mouth.", "This is how one of the biggest animals on Earth eats one of the smallest."],
				"correctAnswer": "The blue whale is the largest animal on Earth. But how do they get so big? Interestingly, they get big by eating a very small animal. Blue whales eat krill, which are like tiny shrimp. The whale will swim by krill with its mouth open. While it does this, it sucks them into its mouth. This is how one of the biggest animals on Earth eats one of the smallest.",
				"audioFileNames": null
			}, {
				"itemId": 7040,
				"itemLabel": "S2P_41",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 5.372,
				"choices": ["The way that people communicate with each other has changed a lot over time.", "The telegraph was one of the earliest forms of communication.", "After many years people began to use telephones instead of telegraphs.", "Now, telephones are still used but people also communicate over the internet.", "I wonder what will be used next?"],
				"correctAnswer": "The way that people communicate with each other has changed a lot over time. The telegraph was one of the earliest forms of communication. After many years people began to use telephones instead of telegraphs. Now, telephones are still used but people also communicate over the internet. I wonder what will be used next?",
				"audioFileNames": null
			}, {
				"itemId": 7041,
				"itemLabel": "S2P_42",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 6.294,
				"choices": ["People in different countries around the world celebrate Christmas with various traditions.", "In Germany, as in many European countries,", "the season begins on Dec. 6, St. Nicholas Day.", "On Dec. 5, children place boots or shoes by doors or fireplaces so that St. Nick can fill them with treats during the night.", "Naughty children have been said to receive rocks or twigs in their shoes."],
				"correctAnswer": "People in different countries around the world celebrate Christmas with various traditions. In Germany, as in many European countries, the season begins on Dec. 6, St. Nicholas Day. On Dec. 5, children place boots or shoes by doors or fireplaces so that St. Nick can fill them with treats during the night. Naughty children have been said to receive rocks or twigs in their shoes.",
				"audioFileNames": null
			}, {
				"itemId": 7042,
				"itemLabel": "S2P_43",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 3.285,
				"choices": ["Butterflies begin their lives as caterpillars.", "They slither around and eat a lot of leaves.", "When they are full, they build a home called a cocoon.", "They wrap themselves inside the cocoon for a couple of weeks.", "Inside the cocoon they turn into butterflies.", "When they are ready, they break out of the cocoon and fly away."],
				"correctAnswer": "Butterflies begin their lives as caterpillars. They slither around and eat a lot of leaves. When they are full, they build a home called a cocoon. They wrap themselves inside the cocoon for a couple of weeks. Inside the cocoon they turn into butterflies. When they are ready, they break out of the cocoon and fly away.",
				"audioFileNames": null
			}, {
				"itemId": 7043,
				"itemLabel": "S2P_44",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 6.067,
				"choices": ["Animals adapt to the environment that they live in so that they can live longer.", "One example of an adaption is camouflage.", "Chameleons use camouflage to protect themselves.", "In order to protect themselves, they change the color of their skin so that they blend into the environment.", "When they blend in predators cannot see them!"],
				"correctAnswer": "Animals adapt to the environment that they live in so that they can live longer. One example of an adaption is camouflage. Chameleons use camouflage to protect themselves. In order to protect themselves, they change the color of their skin so that they blend into the environment. When they blend in predators cannot see them!",
				"audioFileNames": null
			}, {
				"itemId": 7044,
				"itemLabel": "S2P_45",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 2.5,
				"choices": ["The country of France built and donated the Statue of Liberty to the United States.", "It was donated as a gesture of friendship between the two republics, and stands on Liberty Island in New York Harbor.", "The statue is of a robed female figure, and stands", "at over 305 feet high, including the pedestal.", "The figure represents Libertas, the Roman goddess of freedom, who bears a torch.", "A broken chain lies at her feet."],
				"correctAnswer": "The country of France built and donated the Statue of Liberty to the United States. It was donated as a gesture of friendship between the two republics, and stands on Liberty Island in New York Harbor. The statue is of a robed female figure, and stands at over 305 feet high, including the pedestal. The figure represents Libertas, the Roman goddess of freedom, who bears a torch. A broken chain lies at her feet.",
				"audioFileNames": null
			}, {
				"itemId": 7045,
				"itemLabel": "S2P_46",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 6.725,
				"choices": ["There are many different types, or breeds, of horses.", "Each breed was created to perform a certain activity.", "The most popular type of horse is a Quarter Horse.", "Quarter Horses are healthy and muscular, and can do most things.", "Another kind of horse is a Thoroughbred.", "Thoroughbreds were created specifically for racing.", "Finally, the oldest breed of horse is the Arabian.", "Arabians are often used in long-distance races across the desert.", "An interesting fact about Arabians is that they have one less vertebra in their spine."],
				"correctAnswer": "There are many different types, or breeds, of horses. Each breed was created to perform a certain activity. The most popular type of horse is a Quarter Horse. Quarter Horses are healthy and muscular, and can do most things. Another kind of horse is a Thoroughbred. Thoroughbreds were created specifically for racing. Finally, the oldest breed of horse is the Arabian. Arabians are often used in long-distance races across the desert. An interesting fact about Arabians is that they have one less vertebra in their spine.",
				"audioFileNames": null
			}, {
				"itemId": 7046,
				"itemLabel": "S2P_47",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 5.96,
				"choices": ["Each of us is part of an extended family.", "We use a family tree to show family relationships visually.", "First, Aunts and Uncles are parents to my cousins.", "Second, Grandma and Grandpa are my parents' parents.", "Third, Nieces and Nephews are my siblings' children.", "Finally, family trees help us learn about our roots or where we came from."],
				"correctAnswer": "Each of us is part of an extended family. We use a family tree to show family relationships visually. First, Aunts and Uncles are parents to my cousins. Second, Grandma and Grandpa are my parents' parents. Third, Nieces and Nephews are my siblings' children. Finally, family trees help us learn about our roots or where we came from.",
				"audioFileNames": null
			}, {
				"itemId": 7047,
				"itemLabel": "S2P_48",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 6.255,
				"choices": ["Abraham Lincoln was the 16th President of the United States of America.", "As President, Lincoln led the United States through the Civil War.", "He also issued the Emancipation Proclamation.", "It stated that all slaves would be free in the Confederate States.", "President Lincoln was shot and killed by a man named John Wilkes Booth.", "Today, we see President Lincoln's picture on the penny and the $5 bill."],
				"correctAnswer": "Abraham Lincoln was the 16th President of the United States of America. As President, Lincoln led the United States through the Civil War. He also issued the Emancipation Proclamation. It stated that all slaves would be free in the Confederate States. President Lincoln was shot and killed by a man named John Wilkes Booth. Today, we see President Lincoln's picture on the penny and the $5 bill.",
				"audioFileNames": null
			}, {
				"itemId": 7048,
				"itemLabel": "S2P_49",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 2.5,
				"choices": ["The first home surveillance system was invented in the early 1960s.", "In 1966, black female inventor, Marie Van Britton Brown, applied for an invention patent for a closed-circuit security system.", "It was the precursor to the modern-day home security system.", "Brown's system had a set of four peep holes and a camera that could slide up and down to look out of each one.", "Anything the camera observed would appear on a monitor.", "A person could also unlock a door with a remote control using Brown's invention."],
				"correctAnswer": "The first home surveillance system was invented in the early 1960s. In 1966, black female inventor, Marie Van Britton Brown, applied for an invention patent for a closed-circuit security system. It was the precursor to the modern-day home security system. Brown's system had a set of four peep holes and a camera that could slide up and down to look out of each one. Anything the camera observed would appear on a monitor. A person could also unlock a door with a remote control using Brown's invention.",
				"audioFileNames": null
			}, {
				"itemId": 7049,
				"itemLabel": "S2P_50",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 2.5,
				"choices": ["When you are researching a topic, there is a certain way to do it.", "This way is called the Scientific Method.", "When you use the Scientific Method, there are certain steps.", "First, you form a hypothesis, which is a guess about what will happen.", "Next, you set up an experiment to test your hypothesis.", "During the experiment, you observe what happens.", "Finally, you document your observations and think about what they mean."],
				"correctAnswer": "When you are researching a topic, there is a certain way to do it. This way is called the Scientific Method. When you use the Scientific Method, there are certain steps. First, you form a hypothesis, which is a guess about what will happen. Next, you set up an experiment to test your hypothesis. During the experiment, you observe what happens. Finally, you document your observations and think about what they mean.",
				"audioFileNames": null
			}, {
				"itemId": 7050,
				"itemLabel": "S2P_51",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 6.347,
				"choices": ["This morning when I got to school, something seemed wrong.", "There was a growling noise coming from behind my teacher's desk.", "I could also see green smoke rising up from under her chair.", "I walked up to her desk slowly and peered over the top.", "What I saw on the other side was terrifying!", "My teacher had been turned into a monster!", "She had green scales and purple hair, and her claws were long and sharp.", "I think I'm going to stay home tomorrow."],
				"correctAnswer": "This morning when I got to school, something seemed wrong. There was a growling noise coming from behind my teacher's desk. I could also see green smoke rising up from under her chair. I walked up to her desk slowly and peered over the top. What I saw on the other side was terrifying! My teacher had been turned into a monster! She had green scales and purple hair, and her claws were long and sharp. I think I'm going to stay home tomorrow.",
				"audioFileNames": null
			}, {
				"itemId": 7051,
				"itemLabel": "S2P_52",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 6.376,
				"choices": ["Plants have four basic structures.", "The first basic structure is the root which absorbs nutrients from the ground.", "The next basic structure is the stem.", "The stem supports the plant and keeps it from falling over or lying on the ground.", "Leaves are the third basic structure.", "They create food through a process called photosynthesis.", "Lastly, flowers are the fourth basic structure.", "Flowers attract bees and create seeds for reproduction."],
				"correctAnswer": "Plants have four basic structures. The first basic structure is the root which absorbs nutrients from the ground. The next basic structure is the stem. The stem supports the plant and keeps it from falling over or lying on the ground. Leaves are the third basic structure. They create food through a process called photosynthesis. Lastly, flowers are the fourth basic structure. Flowers attract bees and create seeds for reproduction.",
				"audioFileNames": null
			}, {
				"itemId": 7052,
				"itemLabel": "S2P_53",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 2.5,
				"choices": ["Alligators are one of the oldest species of animal that exists today.", "In fact, they were around when dinosaurs roamed the earth!", "Alligators currently live in the southern region of the United States.", "In Florida, many people have problems with alligators swimming in their backyard swimming pools.", "Alligators are territorial, which means they will claim an area and protect it.", "Many people get hurt by alligators because they wander into their territory without knowing it."],
				"correctAnswer": "Alligators are one of the oldest species of animal that exists today. In fact, they were around when dinosaurs roamed the earth! Alligators currently live in the southern region of the United States. In Florida, many people have problems with alligators swimming in their backyard swimming pools. Alligators are territorial, which means they will claim an area and protect it. Many people get hurt by alligators because they wander into their territory without knowing it.",
				"audioFileNames": null
			}, {
				"itemId": 7053,
				"itemLabel": "S2P_54",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 7.579,
				"choices": ["Growth, death, and decay are three main parts of a plant's life cycle.", "First, a plant grows from the nutrients in the ground and the food it makes with its leaves.", "Eventually, the plant dies and falls onto the ground.", "When the plant is dead and on the ground it starts to break up and decay, returning nutrients to the soil.", "Those nutrients that return to the soil during decay will then help another plant grow.", "The cycle continues on and on in that way."],
				"correctAnswer": "Growth, death, and decay are three main parts of a plant's life cycle. First, a plant grows from the nutrients in the ground and the food it makes with its leaves. Eventually, the plant dies and falls onto the ground. When the plant is dead and on the ground it starts to break up and decay, returning nutrients to the soil. Those nutrients that return to the soil during decay will then help another plant grow. The cycle continues on and on in that way.",
				"audioFileNames": null
			}, {
				"itemId": 7054,
				"itemLabel": "S2P_55",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 7.027,
				"choices": ["Hummingbirds are the only birds that can fly backwards.", "By rotating each of their wings in a circle, they can fly backwards, forward, or up and down.", "They can do this because they have a rotator cuff.", "The rotator cuff allows them to move their wings in a figure-8 motion.", "They use this motion mainly when they are feeding on nectar from flowers.", "It helps them to remove their beak from the flower while in flight."],
				"correctAnswer": "Hummingbirds are the only birds that can fly backwards. By rotating each of their wings in a circle, they can fly backwards, forward, or up and down. They can do this because they have a rotator cuff. The rotator cuff allows them to move their wings in a figure-8 motion. They use this motion mainly when they are feeding on nectar from flowers. It helps them to remove their beak from the flower while in flight.",
				"audioFileNames": null
			}, {
				"itemId": 7055,
				"itemLabel": "S2P_56",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 7.963,
				"choices": ["Jimmy's dad said early one morning, \"Let's go for a drive today!\"", "Jimmy was very excited. He hoped they could go to visit Grandma's house.", "Jimmy ran down the stairs shouting, \"Let's go to Grandma's house.\"", "Jimmy's Dad smiled and told him, \"That is a great idea!\"", "Jimmy's Dad rolled a large map out on the kitchen table.", "\"What does it look like is the best way to get to Grandma's house?\", Jimmy's Dad asked.", "Jimmy carefully looked at the map.", "He asked, \"Which street does she live on?\"", "Jimmy's Dad said, \"Good question. Maple Street.\"", "Jimmy carefully found Maple street.", "Then he traced a route with his finger from his house on the map to his Grandma's house on the map.", "\"This looks like the best way!\", Jimmy said, \"Let's go!\""],
				"correctAnswer": "Jimmy's dad said early one morning, \"Let's go for a drive today!\" Jimmy was very excited. He hoped they could go to visit Grandma's house. Jimmy ran down the stairs shouting, \"Let's go to Grandma's house.\" Jimmy's Dad smiled and told him, \"That is a great idea!\" Jimmy's Dad rolled a large map out on the kitchen table. \"What does it look like is the best way to get to Grandma's house?\", Jimmy's Dad asked. Jimmy carefully looked at the map. He asked, \"Which street does she live on?\" Jimmy's Dad said, \"Good question. Maple Street.\" Jimmy carefully found Maple street. Then he traced a route with his finger from his house on the map to his Grandma's house on the map. \"This looks like the best way!\", Jimmy said, \"Let's go!\"",
				"audioFileNames": null
			}, {
				"itemId": 7056,
				"itemLabel": "S2P_57",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 9.226,
				"choices": ["Recycling is the process of changing disposed waste into useful materials.", "Recycling is important because it keeps our earth clean and reduces the amount of waste that is buried in the ground.", "Instead of throwing a container in the trash after consuming food or drink, the container goes into a recycling bin.", "A truck comes and takes the recycling bin away to the recycling center.", "At the recycling center, the recycling gets sorted into different types of materials.", "The different materials are made into something new for people to buy and use."],
				"correctAnswer": "Recycling is the process of changing disposed waste into useful materials. Recycling is important because it keeps our earth clean and reduces the amount of waste that is buried in the ground. Instead of throwing a container in the trash after consuming food or drink, the container goes into a recycling bin. A truck comes and takes the recycling bin away to the recycling center. At the recycling center, the recycling gets sorted into different types of materials. The different materials are made into something new for people to buy and use.",
				"audioFileNames": null
			}, {
				"itemId": 7057,
				"itemLabel": "S2P_58",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 7.464,
				"choices": ["There are many things for butterflies to eat right in your backyard.", "First, when they are caterpillars, they eat only leaves of plants.", "The leaves provide the nutrients the caterpillars need to grow.", "Then they spin chrysalises.", "After a long time, they emerge as butterflies.", "Adult butterflies eat all kinds of things, including nectar, water, and liquids from fruits we eat.", "If you want to attract butterflies to your backyard, plant fruit-bearing trees with plenty of flowers.", "You will definitely have a back yard full of butterflies then."],
				"correctAnswer": "There are many things for butterflies to eat right in your backyard. First, when they are caterpillars, they eat only leaves of plants. The leaves provide the nutrients the caterpillars need to grow. Then they spin chrysalises. After a long time, they emerge as butterflies. Adult butterflies eat all kinds of things, including nectar, water, and liquids from fruits we eat. If you want to attract butterflies to your backyard, plant fruit-bearing trees with plenty of flowers. You will definitely have a back yard full of butterflies then.",
				"audioFileNames": null
			}, {
				"itemId": 7058,
				"itemLabel": "S2P_59",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 5.274,
				"choices": ["In the 1800s, many people suffered from walking and standing in shoes that caused painful blisters and sores on their feet.", "Their feet became sweaty and ached because their shoes had no cool air circulating in them to cool their feet.", "Then, Henry Faulkner devised a method to place holes in certain areas of a shoe to allow circulation and more comfort.", "Faulkner patented his invention, the ventilated shoe, in 1890.", "Because of the ventilated shoe, many more people have healthier feet today."],
				"correctAnswer": "In the 1800s, many people suffered from walking and standing in shoes that caused painful blisters and sores on their feet. Their feet became sweaty and ached because their shoes had no cool air circulating in them to cool their feet. Then, Henry Faulkner devised a method to place holes in certain areas of a shoe to allow circulation and more comfort. Faulkner patented his invention, the ventilated shoe, in 1890. Because of the ventilated shoe, many more people have healthier feet today.",
				"audioFileNames": null
			}, {
				"itemId": 7059,
				"itemLabel": "S2P_60",
				"itemType": "sentences2Paragraph",
				"itemClass": "active",
				"difficulty": 2.5,
				"choices": ["Phoenix has different types of dust storms, but the haboob is the largest and most dangerous.", "A haboob is a wall of dust.", "It can result from a microburst or an intense downdraft of air.", "These cause rapid changes in wind direction and speed.", "The wind during a haboob can range from 30 to over 70 mph.", "This rising wind picks up dirt and sand, and pushes the debris hundreds of feet into the air.", "This makes it practically impossible to see.", "This lack of visibility causes resident to flee indoors and motorists to slow down or pull over off of the road until the storm is over."],
				"correctAnswer": "Phoenix has different types of dust storms, but the haboob is the largest and most dangerous. A haboob is a wall of dust. It can result from a microburst or an intense downdraft of air. These cause rapid changes in wind direction and speed. The wind during a haboob can range from 30 to over 70 mph. This rising wind picks up dirt and sand, and pushes the debris hundreds of feet into the air. This makes it practically impossible to see. This lack of visibility causes resident to flee indoors and motorists to slow down or pull over off of the road until the storm is over.",
				"audioFileNames": null
			}]
		},
		"finale": {
			"trainingItemIds": [],
			"numTrainingItemRetries": 0,
			"items": [{
				"itemId": 9999,
				"itemLabel": "FIN_1",
				"itemType": "finale",
				"itemClass": "active"
			}]
		}
	};

	// Merge the decodable & non-decodable item definitions into a single group,
	// sorted by grade equivalent
	itemDefs.wordRecognition = {};
	itemDefs.wordRecognition.trainingItemIds = itemDefs.wordRecognitionDecodable.trainingItemIds.concat(itemDefs.wordRecognitionNonDecodable.trainingItemIds);
	itemDefs.wordRecognition.numTrainingItemRetries = itemDefs.wordRecognitionDecodable.numTrainingItemRetries +
		itemDefs.wordRecognitionNonDecodable.numTrainingItemRetries;
	itemDefs.wordRecognition.items = itemDefs.wordRecognitionDecodable.items.concat(itemDefs.wordRecognitionNonDecodable.items);
	itemDefs.wordRecognition.items.sort(function(a, b) {
		if (a.GE < b.GE) {
			return -1;
		} else if (a.GE > b.GE) {
			return 1;
		} else {
			return 0;
		}
	});
	delete itemDefs.wordRecognitionDecodable;
	delete itemDefs.wordRecognitionNonDecodable;

	$.each(itemDefs.wordRecognition.items, function(idx, itemDef) {
		// Update the item type on each WR item
		itemDef.itemType = "wordRecognition";
	});

	/*******************************/
	/* Item definition maintenance */
	/*******************************/

	// Remove an item definition (by type & ID)
	itemDefs.purgeItem = function(itemType, itemId) {
		// Account for merged word recognition items
		if ((itemType === "wordRecognitionDecodable") || (itemType === "wordRecognitionNonDecodable")) {
			itemType = "wordRecognition";
		}

		if (itemType && itemId) {
			// Remove the item definition
			var itemIdx = this.findItemIdxByTypeAndId(itemType, itemId);
			if (itemIdx != -1) {
				this[itemType].items.splice(itemIdx, 1);
			}

			// If it's a training item, remove it from the training item IDs array as well
			var trainingItemIdsIdx = $.inArray(itemId, this[itemType].trainingItemIds);
			if (trainingItemIdsIdx != -1) {
				this[itemType].trainingItemIds.splice(trainingItemIdsIdx, 1);
			}
		}
	};

	// Remove all disabled items
	itemDefs.purgeDisabledItems = function() {
		$.each(this, function(itemTypeName, itemArrayHeader) {
			if (typeof itemArrayHeader.items !== "undefined") {
				for (var i = itemArrayHeader.items.length - 1; i > 0; i--) {
					var itemDef = itemArrayHeader.items[i];
					if (itemDef.itemClass == "disabled") {
						itemArrayHeader.items.splice(i, 1);
					}
				}
			}
		});
	};

	/************************/
	/* Add finder functions */
	/************************/

	/**
	 *  Find an item definition of a given type by its ID
	 *  Args:   type - The name of the array in itemDefs to search (e.g. "wordRecognition", "sentences2Paragraph")
	 *          id - The item ID to search for
	 *  Return: The item definition with the given ID and its index in its array, or [undefined, -1] if not found
	 */
	itemDefs.findItemDefAndIndexByTypeAndId = function(type, id) {
		var itemDef = undefined;
		var itemIdx = -1;

		// Account for merged word recognition items
		if ((type === "wordRecognitionDecodable") || (type === "wordRecognitionNonDecodable")) {
			type = "wordRecognition";
		}

		if (this[type].items instanceof Array) {
			this[type].items.every(function(item, i) {
				if (item.itemId == id) {
					itemDef = item;
					itemIdx = i;
					return false;
				} else {
					return true;
				}
			});
		}

		return [ itemDef, itemIdx ];
	};

	itemDefs.findItemDefById = function(id) {
		var retItemDef = undefined;

		var that = this;
		$.each(this, function(itemTypeName, itemArrayHeader) {
			if (typeof itemArrayHeader.items !== "undefined") {
				$.each(itemArrayHeader.items, function(itemDefIdx, itemDef) {
					if (itemDef.itemId === id) {
						retItemDef = itemDef;
						return false;
					}
				});
			}

			if (typeof retItemDef !== "undefined") {
				return false;
			}
		});

		return retItemDef;
	};

	// Front-end finders
	itemDefs.findItemDefByTypeAndId = function(type, id) {
		var results = this.findItemDefAndIndexByTypeAndId(type, id);
		return results[0];
	};
	itemDefs.findItemIdxByTypeAndId = function(type, id) {
		var results = this.findItemDefAndIndexByTypeAndId(type, id);
		return results[1];
	};

	/**
	 *  Find unasked item definitions of a given type by GE
	 *  Args:   type - The name of the array in itemDefs to search (e.g. "wordRecognition", "sentences2Paragraph")
	 *          ge - The grade equivalent to search for
	 *  Return: An array with the item definition(s) with the given GE (may be empty)
	 */
	itemDefs.findUnaskedItemsByTypeAndGE = function(type, ge) {
		var itemDefsForGE = [];

		// Account for merged word recognition items
		if ((type === "wordRecognitionDecodable") || (type === "wordRecognitionNonDecodable")) {
			type = "wordRecognition";
		}

		if (this[type].items instanceof Array) {
			for (var i = 0; i < this[type].items.length; i++) {
				if (!this[type].items[i].asked && (Math.abs(this[type].items[i].ge - ge) < 0.05)) {
					itemDefsForGE.push(this[type].items[i]);
					break;
				}
			}
		}

		return itemDefsForGE;
	};

	return itemDefs;
}
