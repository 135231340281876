function l2m_testDefFactory() {
	var testDefAdaptive = {
		"id": 1001,
		"name": "March 2016 Test",
		"grades": {
			"0": {
				"testType": "adaptive",
				"initialItemTheta": -1.1852356251117786,
				"initialItemType": "letterIdentification",
				"maxItemCount": 24,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 12,
						"minItemCount": 3
					},
					"soundIdentification": {
						"maxItemCount": 12,
						"minItemCount": 3
					},
					"wordRecognition": {
						"maxItemCount": 12,
						"minItemCount": 6
					},
					"letters2Word": {
						"maxItemCount": 12,
						"minItemCount": 5
					},
					"words2Sentence": {
						"maxItemCount": 20,
						"minItemCount": 0
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_abilityEstimateTheta",
						"condition": "lessThan",
						"value": -2.45,
						"results": [
							{ "variable": "_itemType", "value": "finale" },
							{ "variable": "_abilityEstimateTheta", "value": -3.5 }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": -0.5310813270761758 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 0.5205488496166056,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": -0.5310813270761758 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.1027484798996523,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": 0.21196816823690834 }
						]
					}
				}
			},
			"1": {
				"testType": "adaptive",
				"initialItemTheta": -0.7711475658996101,
				"initialItemType": "wordRecognition",
				"maxItemCount": 24,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 20,
						"minItemCount": 0
					},
					"soundIdentification": {
						"maxItemCount": 20,
						"minItemCount": 0
					},
					"wordRecognition": {
						"maxItemCount": 12,
						"minItemCount": 6
					},
					"letters2Word": {
						"maxItemCount": 12,
						"minItemCount": 5
					},
					"words2Sentence": {
						"maxItemCount": 20,
						"minItemCount": 5
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_itemTheta",
						"condition": "lessThan",
						"value": -3.5000,
						"results": [
							{ "variable": "_itemType", "value": "finale" }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letterIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": -0.7711475658996101 }
						]
					},
					"wordRecognitionDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.1027484798996523,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": -0.5310813270761758 }
						]
					},
					"letters2WordDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 5,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": -0.7711475658996101 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.1027484798996523,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": 0.5205488496166056 }
						]
					},
					"words2SentenceDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 5,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": -0.5310813270761758 }
						]
					}
				}
			},
			"2": {
				"testType": "adaptive",
				"initialItemTheta": 0.5205488496166056,
				"initialItemType": "wordRecognition",
				"maxItemCount": 25,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 25,
						"minItemCount": 0
					},
					"soundIdentification": {
						"maxItemCount": 25,
						"minItemCount": 0
					},
					"wordRecognition": {
						"maxItemCount": 12,
						"minItemCount": 6
					},
					"letters2Word": {
						"maxItemCount": 12,
						"minItemCount": 6
					},
					"words2Sentence": {
						"maxItemCount": 25,
						"minItemCount": 6
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_itemTheta",
						"condition": "lessThan",
						"value": -3.5000,
						"results": [
							{ "variable": "_itemType", "value": "finale" }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letterIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.5205488496166056 }
						]
					},
					"wordRecognitionDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.7205589452777987,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 0.5205488496166056 }
						]
					},
					"letters2WordDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.5205488496166056 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.7205589452777987,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": 0.8133268711639268 }
						]
					},
					"words2SentenceDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 0.5205488496166056 }
						]
					}
				}
			},
			"3": {
				"testType": "adaptive",
				"initialItemTheta": 0.5205488496166056,
				"initialItemType": "wordRecognition",
				"maxItemCount": 25,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 25,
						"minItemCount": 0
					},
					"soundIdentification": {
						"maxItemCount": 25,
						"minItemCount": 0
					},
					"wordRecognition": {
						"maxItemCount": 15,
						"minItemCount": 6
					},
					"letters2Word": {
						"maxItemCount": 12,
						"minItemCount": 6
					},
					"words2Sentence": {
						"maxItemCount": 25,
						"minItemCount": 6
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_itemTheta",
						"condition": "lessThan",
						"value": -3.5000,
						"results": [
							{ "variable": "_itemType", "value": "finale" }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letterIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.5205488496166056 }
						]
					},
					"wordRecognitionDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.7205589452777987,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 1.1027484798996523 }
						]
					},
					"letters2WordDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.5205488496166056 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.7205589452777987,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": 1.7205589452777987 }
						]
					},
					"words2SentenceDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 1.1027484798996523 }
						]
					}
				}
			},
			"4": {
				"testType": "adaptive",
				"initialItemTheta": 0.8133268711639268,
				"initialItemType": "wordRecognition",
				"maxItemCount": 25,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 25,
						"minItemCount": 0
					},
					"soundIdentification": {
						"maxItemCount": 25,
						"minItemCount": 0
					},
					"wordRecognition": {
						"maxItemCount": 15,
						"minItemCount": 6
					},
					"letters2Word": {
						"maxItemCount": 15,
						"minItemCount": 6
					},
					"words2Sentence": {
						"maxItemCount": 25,
						"minItemCount": 6
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_itemTheta",
						"condition": "lessThan",
						"value": -3.5000,
						"results": [
							{ "variable": "_itemType", "value": "finale" }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letterIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.8133268711639268 }
						]
					},
					"wordRecognitionDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.7205589452777987,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 1.1027484798996523 }
						]
					},
					"letters2WordDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.8133268711639268 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.7205589452777987,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": 1.7205589452777987 }
						]
					},
					"words2SentenceDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 1.1027484798996523 }
						]
					}
				}
			},
			"5": {
				"testType": "adaptive",
				"initialItemTheta": 1.7205589452777987,
				"initialItemType": "letters2Word",
				"maxItemCount": 25,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 25,
						"minItemCount": 0
					},
					"soundIdentification": {
						"maxItemCount": 25,
						"minItemCount": 0
					},
					"wordRecognition": {
						"maxItemCount": 15,
						"minItemCount": 0
					},
					"letters2Word": {
						"maxItemCount": 15,
						"minItemCount": 12
					},
					"words2Sentence": {
						"maxItemCount": 25,
						"minItemCount": 12
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_itemTheta",
						"condition": "lessThan",
						"value": -3.5000,
						"results": [
							{ "variable": "_itemType", "value": "finale" }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letterIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.8133268711639268 }
						]
					},
					"wordRecognitionDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.7205589452777987,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 1.7205589452777987 }
						]
					},
					"letters2WordDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.8133268711639268 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.7205589452777987,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": 1.7205589452777987 }
						]
					},
					"words2SentenceDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 1.7205589452777987 }
						]
					}
				}
			},
			"6": {
				"testType": "adaptive",
				"initialItemTheta": 1.7205589452777987,
				"initialItemType": "words2Sentence",
				"maxItemCount": 25,
				"parametersByItemType": {
					"letterIdentification": {
						"maxItemCount": 25,
						"minItemCount": 0
					},
					"soundIdentification": {
						"maxItemCount": 25,
						"minItemCount": 0
					},
					"wordRecognition": {
						"maxItemCount": 15,
						"minItemCount": 0
					},
					"letters2Word": {
						"maxItemCount": 15,
						"minItemCount": 12
					},
					"words2Sentence": {
						"maxItemCount": 25,
						"minItemCount": 12
					}
				},
				"itemTypeThresholds": {
					"letterIdentificationDescent": {
						"variable": "_itemTheta",
						"condition": "lessThan",
						"value": -3.5000,
						"results": [
							{ "variable": "_itemType", "value": "finale" }
						]
					},
					"letterIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThanOrEqualTo",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letterIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"soundIdentificationAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": -1.0544779240375213,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.8133268711639268 }
						]
					},
					"wordRecognitionDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "soundIdentification" },
							{ "variable": "_itemTheta", "value": -1.1852356251117786 }
						]
					},
					"wordRecognitionAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.7205589452777987,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 1.7205589452777987 }
						]
					},
					"letters2WordDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "wordRecognition" },
							{ "variable": "_itemTheta", "value": 0.8133268711639268 }
						]
					},
					"letters2WordAscent": {
						"variable": "_itemTheta",
						"condition": "greaterThan",
						"value": 1.7205589452777987,
						"results": [
							{ "variable": "_itemType", "value": "words2Sentence" },
							{ "variable": "_itemTheta", "value": 1.7205589452777987 }
						]
					},
					"words2SentenceDescent": {
						"variable": "_numIncorrectInARow",
						"condition": "greaterThanOrEqualTo",
						"value": 6,
						"results": [
							{ "variable": "_itemType", "value": "letters2Word" },
							{ "variable": "_itemTheta", "value": 1.7205589452777987 }
						]
					}
				}
			},
			"default": {
				"testType": "linear",
				"initialItemTheta": 1.1027484798996523,
				"initialItemType": "words2Sentence",
				"maxItemCount": 35,
				"initialItemId": 6012
			}
		}
	};

	return testDefAdaptive;
}
