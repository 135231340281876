$(function() {
	var resizeTest = function() {
		var $testElement = $('div#contentArea'),
			$testContainer = $('div#toplevel'),
			$splashScreen = $('div#splashScreen'),
			testHeight = $testElement.outerHeight(),
			// We need space on either side of the test to prevent the iPad swipe gesture from
			// pulling the student out of the test
			testWidth = $testElement.outerWidth() + 300,
			headerHeight = $('div#header').outerHeight(),
			footerHeight = $('div#footer').outerHeight(),
			screenHeight = $(window).outerHeight() - footerHeight - headerHeight,
			screenWidth = $(window).outerWidth();

		// If the user is somehow using the exactly perfect screen size, then we're done here
		if (screenWidth >= testWidth && screenHeight >= testHeight) {
			return;
		}

		/**
		 * Now to determine whether to scale to fit the screen's width or height.
		 * To do this, we simply compare the screen's aspect ratio to the test's.  Aspect ratio is the
		 * ratio of width to height, so if aspect ratio is higher, the screen is relatively wider
		 * compared to its height than the test, meaning height is the constraining proportion.
		 */

		var calculateNewScale = function(testDimension, screenDimension) {
			var newScale = 1;
			if (testDimension >= screenDimension) {
				// The test is bigger than the screen, so scale down, or they're equal, in which case this will
				// return 1 in any case
				newScale = screenDimension / testDimension;
			} else {
				newScale = testDimension / screenDimension;
			}

			return newScale;
		};

		var screenAspectRatio = screenWidth / screenHeight,
			testAspectRatio = testWidth / testHeight,
			newScale = 1;

		if (screenAspectRatio >= testAspectRatio) {
			// Either this screen is relatively wider than the test, so height is the constraint,
			// or it has the same aspect ratio, in which case we can use either height or width
			newScale = calculateNewScale(testHeight, screenHeight);
		} else {
			// This screen is relatively taller than the test, so width is the constraint
			newScale = calculateNewScale(testWidth, screenWidth);
		}

		$testContainer.css({
			'transform': `scale(${newScale})`,
			'margin-top': `${headerHeight}px`
		});

		// The splash screen shouldn't be rescaled
		$splashScreen.css({'transform': `scale(${1 / newScale})`});
	};

	// Resize the test on doc ready, and any time the window is resized
	resizeTest();
	$(window).resize(resizeTest);
});
