function SoundCfg(soundName,
	preDelayMs,
	postDelayMs,
	itemIdx,
	responseIdx,
	replayable,
	replayableOnRequest,
	replayableAfterResponse,
	clickable,
	onEndedUserCallback) {
	this.soundName = soundName;
	this.preDelayMs = preDelayMs;
	this.postDelayMs = postDelayMs;
	this.itemIdx = itemIdx;
	this.responseIdx = responseIdx;
	this.replayable = replayable;
	this.replayableOnRequest = replayableOnRequest;
	this.replayableAfterResponse = replayableAfterResponse;
	this.clickable = clickable;
	this.onEndedUserCallback = onEndedUserCallback;
}

var audio = {

	init: function() {
		this._intervalTimerId = undefined;
		this._intervalTimerCallback = undefined;
		this._intervalTimerInterval = undefined;
		this._initialDelayMs = 1000;
		this._autoReplayDelayMs = 30000;
		this._curSoundIdx;
		this._loopEndedCallback = undefined;

		this._soundCfgs = undefined;
		this._soundIntervalPeriods = undefined;
		this._sounds = undefined;
		this._numPlaybacks = 0;
		this._howler = undefined;
		this._volume = 0.5;

		this._MIMEType;

		this._replayRequested = false;
		this._hasResponded = false;
		this._clicksEnabled = true;
		this._initialReadingEnded = false;
	},

	playAudio: function(soundCfgIdx) {
		var soundName = `/audio/mp3/${this._soundCfgs[soundCfgIdx].soundName}.mp3`;

		this._howler = new Howl({
			src: soundName,
			onend: () => this.onSoundEnded.bind(this).call(),
			onpause: (function() {
				this._paused = true;
				app.showReplayButton();
			}).bind(this),
			onplay: (function() {
				this._paused = false;
				app.hideReplayButton();
			}).bind(this),
			onloaderror: (function(soundId, message) {
				console.error(`Howler failed to load sound '${soundId}' with message '${message}'`);
			}).bind(this),
			onplayerror: (function(soundId, message) {
				console.error(`Howler failed to play sound '${soundId}' with message '${message}'`);
			}).bind(this)
		});

		this._howler.volume(this._volume);
		this._howler.play();
	},

	setVolume: function(volume) {
		volume = Math.min(1.0, Math.max(0.0, volume));
		this._volume = volume;
		if (typeof this._howler !== "undefined") {
			this._howler.volume(volume);
		}
	},

	getVolume: function() {
		return this._volume;
	},

	addSoundCfg: function(audio,
		preDelay,
		postDelay,
		itemIdx,
		responseIdx,
		replayable,
		replayableOnRequest,
		replayableAfterResponse,
		clickable,
		onEndedUserCallback) {
		if ((typeof audio !== "undefined") &&
                (audio != "")) {
			var    newCfg = new SoundCfg(audio,
				preDelay,
				postDelay,
				itemIdx,
				responseIdx,
				replayable,
				replayableOnRequest,
				replayableAfterResponse,
				clickable,
				onEndedUserCallback);
			this._soundCfgs[this._soundCfgs.length] = newCfg;

			var len = this._soundIntervalPeriods.length;
			if (len > 0) {
				this._soundIntervalPeriods[len - 1] += newCfg.preDelayMs;
				this._soundIntervalPeriods[len] = newCfg.postDelayMs;
			} else {
				this._soundIntervalPeriods[len] = newCfg.postDelayMs;
			}
		}
	},

	dumpSoundCfgs: function() {
		if (typeof this._soundCfgs !== "undefined") {
			var numCfgs = this._soundCfgs.length;
			console.log("dumpSoundCfgs(): numCfgs = %d\n", numCfgs);

			if (numCfgs > 0) {
				var i = 0;
				for (; i < numCfgs; i++) {
					console.log("  %d: %d ms\n", i, JSON.stringify(this._soundCfgs[i]));
				}
			}
			console.log("dumpSoundCfgs() end\n");
		}
	},

	dumpSoundPeriods: function() {
		console.log("dumpSoundPeriods()");
		if (typeof this._soundIntervalPeriods !== "undefined") {
			var numPeriods = this._soundIntervalPeriods.length;
			console.log("dumpSoundPeriods(): numPeriods = %d\n", numPeriods);

			if (numPeriods > 0) {
				var i = 0;
				for (; i < numPeriods; i++) {
					console.log("  %d: %d ms\n", i, this._soundIntervalPeriods[i]);
				}
			}
			console.log("dumpSoundPeriods() end\n");
		}
	},

	onSoundEnded: function(e) {
		if (!e) {
			e = window.event;
		}

		if (e) {
			e.cancelBubble = true;
			e.returnValue = false;
			if (e.stopPropagation) {
				e.stopPropagation();
				e.preventDefault();
			}
		}

		if (typeof this._soundCfgs !== "undefined") {
			if (typeof logSoundEnded !== "undefined") {
				logSoundEnded(this._soundCfgs[this._curSoundIdx].soundName);
			}

			if (this._soundCfgs[this._curSoundIdx].onEndedUserCallback) {
				this._soundCfgs[this._curSoundIdx].onEndedUserCallback();
			}

			this.stopSoundPlayback();

			if (this._curSoundIdx < this._soundIntervalPeriods.length - 1) {
				if (this._soundIntervalPeriods[this._curSoundIdx] == 0) {
					// Set up for the next audio clip/interval
					this._curSoundIdx++;

					this.intervalCallback();
				} else {
					this.startAudioTimer(this.intervalCallback.bind(this), this._soundIntervalPeriods[this._curSoundIdx]);

					// Set up for the next audio clip/interval
					this._curSoundIdx++;
				}
			} else {
				//if (!this._hasResponded && this._soundIntervalPeriods[this._curSoundIdx] > 3000) {
				if (this._soundIntervalPeriods[this._curSoundIdx] > 3000) {
					app.showReplayButton();
				} else {
					app.hideReplayButton();
				}

				this.setClicksEnabled(true);
				this.startAudioTimer(this.onAudioLoopEnded.bind(this), this._soundIntervalPeriods[this._curSoundIdx]);
			}
		} else if (typeof this._loopEndedCallback != "undefined") {
			// Act like the audio loop has ended (calls user callback)
			this.onAudioLoopEnded();
		}

		return false;
	},

	setHasResponded: function(hasResponded) {
		if (this._hasResponded != hasResponded) {
			this._hasResponded = hasResponded;

			if (
				(typeof this._soundIntervalPeriods !== "undefined") &&
                (this._soundIntervalPeriods.length > 0) &&
                (this._curSoundIdx == this._soundIntervalPeriods.length - 1) &&
                (this._paused === true)
			) {
				this.resetAudioTimer();
			}
		}
	},

	setClicksEnabled: function(enableClicks) {
		this._clicksEnabled = enableClicks;
	},

	getInitialReadingEnded: function() {
		return this._initialReadingEnded;
	},

	setInitialReadingEnded: function(hasEnded) {
		this._initialReadingEnded = hasEnded;
	},

	isAudioReplayable: function(audioIdx) {
		var isReplayable = (this._soundCfgs[this._curSoundIdx].replayable ||
                            (this._soundCfgs[this._curSoundIdx].replayableOnRequest && this._replayRequested) ||
                            (this._soundCfgs[this._curSoundIdx].replayableAfterResponse && this._hasResponded));

		return isReplayable;
	},

	intervalCallback: function() {
		this.stopSoundPlayback();

		if ((typeof this._soundCfgs === "undefined") ||
                (typeof this._soundIntervalPeriods === "undefined")) {
			// Act line the sound has ended
			this.onSoundEnded();
			return;
		}
		// Skip replays of unreplayable sounds
		while ((this._numPlaybacks > 1) &&
               (this._curSoundIdx < this._soundCfgs.length) &&
               !this.isAudioReplayable(this._curSoundIdx)) {
			this._curSoundIdx++;
		}

		if (this._curSoundIdx >= this._soundCfgs.length) {
			this._curSoundIdx = this._soundCfgs.length - 1;

			// Act like the sound has ended
			this.onSoundEnded();
		} else {
			// Enable/disable button clicking (always true on a
			// repeat of the item, i.e. during a trial "Try Again".)
			if (this._numPlaybacks > 1) {
				this.setClicksEnabled(true);
			} else {
				this.setClicksEnabled(this._soundCfgs[this._curSoundIdx].clickable);
			}

			// Clicks can only be disabled the first time
			// (i.e. can click anytime during a replay)
			this._soundCfgs[this._curSoundIdx].clickable = true;

			app.hideReplayButton();

			if (typeof this._soundCfgs[this._curSoundIdx] !== "undefined") {
				this.playAudio(this._curSoundIdx);
			} else {
				// Act like the sound has ended
				this.onSoundEnded();
			}
		}
	},

	onAudioLoopEnded: function() {
		this.clearAudioTimer();

		this._replayRequested = false;

		if (typeof this._loopEndedCallback !== "undefined") {
			// Call the provided callback (e.g. end of feedback)
			this._loopEndedCallback();

			// Clear the callback
			this._loopEndedCallback = undefined;
		} else {
			// No callback -- re-start audio loop
			this.startSoundPlayback();
		}
	},

	playEmptySound: function() {
		app.hideReplayButton();

		// Terminate any running sound playback
		this.stopSoundPlayback();
		this.cleanupSounds();

		// "Play" just the initial silence interval
		// (give enough time for visual feedback of selection)
		this.startAudioTimer(this.intervalCallback.bind(this), this._initialDelayMs);
	},

	initializeSounds: function() {
		if (this._soundCfgs == undefined) {
			this._soundCfgs = [];
		}

		if (this._soundIntervalPeriods == undefined) {
			this._soundIntervalPeriods = [];
		}

		if (this._sounds == undefined) {
			this._sounds = [];
		}
	},

	addDelay: function(delayMs) {
		if ((typeof this._soundIntervalPeriods !== "undefined") &&
                (this._soundIntervalPeriods.length > 0)) {
			this._soundIntervalPeriods[this._soundIntervalPeriods.length - 1] += delayMs;
		}
	},

	addAutoReplayDelay: function(scaleFactor) {
		if (scaleFactor == undefined) {
			scaleFactor = 1.0;
		}

		if ((typeof this._soundIntervalPeriods !== "undefined") &&
                (this._soundIntervalPeriods.length > 0)) {
			this._soundIntervalPeriods[this._soundIntervalPeriods.length - 1] += this._autoReplayDelayMs * scaleFactor;
		}
	},

	addIntroSounds: function(soundAry, preDelayMs, postDelayMs) {
		if ((typeof soundAry !== "undefined") && (soundAry instanceof Array)) {
			this.initializeSounds();

			for (var i = 0; i < soundAry.length; i++) {
				this.addSoundCfg(soundAry[i],
					preDelayMs,
					postDelayMs,
					-1, -1,
					false,
					false,
					false,
					false,
					undefined);
			}
		}
	},

	addPromptSounds: function(soundAry,
		preDelayMs,
		postDelayMs,
		replayable,
		replayableOnRequest,
		replayableAfterResponse,
		initiallyClickable,
		onEndedUserCallback) {
		if ((typeof soundAry !== "undefined") && (soundAry instanceof Array)) {
			this.initializeSounds();

			for (var i = 0; i < soundAry.length; i++) {
				this.addSoundCfg(soundAry[i],
					preDelayMs,
					postDelayMs,
					-1, -1,
					replayable,
					replayableOnRequest,
					replayableAfterResponse,
					initiallyClickable,
					(i == soundAry.length - 1) ? onEndedUserCallback : undefined);
			}
		}
	},

	addAllItemResponseSounds: function(itemIdx,
		baseResponseIdx,
		soundAry,
		preDelayMs,
		postDelayMs,
		postLastResponseDelayMs,
		replayable,
		replayableOnRequest,
		replayableAfterResponse,
		initiallyClickable,
		onEndedUserCallback) {
		if ((typeof soundAry !== "undefined") && (soundAry instanceof Array)) {
			this.initializeSounds();

			for (var i = 0; i < soundAry.length; i++) {
				this.addSoundCfg(soundAry[i],
					preDelayMs, postDelayMs,
					itemIdx, baseResponseIdx + i,
					replayable,
					replayableOnRequest,
					replayableAfterResponse,
					initiallyClickable,
					(i == soundAry.length - 1) ? onEndedUserCallback : undefined);
			}

			this.addDelay(postLastResponseDelayMs);
		}
	},

	addItemResponseSound: function(itemIdx,
		responseIdx,
		soundName,
		preDelayMs,
		postDelayMs,
		postLastResponseDelayMs,
		replayable,
		replayableOnRequest,
		replayableAfterResponse,
		initiallyClickable,
		onEndedUserCallback) {
		this.initializeSounds();

		this.addSoundCfg(soundName,
			preDelayMs, postDelayMs,
			itemIdx, responseIdx,
			replayable,
			replayableOnRequest,
			replayableAfterResponse,
			initiallyClickable,
			onEndedUserCallback);

		this.addDelay(postLastResponseDelayMs);
	},

	startSoundPlayback: function(audioLoopEndedCallback) {
		this._loopEndedCallback = audioLoopEndedCallback;

		// this.dumpSoundCfgs();
		// this.dumpSoundPeriods();

		// If we have something to do, ...
		if ((typeof this._soundCfgs !== "undefined") &&
                (this._soundCfgs.length > 0)) {
			// Set up for sound playback
			app.hideReplayButton();

			this._numPlaybacks++;

			this.stopSoundPlayback();
			this._curSoundIdx = 0;

			// Kick off the chain of sound(s) playback & timers for the audio loop
			this.intervalCallback();
		} else {
			// "Play" some empty sound
			this.playEmptySound();
		}
	},

	replayRequested: function() {
		if (typeof logButtonPressed != "undefined") {
			logButtonPressed("Replay");
		}

		this._replayRequested = true;
		this.startSoundPlayback();
	},

	stopSoundPlayback: function() {
		if (typeof this._howler !== 'undefined') {
			this._howler.stop();
		}
	},

	startAudioTimer: function(callback, interval) {
		this.clearAudioTimer();

		// Keep track of the current timer callback/interval so we can restart it if needed
		// (e.g. when the user responds)
		this._intervalTimerCallback = callback;
		this._intervalTimerInterval = interval;

		this._intervalTimerId = setTimeout(this._intervalTimerCallback,
			this._intervalTimerInterval);
	},

	clearAudioTimer: function() {
		if (typeof this._intervalTimerId !== "undefined") {
			clearTimeout(this._intervalTimerId);
			this._intervalTimerId = undefined;
		}
	},

	resetAudioTimer: function() {
		this.clearAudioTimer();

		if (
			(typeof this._intervalTimerCallback !== "undefined") &&
			(typeof this._intervalTimerInterval !== "undefined") &&
			(this._intervalTimerInterval > 0)
		) {
			this._intervalTimerId = setTimeout(this._intervalTimerCallback, this._intervalTimerInterval);
		}
	},

	cleanupSounds: function() {
		this.stopSoundPlayback();
		if (typeof this._soundCfgs !== "undefined") {
			var len = this._soundCfgs.length;
			for (var i = 0; i < len; i++) {
				delete this._soundCfgs[i];
			}
			delete this._soundCfgs;
			this._soundCfgs = undefined;
		}

		if (typeof this._soundIntervalPeriods !== "undefined") {
			delete this._soundIntervalPeriods;
			this._soundIntervalPeriods = undefined;
		}

		if (typeof this._sounds !== "undefined") {
			len = this._sounds.length;
			for (var i = 0; i < len; i++) {
				if (typeof this._sounds[i] !== "undefined") {
					delete this._sounds[i];
				}
			}
			delete this._sounds;
			this._sounds = undefined;
		}

		this._numPlaybacks = 0;
	}
};
