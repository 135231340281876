function Letters2Meaning_Letters2WordItem(testAreaDiv, itemDef) {
	// Save incoming parameters
	this._testAreaDiv = testAreaDiv;
	this._itemDef = itemDef;

	// No answer area yet
	this._answerArea = undefined;
	this._answerAreaTop = undefined;
	this._answerAreaLeft = undefined;
	this._answerAreaWidth = undefined;
	this._answerAreaHeight = undefined;

	// Layout attributes
	this._choiceFontSize = undefined;
	this._textMetrics = undefined;

	// Insertion cursor
	this._insertCursor = undefined;
	this._cursorWidth = undefined;

	// Lookup table for choices, indexed by IDs, holding their original layout coordinates
	// (for restoring choices to their original positions when removed from answer)
	this._choiceData = {};

	// No answer yet
	this.currentAnswer = undefined;
	this.answerCorrect = false;
	this._answerChoices = [];

	// Construct the item in the DOM
	this._build();
}

Letters2Meaning_Letters2WordItem.prototype._build = function() {
	if (this._testAreaDiv && this._itemDef) {
		// Get a reference to ourselves so we can access ourselves in handler contexts
		var that = this;

		// Construct a wrapper matching the parent test area (container for drag/drop)
		this._testAreaDroppableWrapper = $("<div/>", {
			"class": "testAreaDroppableWrapper"
		}).appendTo(this._testAreaDiv);

		// Make it droppable for answer choices & set handler
		this._testAreaDroppableWrapper.droppable({
			accept: ".letters2WordChoice",
			tolerance: "intersect",
			drop: function(evt, uiObj) {
				if (!uiObj.draggable.hasClass('clicked')) {
					// Remove the object from our answer array
					that._removeChoiceFromAnswer(uiObj.draggable);

					// Put the choice back in its original position
					that._returnChoiceToOrigin(uiObj.draggable);
				}
			}
		});

		// Construct frames for the choice & answer areas
		this._choiceAreaFrame = $("<div/>", {
			"class": "choiceAreaFrame"
		}).appendTo(this._testAreaDroppableWrapper);
		this._answerAreaFrame = $("<div/>", {
			"class": "answerAreaFrame"
		}).appendTo(this._testAreaDroppableWrapper);

		// Get the parent's styling (for configuring answer area)
		var parentCSS = this._testAreaDroppableWrapper.css([
			"left",
			"width",
			"height"
		]);
		var parentLeft = app.getCSSPropertyAsNumber(parentCSS.left);
		var parentWidth = app.getCSSPropertyAsNumber(parentCSS.width);
		var parentHeight = app.getCSSPropertyAsNumber(parentCSS.height);

		// Get the metrics needed to fit all of the choices in the answer area
		var answerAreaMarginX = 20;
		var itemMetrics = this._getItemMetrics({
			"frameWidth": (parentWidth / 2) - (2 * answerAreaMarginX),
			"frameHeight": parentHeight,
			"fontFamilyCSS": "ProximaNovaSoft-Bold,Arial,sans-serif",
			"initialFontSize": 82,
			"fontWeightCSS": "normal",
			"lineSpacingRatio": 1.4,
			"minMarginXRight": 0
		});
		this._choiceFontSize = itemMetrics.fontSize;

		// Now that we have a workable font size, get the text metrics
		this._textMetrics = app.getFontMetrics("ProximaNovaSoft-Bold,Arial,sans-serif",
			`${this._choiceFontSize}px`,
			"normal");

		// Construct the answer area
		this._answerArea = app.buildAnswerArea("letters2WordAnswerArea", this._testAreaDroppableWrapper);

		this._answerAreaHeight = this._textMetrics.heightAboveBaseline;
		this._answerAreaTop = (parentHeight - this._answerAreaHeight) / 2;
		this._answerAreaLeft = parentLeft + (parentWidth / 2) + answerAreaMarginX;
		this._answerAreaWidth = (parentWidth / 2) - (2 * answerAreaMarginX);
		this._answerArea.css({
			"top": `${this._answerAreaTop}px`,
			"left": `${this._answerAreaLeft}px`,
			"width": `${this._answerAreaWidth}px`,
			"height": `${this._answerAreaHeight}px`,
			"position": "absolute",
			"font-size": `${this._choiceFontSize}px`,
			"text-align": "center",
			"white-space": "nowrap"
		});

		// Make the answer area droppable for choices & set handler
		this._answerArea.droppable({
			accept: ".letters2WordChoice",
			greedy: true,
			tolerance: "intersect",
			over: function(evt, uiObj) {
				// Make the insertion cursor visible
				that._insertCursor.removeClass("hidden");
			},
			out: function(evt, uiObj) {
				// Hide the insertion cursor
				that._insertCursor.addClass("hidden");
			},
			drop: function(evt, uiObj) {
				// Hide the insertion cursor
				that._insertCursor.addClass("hidden");

				// Insert the object into our answer array based on current center-X coordinate
				that._addChoiceToAnswer(uiObj.draggable);
			}
		});

		// Add the insertion cursor to the answer area, with
		// top & height based on the answer area
		this._cursorWidth = Math.max(10, this._answerAreaHeight / 4);
		this._insertCursor = $("<div/>", {
			"class": "insertCursor hidden",
			"css": {
				"top": "0px",
				"width": `${this._cursorWidth}px`,
				"height": `${this._answerAreaHeight}px`
			}
		});
		this._insertCursor.appendTo(this._answerArea);

		// Get the layout for the choices specifed in the item definition
		var choiceLayout = this._getChoiceLayout();

		// For each choice in the item...
		for (var i = 0; i < this._itemDef.choices.length; i++) {
			// Generate the ID for the choice
			var choiceId = `letters2WordChoice${i + 1}of${this._itemDef.choices.length}`;

			// Compute the choice's start position and record it
			var originalTop = `${choiceLayout[i].y + this._textMetrics.topOffset}px`;
			var originalLeft = `${choiceLayout[i].x + this._textMetrics.leftOffset}px`;
			this._choiceData[choiceId] = {
				"originalTop": originalTop,
				"originalLeft": originalLeft
			};

			// Create a draggable div for the choice
			var click = { x: 0, y: 0 },
				totalDragEventCount = 0;

			var choice = $("<div/>", {
				"id": choiceId,
				"class": "letters2WordChoice draggableChoiceUnselected",
				"text": this._itemDef.choices[i],
				"mousedown": function(evt) {
					$(evt.target).removeClass("draggableChoiceUnselected").addClass("draggableChoiceDragging");
					window.clickStart = Date.now();
					totalDragEventCount = 0;
				},
				"mouseup": function(evt) {
					var $choice = $(evt.target);
					// I used to differentiate a drag from a short click by looking at the total time the mouse
					// button was held down, but that's not really the standard for UX.  A user could reasonably
					// expect to hold the mouse down in a click for a second or two, then release without dragging,
					// and have it act like a click.  I've switched to counting total drag events instead.
					// Note, though, that when the user clicks on a choice, the system responds by simulating drag
					// events to put the choice into the answer area.  Each simulated drag event also fires a
					// mousedown and mouseup.  Without something in place to prevent it, this will cause a loop where
					// each simulated drag event counts as a click that fires more simulated drag events.  To prevent
					// that, I'm checking for a minimum of 10ms since the mousedown event.
					if (Date.now() - window.clickStart > 10) {
						if (totalDragEventCount > 10) {
							// User is dragging
							if (!$choice.hasClass("partOfAnswer")) {
								$choice.removeClass("draggableChoiceDragging").addClass("draggableChoiceUnselected");
							}
						} else {
							// User clicked
							var droppable,
								draggable = $choice.draggable(),
								draggableOffset = draggable.offset(),
								draggableCenterX = draggableOffset.left + (draggable.width() / 2),
								draggableCenterY = draggableOffset.top + (draggable.height() / 2),
								dx,
								dy;

							if ($choice.hasClass('partOfAnswer')) {
								droppable = that._testAreaDroppableWrapper.droppable();
							} else {
								droppable = that._answerArea.droppable();
							}

							var droppableOffset = droppable.offset(),
								droppableCenterX = droppableOffset.left + (droppable.width() / 2),
								droppableCenterY = droppableOffset.top + (droppable.height() / 2);

							if ($choice.hasClass('partOfAnswer')) {
								// If this choice is already part of the answer, remove it by simulating a drag
								// back to the answer pool
								dx = droppableCenterX - draggableCenterX;
								dy = droppableCenterY - draggableCenterY;
							} else {
								// Otherwise, add it to the answer by simulating a drag into the answer area
								// Need to block the drop event in the choice's current location, because it's in the
								// answer pool, and dropping there sends it back to its original position
								$choice.addClass('clicked');
								dx = droppableCenterX + (that._getAnswerWidth() / 2) + 10 - draggableCenterX;
								dy = $('.answerAreaMidline').offset().top + ($('.answerAreaMidline').height() / 2) + 10 - draggableCenterY;
								// The `simulate` method drags/drops *from the current cursor position*.  That means that
								// if the cursor is too far from center, the item being dragged might not hit the
								// intended target.
								var mouseOffsetY = evt.pageY - draggableCenterY;
								dy += mouseOffsetY;
							}
							draggable.simulate("drag", {
								dx: dx,
								dy: dy
							});
						}
					}
				},
				"css": {
					"display": "inline-block",
					"position": "absolute",
					"top": originalTop,
					"left": originalLeft,
					"width": `${this._textMetrics.width}px`,
					"height": `${this._textMetrics.height}px`,
					"font-size": `${this._choiceFontSize}px`,
					"text-align": "center",
					"white-space": "nowrap",
					"vertical-align": "baseline"
				}
			}).draggable({
				containment: this._testAreaDroppableWrapper,
				revert: "invalid",
				start: function(evt, uiObj) {
					//uiObj.helper.removeClass("draggableChoiceUnselected").addClass("draggableChoiceDragging");

					// Tell the audio module we've responded
					audio.setHasResponded(true);

					// If the choice is already part of the answer, ...
					if (uiObj.helper.hasClass("partOfAnswer")) {
						// Remove it from the answer at its old position
						that._removeChoiceFromAnswer(uiObj.helper);
					}

					click.x = evt.clientX;
					click.y = evt.clientY;
				},
				drag: function(evt, uiObj) {
					// Modify the position of the draggable based on the css scale transform applied
					// to the test container
					var original = uiObj.originalPosition;
					var left = (evt.clientX - click.x + original.left) / app.testScale;
					var top = (evt.clientY - click.y + original.top) / app.testScale;

					totalDragEventCount++;

					uiObj.position = {
						left: left,
						top: top
					};

					var cursorLeft = that._getCursorLeft(uiObj.position.top, uiObj.position.left);
					that._insertCursor.css({
						"left": `${cursorLeft}px`
					});
				},
				stop: function(evt, uiObj) {
					// If the choice is no longer part of the answer...
					if (!uiObj.helper.hasClass("partOfAnswer")) {
						// Deselect the choice
						uiObj.helper.removeClass("draggableChoiceDragging").addClass("draggableChoiceUnselected");
					} else {
						uiObj.helper.removeClass("draggableChoiceDragging").addClass("draggableChoiceSelected");
					}

					// Update the answer
					that._updateAnswer();
				}
			});

			// Create an anchor pixel at the bottom of the choice div to set the text baseline properly
			var anchorImage = $("<img/>", {
				"src": "images/1x1.gif",
				"css": {
					"position": "relative",
					"margin-top": `${this._textMetrics.anchorImgMarginTop}px`
				}
			}).appendTo(choice);

			// Append it to the selection area (test area)
			this._testAreaDroppableWrapper.append(choice);
		}
	}
};

Letters2Meaning_Letters2WordItem.prototype._getItemMetrics = function(params) {
	var itemMetrics = undefined;

	if (params) {
		// Extract parameters
		var frameWidth          = params.frameWidth;
		var frameHeight         = params.frameHeight;
		var fontFamilyCSS       = params.fontFamilyCSS;
		var initialFontSize     = params.initialFontSize;
		var fontWeightCSS       = params.fontWeightCSS;
		var lineSpacingRatio    = params.lineSpacingRatio;
		var minMarginXRight     = params.minMarginXRight;

		if (frameWidth && frameHeight) {
			// All of the choices are the same width
			var widestChoice = app.measureText(fontFamilyCSS, `${initialFontSize}px`, fontWeightCSS, "W");

			// Get the font size that allows the choices to fit in the given frame, based on the maximum choice width
			itemMetrics = this._adaptChoicesToFrame({
				"textToFit": "W",
				"maxChoiceWidth": widestChoice.measureWidth,
				"numChoices": this._itemDef.choices.length,
				"frameWidth": frameWidth,
				"frameHeight": frameHeight,
				"fontFamilyCSS": fontFamilyCSS,
				"initialFontSize": initialFontSize,
				"fontWeightCSS": fontWeightCSS,
				"lineSpacingRatio": lineSpacingRatio,
				"minMarginXRight": minMarginXRight
			});
		}
	}

	return itemMetrics;
};

Letters2Meaning_Letters2WordItem.prototype._adaptChoicesToFrame = function(params) {
	var adaptedMetrics = undefined;

	if (params) {
		// Extract parameters
		var textToFit           = params.textToFit;
		var maxChoiceWidth      = params.maxChoiceWidth;
		var numChoices          = params.numChoices;
		var frameWidth          = params.frameWidth;
		var frameHeight         = params.frameHeight;
		var fontFamilyCSS       = params.fontFamilyCSS;
		var initialFontSize     = params.initialFontSize;
		var fontWeightCSS       = params.fontWeightCSS;
		var lineSpacingRatio    = params.lineSpacingRatio;          // unused
		var minMarginXRight     = 0;    // params.minMarginXRight;  // unused

		if (textToFit && maxChoiceWidth && numChoices && frameWidth && frameHeight) {
			var marginXLeft = 0;
			var marginXRight = 0;
			var choiceMetrics;
			var fontSize = initialFontSize;

			// Everything on one line
			var blockWidth = maxChoiceWidth * numChoices;
			var numLines = 1;

			if ((blockWidth + minMarginXRight) > frameWidth) {
				// Iterate the font size until the metrics meet the width constraint from the frame
				while ((blockWidth + minMarginXRight) > frameWidth) {
					// Decrease the font size accordingly
					fontSize = Math.max(9, Math.floor(fontSize * (frameWidth / (blockWidth + minMarginXRight))));

					// Re-measure the widest choice at the new font size
					choiceMetrics = app.measureText(fontFamilyCSS, `${fontSize}px`, fontWeightCSS, textToFit);

					// Update the block width & margin
					blockWidth = choiceMetrics.measureWidth * numChoices;
				}
			}

			// Width constraint met -- how about the height?
			var blockHeight = (numLines * fontSize) + ((numLines - 1) * (fontSize * (lineSpacingRatio - 1)));
			while ((blockHeight > frameHeight) && (fontSize >= 9)) {
				// Block of text is too tall -- iterate further to reduce font size & cram it all in
				fontSize--;
				choiceMetrics = app.measureText(fontFamilyCSS, `${fontSize}px`, fontWeightCSS, textToFit);

				// Update block sizes
				blockHeight = (numLines * fontSize) + ((numLines - 1) * (fontSize * (lineSpacingRatio - 1)));
				blockWidth = choiceMetrics.measureWidth * numChoices;
			}

			if ((blockWidth + (2 * minMarginXRight)) <= frameWidth) {
				// Plenty of room for symmetric margins -- split the whitespace evenly
				marginXRight = (frameWidth - blockWidth) / 2;
				marginXLeft = marginXRight;
			} else {
				// Room for right-hand margin, but not left
				marginXRight = minMarginXRight;
				marginXLeft = ((frameWidth - blockWidth) / 2) - marginXRight;
			}

			adaptedMetrics = {
				"marginXLeft": marginXLeft,
				"marginYTop": (frameHeight - blockHeight) / 2,
				"fontSize": fontSize,
				"blockWidth": blockWidth,
				"blockHeight": blockHeight,
				"lineSpacing": (fontSize * (lineSpacingRatio - 1))
			};
		}
	}

	return adaptedMetrics;
};

Letters2Meaning_Letters2WordItem.prototype._getChoiceLayout = function() {
	var layout = [];

	// Get the layout of the choice area
	var parentCSS = this._choiceAreaFrame.css([
		"left",
		"width",
		"height"
	]);
	var parentLeft = app.getCSSPropertyAsNumber(parentCSS.left);
	var parentWidth = app.getCSSPropertyAsNumber(parentCSS.width);
	var parentHeight = app.getCSSPropertyAsNumber(parentCSS.height);

	// Define a grid on the choice area based on the current font metrics
	var numCols = Math.floor(parentWidth / this._textMetrics.width);
	var numRows = Math.floor(parentHeight / this._textMetrics.height);
	var marginLeft = (parentWidth % this._textMetrics.width) / 2;
	var marginTop = (parentHeight % this._textMetrics.height) / 2;

	// Set up an array of row/column pairs so we can randomly select them
	var cellArray = [];
	for (var i = 0; i < numRows; i++) {
		for (var j = 0; j < numCols; j++) {
			cellArray.push({"col": j, "row": i});
		}
	}

	// Select random cells to hold the choices
	var cellIdx;
	var cell;
	for (var i = 0; i < this._itemDef.choices.length; i++) {
		// Select a random cell index
		cellIdx = Math.floor(Math.random() * cellArray.length);

		// Remove the randomly-selected cell from the array,
		// so we don't choose it again
		cell = cellArray.splice(cellIdx, 1)[0];

		// Generate a layout coordinate for the cell
		layout.push({
			"x": Math.floor(marginLeft + ((cell.col + 0.5) * this._textMetrics.width)),
			"y": Math.floor(marginTop + (cell.row * this._textMetrics.height))
		});
	}

	return layout;
};

Letters2Meaning_Letters2WordItem.prototype._addChoiceToAnswer = function(choice) {

	// Signal that the choice should now be considered part of the answer
	choice.addClass("partOfAnswer");

	// Make sure choice is deselected
	choice.removeClass("draggableChoiceUnselected").addClass("draggableChoiceSelected");

	// Insert the object into our answer array based on current center-X coordinate
	var centerX = app.getChoiceCenterX(choice);
	for (var i = 0; i < this._answerChoices.length; i++) {
		if (this._answerChoices[i].centerX > centerX) {
			break;
		}
	}
	this._answerChoices.splice(i, 0, {"centerX": centerX, "choice": choice});
};

Letters2Meaning_Letters2WordItem.prototype._removeChoiceFromAnswer = function(choice) {
	// Signal that the choice should no longer be considered part of the answer
	choice.removeClass("partOfAnswer");

	// Remove the object from our answer array
	var choiceId = choice.attr("id");
	for (var i = 0; i < this._answerChoices.length; i++) {
		if (this._answerChoices[i].choice.attr("id") == choiceId) {
			break;
		}
	}
	this._answerChoices.splice(i, 1);
};

Letters2Meaning_Letters2WordItem.prototype._returnChoiceToOrigin = function(choice) {
	// Make sure choice is deselected
	choice.removeClass("draggableChoiceSelected").addClass("draggableChoiceUnselected");

	// Put the choice back in its original position
	var choiceData = this._choiceData[choice.attr("id")];
	if (choiceData) {
		choice.animate({
			"top": choiceData.originalTop,
			"left": choiceData.originalLeft
		}, 500);
	}
};

Letters2Meaning_Letters2WordItem.prototype._getAnswerWidth = function() {
	if (this._answerChoices.length == 0) {
		return 0;
	} else {
		var leftCenterX = this._answerChoices[0].centerX;
		var rightCenterX = this._answerChoices[this._answerChoices.length - 1].centerX;
		return rightCenterX - leftCenterX + this._textMetrics.width;
	}
};

Letters2Meaning_Letters2WordItem.prototype._getCursorLeft = function(top, left) {
	var cursorLeft = 0;
	var centerX = left + (this._textMetrics.width / 2);

	if (this._answerChoices.length == 0) {
		cursorLeft = (this._answerAreaWidth / 2) - (this._cursorWidth / 2);
	} else {
		var leftCenterX = this._answerChoices[0].centerX;
		var rightCenterX = this._answerChoices[this._answerChoices.length - 1].centerX;

		// Compute answer width as distance between end character centers, rather
		// than numChoices * width, because we may be dragging an answer choice
		// and the cursor must align with the existing choice arrangement
		var answerWidth = rightCenterX - leftCenterX + this._textMetrics.width;

		if (centerX <= leftCenterX) {
			cursorLeft = leftCenterX - (this._textMetrics.width / 2) - this._cursorWidth - this._answerAreaLeft;
		} else if (centerX > this._answerChoices[this._answerChoices.length - 1].centerX) {
			cursorLeft = rightCenterX + (this._textMetrics.width / 2) - this._answerAreaLeft;
		} else {
			var rightCenterX;
			for (var i = 1; i < this._answerChoices.length; i++) {
				rightCenterX = this._answerChoices[i].centerX;
				if ((centerX > leftCenterX) && (centerX <= rightCenterX)) {
					cursorLeft = ((leftCenterX + rightCenterX) / 2) - (this._cursorWidth / 2) - this._answerAreaLeft;
					break;
				}

				leftCenterX = rightCenterX;
			}
		}
	}

	return cursorLeft;
};

Letters2Meaning_Letters2WordItem.prototype._arrangeAnswerChoices = function() {
	var answerWidth = this._answerChoices.length * this._textMetrics.width;
	var currentChoiceCenterX = this._answerAreaLeft +                           // Left end of the answer area
								(this._answerAreaWidth - answerWidth) / 2 +     // Space to center answer in answer area
								this._textMetrics.width / 2;              // Centerline of first choice

	for (var i = 0; i < this._answerChoices.length; i++) {
		// Reposition the choice in the answer area
		this._answerChoices[i].choice.css({
			"top": this._answerAreaTop + this._textMetrics.topOffset,
			"left": currentChoiceCenterX + this._textMetrics.leftOffset
		});

		// Update the choice's center-X coordinate in the list
		this._answerChoices[i].centerX = currentChoiceCenterX;

		// Position for the next choice
		currentChoiceCenterX += this._textMetrics.width;
	}
};

Letters2Meaning_Letters2WordItem.prototype._getAnswer = function() {
	var currentAnswer = "";
	for (var i = 0; i < this._answerChoices.length; i++) {
		currentAnswer += this._answerChoices[i].choice.text();
	}

	if (currentAnswer !== this.currentAnswer) {
		this.currentAnswer = currentAnswer;
		this.answerCorrect = (this.currentAnswer == this._itemDef.correctAnswer);

		// Log the response
		logging.logItemResponse(app.curPageName,
			this._itemDef.itemId,
			this._itemDef.itemLabel,
			-1,
			this.currentAnswer,
			this.answerCorrect,
			true);
	}
};

Letters2Meaning_Letters2WordItem.prototype._updateAnswer = function() {
	// Update the positions of the answer choices to tidy everything up
	this._arrangeAnswerChoices();
	$('.ui-draggable').removeClass('clicked');

	this._getAnswer();

	// Update the "Next" button -- only enable it if we have an answer
	var enabled = ((this.currentAnswer != null) &&
					(typeof this.currentAnswer !== "undefined") &&
					(this.currentAnswer !== ""));
	app.enableNextButton(enabled);
};
