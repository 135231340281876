var offlineWarning = {
	isOnline: true,

	initialize: function() {
		offlineWarning.bindEvents();
	},

	bindEvents: function() {
		// Regularly request an empty file to verify internet connectivity and speed
		setInterval(offlineWarning.checkConnection, 5000);

		// When the user clicks the button on the offline warning screen, hide the warning
		$('button.close-offline-warning').click(function(ev) {
			ev.preventDefault();
			offlineWarning.hideWarning();
		});
	},

	checkConnection: function() {
		$.ajax({
			url: "https://connectiontest.a2iresearch.com/index.html",
			success: offlineWarning.goOnline,
			error: offlineWarning.goOffline,
			timeout: 5000,
			cache: false
		});
	},

	hideWarning: function() {
		$('.offline-warning-container').addClass('hidden');
		if (typeof window.audio._howler !== 'undefined') {
			window.audio._howler.play();
		}
	},

	showWarning: function() {
		$('.offline-warning-container').removeClass('hidden');
	},

	goOffline: function() {
		if (offlineWarning.isOnline) {
			console.warn("User's internet connection has gone offline");
			offlineWarning.showWarning();
			$('.offline-warning-container').removeClass('online').addClass('offline');
			$('.offline-warning-content').removeClass('hidden');
			$('.online-warning-content').addClass('hidden');
			$('button.close-offline-warning')
				.prop('disabled', true)
				.html('<span>Waiting...<i className="glyphicon glyphicon-refresh glyphicon-spin" /></span>');

			// Stop the narration
			if (typeof window.audio._howler !== 'undefined') {
				window.audio._howler.stop();
			}

			offlineWarning.isOnline = false;
		}
	},

	goOnline: function() {
		if (!offlineWarning.isOnline) {
			console.warn("User's internet connection is back online");
			$('.offline-warning-container').removeClass('offline').addClass('online');
			$('.offline-warning-content').addClass('hidden');
			$('.online-warning-content').removeClass('hidden');
			$('button.close-offline-warning')
				.prop('disabled', false)
				.html('Ready!');

			offlineWarning.isOnline = true;

		}
	}
};

$(offlineWarning.initialize);


