$(function() {
	var nextButtonHandler = {
		initialize: function() {
			var $button = $('.nextButton');

			$button.click(function(ev) {
				$button.attr('disabled', 'disabled');

				setTimeout(function() {
					$button.removeAttr('disabled');
				}, 2000);
			});
		}
	};

	nextButtonHandler.initialize();
});